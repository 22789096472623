import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import VueImg from 'v-img';
import axios from 'axios';
import './registerServiceWorker';
import vuetify from '@/plugins/vuetify';
import Vuetify from 'vuetify/lib';
import { v4 as uuidv4 } from 'uuid';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@fortawesome/fontawesome-free/css/all.css';

import AccessRightsMixin from './mixins/AccessRightsMixin';
import fieldAccessRightsMixin from './mixins/fieldAccessRightsMixin';
import ChartsMixin from './mixins/chartsMixin';
import DashboardMixin from './mixins/dashboardMixin';
import userFunctionsMixin from './mixins/userFunctionsMixin';
import TrucksMixin from './mixins/trucksMixin';
import BidsMixin from './mixins/bidsMixin';
import LoadsMixin from './mixins/loadsMixin';
import InvoicesMixin from './mixins/invoicesMixin';
import usersMixin from './mixins/usersMixin';
import loggerMixin from './mixins/loggerMixin';
import GoogleMapsMixin from './mixins/googleMapsMixin';
import pagesTabsMixin from './mixins/pagesTabsMixin';
import mailsMixin from './mixins/mailsMixin';
import indexedDBMixin from './mixins/indexedDBMixin';
import notificationsMixin from './mixins/notificationsMixin';
import LoadsFeedMixin from './mixins/loadsFeedMixin';
import DateRangePicker from '@gravitano/vue-date-range-picker';
import VuejsDialog from 'vuejs-dialog';


// include the default style
import 'vuetify/dist/vuetify.min.css';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';

import moment from 'moment';
import momentTZ from 'moment-timezone';
import VueMomentJS from 'vue-momentjs';

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import VueQuill from 'vue-quill';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: 'https://d0e8f78e7bce48f7abd43aefb0eda5c2@sentry.io/2612311',
    //dsn: 'https://24cb716ad5d4425db72b67295a960896@o518802.ingest.sentry.io/5628272', // TK
    integrations: [new Integrations.Vue({ Vue, attachProps: true, logErrors: true })]
  });
}

Vue.use(Vuetify);
Vue.config.productionTip = false;
Vue.use(require('vue-cookies'));
Vue.use(VueImg, { altAsTitle: true });
Vue.use(VueMomentJS, { moment, momentTZ });
Vue.use(DateRangePicker);
Vue.use(VueQuill);
// Vue.use(EmojiPicker);

window.App = new Vue({
  router,
  store,
  i18n,
  vuetify,
  data: () => ({
    isOnline: false,
    isRouterAlive: true,
    isShowTopCreateBtn: false,
    isHideTopCreateBtn: false,
    isShowTopSaveBtn: false,
    isHideTopSaveBtn: false,
    isShowTopEditBtn: false,
    isHideTopEditBtn: false,
    driverPendingFilesCount: 0,
    isFullUI: false,
    snackbar: false,
    snackbarText: '',
    snackbarColor: 'blue',
    searchDialog: false,
    searchQuery: '',
    searchType: '',
    lastListPath: '',
    activeLoadsCount: 0,
    lastActiveLoad: 0,
    notFinishedLoadsCount: 0,
    topNotifications: [],
    isStatusActive: false,
    alphabet: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'],
    wsConnection: null,
    loadModal: false,
    loadModalKey: '0',
    loadEditModal: false,
    loadEditModalKey: '0',
    selectedLoadId: 0,
    selectedLoadTruckId: 0,
    invoiceModal: false,
    invoicesModalKey: '0',
    invoiceEditModal: false,
    invoiceEditModalKey: '0',
    selectedInvoiceId: 0,
    selectedInvoiceNumber: '',
    truckModal: false,
    truckModalKey: '0',
    truckEditModal: false,
    truckEditModalKey: '0',
    selectedTruckId: 0,
    selectedTruckIsTrailer: false,
    selectedTruckName: '',
    truckCardModal: false,
    // Users Modal
    userModal: false,
    userModalKey: '0',
    userEditModal: false,
    userEditModalKey: '0',
    selectedUserId: 0,
    selectedUserName: '',
    selectedTruckFields: {},
    defaultHQPlace: [],
    defaultHQModal: false,
    isEditColumnsModal: false,
    editColumnsSettings: [],
    columnsEditModalData: [],
    editColumnsSaveName: '',
    sendViaEmailModal: false,
    sendViaEmailAttArray: [],
    sendViaEmailRecipients: [],
    sendViaEmailTitle: '',
    sendReportLoading: false,
    downloadReportLoading: false,
    viewReportLoading: false,
    viewInNewWindowsReportLoading: false,
    currentReportFileName: '',
    troubleShootingModal: false,
    sendViaEmailKey: 'sendViaEmailKey',
    overrideFieldDialog: false,
    overrideFieldKey: new Date().getTime(),
    overrideFieldUser: 0,
    overrideFieldItemType: 0,
    overrideFieldItemId: 0,
    overrideFieldReportId: 0,
    overrideFieldName: '',
    overrideFieldValue: 0,
    overrideFieldHaveDate: false,
    overrideFieldNote: '',
    overrideFieldIsTimeFormat: false,
    overrideFieldPrefix: '',
    isChangePaidDateModal: false,
    changePaidDateTime: '',
    paidLoadsUpdateData: {},
    companyId: 0,
    companyName: '',
    companiesList: [],
    globalLoading: false,
    floatingMap: false,
    isShowCRMDashboard: false,
    rules: {
      required: value => !!value || 'Required.'
    },
    customToolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],
      [{ 'header': 1 }, { 'header': 2 }], // custom button values
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'script': 'sub' }, { 'script': 'super' }], // superscript/subscript
      [{ 'indent': '-1' }, { 'indent': '+1' }], // indent
      [{ 'direction': 'rtl' }], // text direction
      // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['clean']
      // ['link', 'image','video']
    ]
  }),
  mixins: [
    AccessRightsMixin,
    ChartsMixin,
    DashboardMixin,
    userFunctionsMixin,
    TrucksMixin,
    BidsMixin,
    LoadsMixin,
    InvoicesMixin,
    GoogleMapsMixin,
    loggerMixin,
    fieldAccessRightsMixin,
    pagesTabsMixin,
    mailsMixin,
    usersMixin,
    notificationsMixin,
    indexedDBMixin,
    LoadsFeedMixin
  ],
  created () {
    if (typeof window !== 'undefined') {
      navigator.onLine ? this.isOnline = true : this.isOffline = true;
    }
  },
  mounted () {
    Vue.$cookies.config('30d', '', '', true, 'Strict');
    // Set timezone
    const timezoneOffsetMinutes = this.$root.$moment.momentTZ.tz('America/Los_Angeles').utcOffset();
    this.$store.commit('setTimezoneOffsetMinutes', timezoneOffsetMinutes);

    this.$router.beforeEach((to, from, next) => {
      this.$root.isShowTopCreateBtn = false;
      this.$root.isShowTopEditBtn = false;
      this.$root.isShowTopSaveBtn = false;
      this.$root.contextCreate = () => {};
      this.$root.contextSave = () => {};

      // Check is token already set before sending log data
      if (this.$store.getters.token) {
        this.logPathChanged(to.fullPath);
      }

      next();
    });

    // Tell Vue to install the plugin.
    Vue.use(VuejsDialog, {
      okText: this.$i18n.t('Yes'),
      cancelText: this.$i18n.t('No'),
      animation: 'bounce',
      backdropClose: false
    });

    if (typeof window !== 'undefined') {
      navigator.onLine ? this.isOnline = true : this.isOffline = true;

      const onlineHandler = () => {
        this.$emit('online');
        this.isOnline = true;
        this.isOffline = false;
      };

      const offlineHandler = () => {
        this.$emit('offline');
        this.isOffline = true;
        this.isOnline = false;
      };

      window.addEventListener('online', onlineHandler);
      window.addEventListener('offline', offlineHandler);

      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('online', onlineHandler);
        window.removeEventListener('offline', offlineHandler);
      });
    }

    var hidden, visibilityChange;
    if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }

    document.addEventListener(visibilityChange, () => {
      if (document[hidden]) {
        this.$store.state.isPageVisible = false;
      } else {
        this.$store.state.isPageVisible = true;
      }
    }, false);
  },
  watch: {
    $route (to, from) {
      this.$root.setPageRoute(to.path);

      if (to.path === '/') {
        if (this.$store.getters.isUserRoleDriver && this.$store.getters.isUserRoleDriverOO) {
          this.$root.route('/user/dashboard');
        } else {
          this.$root.route('/driverLoads/list');
        }
      }

      Sentry.setContext(to.path);

      this.$root.loadModal = false;
      this.$root.loadEditModal = false;
      this.$root.invoiceModal = false;
      this.$root.invoiceEditModal = false;
      this.$root.truckModal = false;
      this.$root.truckEditModal = false;
      this.$root.userModal = false;
      this.$root.userEditModal = false;

      this.$root.searchDialog = false;
    }
  },
  computed: {
    isDev: function () {
      return this.getLocalStorageItem('isDev');// || parseInt(this.$store.getters.currentUser.id) === 1 || parseInt(this.$store.getters.currentUser.id) === 3;
    },
    isMobile: function () {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    isDark: function () {
      return this.$vuetify.theme.dark;
    },
    currentTheme: function () {
      return this.$vuetify.theme.themes[this.isDark ? 'dark' : 'light'];
    },
    isMainCRM() {
      return this.$root.isShowCRMDashboard && this.$root.$store.getters.isUserRoleSuperAdmin;
    },
    selectionRequiredRules: function () {
      return [v => !!v || this.t('Validators.SelectionIsRequired')];
    },
    fieldRequiredRules: function () {
      return [v => !!v.toString() || this.t('Validators.FieldIsRequired')];
    },
    emailRules: function () {
      return [
        v => !!v || this.t('Validators.EMailIsRequired'),
        v => /.+@.+\..+/.test(v) || this.t('Validators.MustBeValidEMail')
      ];
    },
    datetimeUSARule: function () {
      return [v => new Date(v).getTime() ? true : false || this.$root.t('Loads.DateFormatError')];
    },
    durationHHMMRule: function () {
      return [v => /^[0-9][0-9]:[0-5][0-9]$/.test(v) ? true : false || this.$root.t('Loads.DateFormatError')];
    },
    numberRule: function () {
      return [v => !isNaN(parseFloat(v)) || this.t('Validators.MustBeNumber')];
    },
    driverRateTypeRule: function () {
      return [v => (v.toLocaleString().indexOf('solo') > 0 || v.toLocaleString().indexOf('team') > 0) || this.t('Validators.DriverRateTypeRule')];
    },
    isChatPage: function () {
      return this.$route.path.indexOf('/chats/list') >= 0;
    },
    isContextEdit: function () {
      if (!this.isOnline) {
        return false;
      }
      if (this.$route.path.indexOf('/loads/page') >= 0 && this.$route.params.id && this.isLoadsEditAllowed) {
        return true;
      }
      if (this.$route.path.indexOf('/trucks/page') >= 0 && this.$route.params.id && this.isTrucksEditAllowed) {
        return true;
      }
      if (this.$route.path.indexOf('/invoices/page') >= 0 && this.$route.params.id && this.isInvoicesEditAllowed) {
        return true;
      }
      if (this.$route.path.indexOf('/tasks/page') >= 0 && this.$route.params.id && this.isTasksEditAllowed) {
        return true;
      }
      return false;
    },
    isContextSave: function () {
      if (!this.isOnline) {
        return false;
      }

      if (this.$route.path.indexOf('/settings/global') >= 0 && this.isGlobalSettingsEditAllowed) {
        return true;
      }
      if (this.$route.path.indexOf('/trucks/create') >= 0 && !this.$route.params.id && this.isTrucksCreateAllowed) {
        return true;
      }
      if (this.$route.path.indexOf('/trucks/edit') >= 0 && this.$route.params.id && this.isTrucksEditAllowed) {
        return true;
      }
      if (this.$route.path.indexOf('/bids/edit') >= 0 && this.$route.params.id && this.isBidsEditAllowed) {
        return true;
      }
      if (this.$route.path.indexOf('/loads/create') >= 0 && this.isLoadsCreateAllowed) {
        return true;
      }
      if (this.$route.path.indexOf('/loads/edit') >= 0 && this.$route.params.id && this.isLoadsEditAllowed) {
        return true;
      }
      if (this.$route.path.indexOf('/invoices/create') >= 0 && this.isInvoicesCreateAllowed) {
        return true;
      }
      if (this.$route.path.indexOf('/invoices/edit') >= 0 && this.$route.params.id && this.isInvoicesEditAllowed) {
        return true;
      }
      if (this.$route.path.indexOf('/chart/edit') >= 0 && this.$route.params.id && this.isChartEditAllowed) {
        return true;
      }
      if (this.$route.path.indexOf('/tasks/edit') >= 0 && this.$route.params.id && this.isTasksEditAllowed) {
        return true;
      }
      return false;
    },
    isContextCreate: function () {
      if (this.$route.path.indexOf('/user/dashboard') === 0 && this.isUserDashboardEditAllowed) {
        return true;
      } else if (this.$route.path.indexOf('/users') === 0 && this.isUserCreateAllowed()) {
        return true;
      } else if (this.$route.path.indexOf('/trucks/list') === 0 && this.isTrucksCreateAllowed) {
        return true;
      } else if (this.$route.path.indexOf('/bids/list') === 0 && this.isBidsCreateAllowed) {
        return true;
      } else if (this.$route.path.indexOf('/invoices/list') === 0 && this.isInvoicesCreateAllowed) {
        return true;
      } else if (this.$route.path.indexOf('/chart/list') === 0 && this.isChartCreateAllowed) {
        return true;
      } else if (this.$route.path.indexOf('/notifications/list') === 0 && this.isNotificationsCreateAllowed) {
        return true;
      } else if (this.$route.path.indexOf('/tasks/list') === 0 && this.isTasksCreateAllowed) {
        return true;
      }
      /* else if (this.$route.path.indexOf('/shifts/list') === 0 && this.isShiftsCreateAllowed) {
        return true;
      } */

      return false;
    },
    isLastListPath: function () {
      return this.$root.lastListPath !== '' && this.$root.lastListPath !== this.$route.path;
    },
    isFullFinancialDataAllowedToView () {
      return this.$root.isAccessRightAllowView(this.$store.getters.loadsRights['financialInformation']);
    },
    isPendingExpensesApproveAllowed () {
      return this.$root.isAccessRightAllowView(this.$store.getters.loadsRights['approvePendingExpenses']);
    },
    isOverrideSalaryAllowed () {
      return this.$root.isAccessRightAllowView(this.$store.getters.loadsRights['overrideSalary']);
    },
    isFinancialDataAllowedToView () {
      return this.$root.isAccessRightAllowView(this.$store.getters.loadsRights['financialInformation']) ||
        this.$root.isAccessRightAllowView(this.$store.getters.loadsRights['financialInformationForDispatcher']) ||
        this.$root.isAccessRightAllowView(this.$store.getters.loadsRights['financialInformationForDriver']);
    },
    isDispatcherFinancialDataAllowedToView () {
      return this.$root.isAccessRightAllowView(this.$store.getters.loadsRights['financialInformationForDispatcher']);
    },

    isDriverFinancialDataAllowedToView () {
      return this.$root.isAccessRightAllowView(this.$store.getters.loadsRights['financialInformationForDriver']);
    },
    mapBgPath () {
      return process.env.BASE_URL + 'img/map_dummy.jpg';
    },
    windowWidth () {
      return window.innerWidth;
    },
    windowHeight () {
      return window.innerHeight;
    },
    isUseGoogleMaps() {
      return parseInt(this.$store.getters.globalSettingsFields.useGoogleMaps) === 1;
    }
  },
  methods: {
    isReportAllowedToViewByUser (userId) {
      if (this.$store.getters.menuRights) {
        if (!this.$root.isAccessRightAllowView(this.$store.getters.menuRights['Reports'])) {
          return false;
        }
      }
      return userId === this.$store.getters.currentUserId || (this.$root.isAccessRightAllowView(this.$store.getters.loadsRights['financialInformation']) && userId !== this.$store.getters.currentUserId);
    },
    onApplyEditColumns () {
      // overridable
    },
    onEditColumnsSave () {
      this.$root.setLocalStorageItem(this.$root.editColumnsSaveName, JSON.stringify(this.$root.editColumnsSettings));
      this.$root.onApplyEditColumns();
      this.$root.isEditColumnsModal = false;
    },
    onEditColumnsModal (headers, name) {
      this.isEditColumnsModal = true;
      this.columnsEditModalData = headers;
      this.editColumnsSettings = [];
      this.editColumnsSaveName = name;
      if (this.getLocalStorageItem(name)) {
        this.editColumnsSettings = JSON.parse(this.getLocalStorageItem(name));
      } else {
        for (let i = 0; i < this.columnsEditModalData.length; i++) {
          if (this.columnsEditModalData.value === 'actions') continue;
          this.editColumnsSettings.push(i);
        }
      }
    },
    getLocation () {
      return new Promise((resolve) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            resolve({ status: 'ok', lat: position.coords.latitude, lng: position.coords.longitude });
          }, (error) => {
            resolve({ status: 'err', code: error.code, msg: error.message });
          }, { timeout: 10000 });
        } else {
          resolve({status: 'err', msg: 'Geolocation is not supported by this browser'});
        }
      });
    },
    onShowLoadPreview (id, truckId = 0, isCallRoute = false) {
      this.$root.loadModal = false;
      this.$root.invoiceModal = false;
      if (this.$store.getters.currentUser.isOpenItemsInModal === 0 || isCallRoute) {
        if (truckId > 0) {
          this.route('/loads/page/' + id + '/' + truckId);
        } else {
          this.route('/loads/page/' + id);
        }
      } else {
        this.$root.selectedLoadId = id;
        this.$root.selectedLoadTruckId = truckId;
        this.$root.loadModal = true;
        this.$root.loadModalKey = new Date().getTime();
      }
    },
    onShowLoadEditPreview (id) {
      if (this.$store.getters.currentUser.isOpenItemsInModal === 0) {
        this.route('/loads/edit/' + id);
      } else {
        this.$root.selectedLoadId = id;
        this.$root.loadEditModal = true;
        this.$root.loadEditModalKey = new Date().getTime();
      }
    },
    onShowInvoicePreview (id, invoiceNumber) {
      this.$root.loadModal = false;
      this.$root.invoiceModal = false;
      if (this.$store.getters.currentUser.isOpenItemsInModal === 0) {
        this.route('/invoices/page/' + id);
      } else {
        this.$root.selectedInvoiceId = id;
        this.$root.selectedInvoiceNumber = invoiceNumber;
        this.$root.invoiceModal = true;
        this.$root.invoiceModalKey = new Date().getTime();
      }
    },
    onShowInvoiceEditPreview (id, invoiceNumber) {
      if (this.$store.getters.currentUser.isOpenItemsInModal === 0) {
        this.route('/invoices/edit/' + id);
      } else {
        this.$root.selectedInvoiceId = id;
        this.$root.selectedInvoiceNumber = invoiceNumber;
        this.$root.invoiceEditModal = true;
        this.$root.invoiceEditModalKey = new Date().getTime();
      }
    },
    onShowTruckPreview (id, truckName, isTrailer = false) {
      if (this.$store.getters.currentUser.isOpenItemsInModal === 0) {
        this.route('/trucks/page/' + id);
      } else {
        this.$root.selectedTruckId = id;
        this.$root.selectedTruckName = truckName;
        this.$root.truckModal = true;
        this.$root.selectedTruckIsTrailer = isTrailer;
        this.$root.truckModalKey = new Date().getTime();
      }
    },
    onShowTruckEditPreview (id, truckName, isTrailer = false) {
      if (this.$store.getters.currentUser.isOpenItemsInModal === 0) {
        this.route('/trucks/edit/' + id);
      } else {
        this.$root.selectedTruckId = id;
        this.$root.selectedTruckName = truckName;
        this.$root.truckEditModal = true;
        this.$root.selectedTruckIsTrailer = isTrailer;
        this.$root.truckEditModalKey = new Date().getTime();
      }
    },
    onShowTruckCardModal (id, truckName, truckFields, isTrailer = false) {
      this.$root.selectedTruckId = id;
      this.$root.selectedTruckName = truckName;
      this.$root.selectedTruckFields = truckFields;
      this.$root.truckCardModal = true;
      this.$root.selectedTruckIsTrailer = isTrailer;
      this.$root.truckModalKey = new Date().getTime();
    },
    onShowUserPreview (id, userName = '') {
      if (this.$store.getters.currentUser.isOpenItemsInModal === 0) {
        this.route('/user/page/' + id);
      } else {
        this.$root.selectedUserId = id;
        this.$root.selectedUserName = userName;
        this.$root.userModal = true;
        this.$root.userModalKey = new Date().getTime();
      }
    },
    onShowUserEditPreview (id, userName = '') {
      if (this.$store.getters.currentUser.isOpenItemsInModal === 0) {
        this.route('/user/edit/' + id);
      } else {
        this.$root.selectedUserId = id;
        this.$root.selectedUserName = userName;
        this.$root.userEditModal = true;
        this.$nextTick(() => {
          this.$root.userEditModalKey = new Date().getTime();
        });
      }
    },
    goToTheLastListPath () {
      this.$router.push(this.$root.lastListPath);
    },
    setLastListPath (path) {
      this.$root.lastListPath = path;
    },
    searchDlg (query) {
      const globalSearchType = this.$root.getLocalStorageItem('globalSearchType');
      this.$root.searchType = globalSearchType ? globalSearchType : 'loads';
      this.$root.searchQuery = query;
      this.$root.searchDialog = true;
    },
    toast (text, options) {
      if (options) {
        if (options.color) {
          this.snackbarColor = options.color;
        }
      }
      this.snackbarText = text;
      this.snackbar = true;
    },
    refresh () {
      this.isRouterAlive = false;
      setTimeout(() => {
        this.isRouterAlive = true;
      }, 10);
    },
    route (to) {
      /* if (this.$store.getters.isCurrentFormUpdated) {
        this.$dialog.confirm('It looks like you have been editing something. If you leave before saving, your changes will be lost.').then(dialog => {
          this.$store.commit('setIsCurrentFormUpdated', false);
          this.$root.setToPrevTab();
          this.$router.push(to).catch((error) => {
            if (error.toString().indexOf('NavigationDuplicated') >= 0) {
              this.refresh();
            }
          });
        }).catch(() => {
          this.$root.returnToPrevTab();
        });
      } else { */

      if (this.isMainCRM) {
        if (to.toString().indexOf('/user/dashboard') !== 0 &&
            to.toString().indexOf('/companies') !== 0) {
          to = '/user/dashboard';
        }
      }
      this.$root.setToPrevTab();
      this.$router.push(to).catch((error) => {
        if (error.toString().indexOf('Avoided redundant navigation') >= 0) {
          this.$root.refresh();
        }
      });
      // }
    },
    get (request, companyId = -1) {
      if (process.env.NODE_ENV !== 'production' || this.isDev) {
        console.log('GET -->', request);
      }
      return new Promise((resolve, reject) => {
        if (this.$store.getters.token) {
          axios.defaults.headers.common['Authorization'] = this.$store.getters.token;
          if (companyId > 0) {
            axios.defaults.headers.common['Company'] = companyId;
          } else if (this.$root.companyId > 0 || (parseInt(this.$root.companyId) === 0 && this.$store.getters.isUserRoleSuperAdmin)) {
            axios.defaults.headers.common['Company'] = this.$root.companyId;
          }
        }
        axios.get(this.$store.getters.host + request).then((response) => {
          if (process.env.NODE_ENV !== 'production' || this.isDev) {
            console.log('GET <--', request, response.data);
          }
          resolve(response.data);
        }).catch((error) => {
          //Sentry.captureException(error.toJSON());
          if (error.toJSON().message.indexOf('401') >= 0) {
            this.$root.logout();
          }
          else if (!error.response || error.code === 'ECONNABORTED') {
            console.warn(error);
            resolve({ status: 'error', msg: 'ECONNABORTED' });
          } else {
            console.error('ERROR', request, error);
            if (error.status === 401) {
              this.$root.logout();
            }
            if (this.isOnline) {
              console.error('ERROR', request, error);
              this.$root.toast(error, { color: 'error' });
            }
            reject(error);
          }
        });
      });
    },
    post (request, body, forceCache = false) {
      if (process.env.NODE_ENV !== 'production' || this.isDev) {
        console.log('POST -->', request, body);
      }
      return new Promise((resolve, reject) => {
        if (this.$store.getters.token) {
          axios.defaults.headers.common['Authorization'] = this.$store.getters.token;
          if (this.$root.companyId > 0 || (parseInt(this.$root.companyId) === 0 && this.$store.getters.isUserRoleSuperAdmin)) {
            axios.defaults.headers.common['Company'] = this.$root.companyId;
          }
        }
        axios.post(this.$store.getters.host + request, body).then(response => {
          if (process.env.NODE_ENV !== 'production' || this.isDev) {
            console.log('POST <--', request, response.data);
          }
          resolve(response.data);
        }).catch(error => {
          //Sentry.captureException(error.toJSON());
          if (error.toJSON().message.indexOf('401') >= 0) {
            this.$root.logout();
          }
          else if (!error.response || error.code === 'ECONNABORTED') {
            console.warn(error);
            resolve({ status: 'error', msg: 'ECONNABORTED' });
          } else {
            console.error('ERROR', request, error);
            if (error.status === 401) {
              this.$root.logout();
            }
            if (this.isOnline) {
              console.error('ERROR', request, error);
              this.$root.toast(error, { color: 'error' });
            }
            reject(error);
          }
        });
      });
    },
    postForm(request, formData) {
      if (process.env.NODE_ENV !== 'production' || this.isDev) {
        console.log('POST_FORM -->', request, body);
      }
      return new Promise((resolve, reject) => {
        this.$root.axios.post(request, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': this.$store.getters.token
          }
        }).then((response) => {
          if (process.env.NODE_ENV !== 'production' || this.isDev) {
            console.log('POST_FORM <--', request, response.data);
          }
          resolve(response.data);
        }, (error) => {
          //Sentry.captureException(error.toJSON());
          if (error.toJSON().message.indexOf('401') >= 0) {
            this.$root.logout();
          }
          else if (!error.response || error.code === 'ECONNABORTED') {
            console.warn(error);
            resolve({ status: 'error', msg: 'ECONNABORTED' });
          } else {
            console.error('ERROR', request, error);
            if (error.status === 401) {
              this.$root.logout();
            }
            if (this.isOnline) {
              console.error('ERROR', request, error);
              this.$root.toast(error, { color: 'error' });
            }
            reject(error);
          }
        });
      });
    },
    googleApiRequest: function (url) {
      return new Promise((resolve, reject) => {
        axios.get(url).then((response) => {
          resolve(response);
        }, (error) => {
          console.error('ERROR', url, error);
          reject(error);
        });
      });
    },
    logout: function () {
      document.cookie = "t=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';";
      document.cookie = "ogt=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';";
      window.location.reload();
      /*this.$cookies.remove('t');
      this.$root.route('/login');*/
    },
    signIn (email, password) {
      return this.post('/signin', { email: email, password: password });
    },
    sleep (ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    print () {
      window.print();
    },
    searchByAll (query) {
      return this.get('/search/' + encodeURI(query));
    },
    getUserToken (userId) {
      return this.get('/getUserToken/' + userId);
    },
    chatMessagesList (threadId, withUserId) {
      return this.$root.get('/api/chats/list/' + threadId + '/' + withUserId);
    },
    chatThreadsList () {
      return this.$root.get('/api/chats/threads');
    },
    createNewThreadForItem (itemType, itemId) {
      return this.$root.post('/api/chats/threads/add/' + itemType + '/' + itemId, {});
    },
    wsTest (url) {
      let wsConnection = new WebSocket(url);
      wsConnection.onopen = (event) => {
        console.log('WebSocket is connected');
      };

      wsConnection.onerror = (err) => {
        console.error('Socket encountered error: ', err, 'Closing socket');
        // if (this.$root.wsConnection.readyState !== WebSocket.CLOSED) {
        wsConnection.close();
      };
    },
    wsConnect (userId) {
      return;
      /*let url = 'ws://127.0.0.1:56112';
      if (process.env.NODE_ENV === 'production') {
        url = 'wss://api.freighthyperion.com/wsapp/';
      }
      // Connect web socket
      this.$root.wsConnection = new WebSocket(url);
      this.$root.wsConnection.onopen = (event) => {
        console.log('WebSocket is connected');
        this.$root.wsConnection.send('User:' + userId);
      };
      this.$root.wsConnection.onmessage = (event) => {
        if (event.data === 'updateChats') {
          this.$store.dispatch('chats/loadThread', {
            messagesListFunc: this.$root.chatMessagesList,
            threadsListFunc: this.$root.chatThreadsList
          }).then(() => {
            this.$nextTick(() => {
              const d = document.getElementById('chatScrollDiv');
              if (d) {
                d.scrollTop = d.scrollHeight;
              }
            });
          });
        }
      };*/

      /*this.$root.wsConnection.onclose = (e) => {
        console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
        setTimeout(() => {
          this.$root.wsConnect(userId);
        }, 1000);
      };

      this.$root.wsConnection.onerror = (err) => {
        console.error('Socket encountered error: ', err, 'Closing socket');
        // if (this.$root.wsConnection.readyState !== WebSocket.CLOSED) {
        this.$root.wsConnection.close();
      };*/
    },
    statusUpdate () {
      return new Promise((resolve, reject) => {
        this.$root.checkDriverPendingFilesAvailable();

        this.post('/status', {
          endpointUrl: this.$store.getters.pushNotificationsEndpointUrl
        }).then((response) => {
          if (response.status === 'ok') {
            // check app version
            this.$store.commit('setAppVersion', response.appVersion);
            this.$store.commit('setAppDriverVersion', response.appDriverVersion);
            if (this.$root.isMobile) {
              this.$store.commit('setMobileIntervals');
            }

            if (response.data) {
              if (response.companyId > 0) {
                this.$set(this.$root, 'companyId', response.companyId);
              }
              this.$set(this.$root, 'companyName', response.companyName);
              this.$set(this.$root, 'companiesList', response.companiesList);
              this.$store.commit('setToken', response.token);
              this.$store.commit('setUsersByRoles', response.data);
              this.$store.commit('setMenuAccessRights', response.menuRights);
              this.$store.commit('setLoadsAccessRights', response.loadsRights);
              this.$store.commit('setInvoicesAccessRights', response.invoicesRights);
              this.$store.commit('setReportsAccessRights', response.reportsRights);
              this.$store.commit('setCurrentUser', response.currentUser);
              this.$store.commit('setNewMailsCount', response.newMailsCount);
              this.$store.commit('setNewNotificationsCount', response.newNotificationsCount);
              this.$store.commit('setUnreadChatMessagesCount', response.unreadChatMessagesCount);
              this.$store.commit('setUnreadTasksCount', response.unreadTasksCount);
              this.$store.commit('setTopNotifications', response.topNotifications);
              this.$store.commit('setNeedApprovalLoads', response.needApprovalLoads);
              this.$store.commit('setUnfinishedLoads', response.unfinishedLoads);
              this.$store.commit('setActiveShiftId', response.activeShiftId);
              this.$store.commit('setGlobalSettingsFields', response.globalSettingsFields);

              if (this.$store.getters.isUserRoleDriverOO) {
                if (this.$route.path.includes('loads/feed')) {
                  this.$root.bottomNavActiveBtn = 0;
                } else if (this.$route.path.includes('bids/list')) {
                  this.$root.bottomNavActiveBtn = 1;
                } else if (this.$route.path.includes('driverLoads/list') || this.$route.path.includes('loads/driverPage')) {
                  this.$root.bottomNavActiveBtn = 2;
                } else if (this.$route.path.includes('user/settings')) {
                  this.$root.bottomNavActiveBtn = 5;
                }
              } else {
                if (this.$route.path.includes('shifts/driverList') || this.$route.path.includes('driverLoads/shifts')) {
                  this.$root.bottomNavActiveBtn = 0;
                } else if (this.$route.path.includes('driverLoads/list') || this.$route.path.includes('loads/driverPage')) {
                  this.$root.bottomNavActiveBtn = 1;
                } else if (this.$route.path.includes('user/settings')) {
                  this.$root.bottomNavActiveBtn = 3;
                }
              }

              this.$root.isStatusActive = parseInt(response.currentUser.statusIsAway) === 0;

              // Set active loads count if user rile is driver
              if (this.$store.getters.isUserRoleDriver || this.$store.getters.isUserRoleDriverOO) {
                if (!this.$route.path.includes('/chats/list') &&
                  (!this.$route.path.includes('/loads/feed') && !this.$store.getters.isUserRoleDriverOO) &&
                  (!this.$route.path.includes('/bids/list') && !this.$store.getters.isUserRoleDriverOO) &&
                  (!this.$route.path.includes('/shifts/driverList') && !this.$store.getters.isUserRoleDriver) &&
                  (!this.$route.path.includes('/driverLoads/shifts/') && !this.$store.getters.isUserRoleDriver) &&
                  !this.$route.path.includes('/driverLoads/list') &&
                  !this.$route.path.includes('/loads/driverPage') &&
                  !this.$route.path.includes('/user/settings') && !this.$root.isFullUI) {
                  this.$root.route('/driverLoads/list');
                }

                if (this.$store.getters.currentUser.id > 0) {
                  this.$root.activeDriverLoads(this.$store.getters.currentUser.id).then((response) => {
                    if (response.status === 'ok') {
                      this.$root.activeLoadsCount = parseInt(response.result);
                      this.$root.lastActiveLoad = parseInt(response.lastActiveLoad);
                    }
                  });
                  this.$root.unfinishedDriverLoads(this.$store.getters.currentUser.id).then((response) => {
                    if (response.status === 'ok') {
                      this.$root.notFinishedLoadsCount = parseInt(response.result);
                    }
                  });
                }
              } else {
                if (this.$route.path.includes('/shifts/driverList') ||
                    this.$route.path.includes('/driverLoads/shifts/') ||
                    this.$route.path.includes('/driverLoads/list') ||
                    this.$route.path.includes('/loads/driverPage')) {
                  this.$root.route('/user/dashboard');
                }
              }

              this.$root.getGlobalSettingsField('defaultHQPlace').then(response => {
                if (response.status === 'ok') {
                  if (response.result && response.result.indexOf('[') === 0) {
                    this.$root.defaultHQPlace = JSON.parse(response.result);
                  }
                }
              });
            }
          }
          resolve();
        });
      });
    },
    async setActiveCompany(id) {
      if (id > 0) {
        this.hideCRMDashboard();
        this.$set(this, 'globalLoading', true);
        this.$set(this.$root, 'companyId', id);
        this.$root.setLocalStorageItem('currentCompanyId', id);
        await this.$root.statusUpdate();
        if (parseInt(id) === 0) {
          this.$router.push('/user/dashboard');
        } else {
          await this.$root.refresh();
        }
        this.$set(this, 'globalLoading', false);
      }
    },
    showCRMDashboard() {
      this.isShowCRMDashboard = true;
      this.$router.push('/user/dashboard');
    },
    hideCRMDashboard() {
      this.isShowCRMDashboard = false;
    },
    checkDriverPendingFilesAvailable () {
      this.$root.getAllKeysFromIndexedDB('driverAppDB', 'filesUploads').then(keys => {
        let i = 0;
        this.driverPendingFilesCount = 0;
        for (i = 0; i < keys.length; i++) {
          if (keys[i].indexOf('_Count') > 0) continue;
          this.driverPendingFilesCount++;
        }
      });
    },
    contextCreate () {
    },
    contextSave () {
    },
    contextEdit () {
      if (this.$route.path.indexOf('/trucks/page') >= 0 && this.isTrucksEditAllowed && this.$route.params.id) {
        this.route('/trucks/edit/' + this.$route.params.id);
      }
      if (this.$route.path.indexOf('/loads/page') >= 0 && this.isLoadsEditAllowed && this.$route.params.id) {
        this.route('/loads/edit/' + this.$route.params.id);
      }
      if (this.$route.path.indexOf('/invoices/page') >= 0 && this.isInvoicesEditAllowed && this.$route.params.id) {
        this.route('/invoices/edit/' + this.$route.params.id);
      }
      if (this.$route.path.indexOf('/user/dashboard') >= 0 && this.isUserDashboardEditAllowed) {
        this.contextDashboardEdit();
      }
      if (this.$route.path.indexOf('/tasks/page') >= 0 && this.isTasksEditAllowed && this.$route.params.id) {
        this.route('/tasks/edit/' + this.$route.params.id);
      }
    },
    contextDashboardEdit () {

    },
    loadImage (folder, filename) {
      return this.get('/files/' + this.$store.getters.token + '/' + this.$store.getters.currentUser.id + '/' + folder + '/' + filename);
    },
    loadPDFFromImage (base64Data) {
      return this.post('/imageToPdf', { data: base64Data });
    },
    getMailAttUrl (to, from, mailId, attId, fileName, isDownload = false) {
      return this.$store.getters.host + '/api/mail/getAttByAttId/' +
             this.$store.getters.token + '/' +
             this.$store.getters.currentUser.id + '/' +
             encodeURIComponent(to) + '/' +
             encodeURIComponent(from) + '/' +
             mailId + '/' +
             attId + '/' +
             fileName + (isDownload ? '/download' : '');
    },
    getImageUrl (folder, file, filename = '') {
      if (filename !== '') {
        return this.$store.getters.host + '/files/' + this.$store.getters.token + '/' + this.$store.getters.currentUser.id + '/' + folder + '/' + file + '/' + encodeURIComponent(filename);
      } else {
        return this.$store.getters.host + '/files/' + this.$store.getters.token + '/' + this.$store.getters.currentUser.id + '/' + folder + '/' + file;
      }
    },
    decryptUserField (field, password, userId) {
      if (userId) {
        return this.post('/api/users/decryptSettings/' + field + '/' + userId, { password: password });
      }
      return this.post('/api/users/decryptSettings/' + field, { password: password });
    },
    loadGlobalSettingsFields () {
      return this.get('/api/globalSettings/list');
    },
    getGlobalSettingsField (field, companyId = 0) {
      if (companyId > 0) {
        return this.get('/api/globalSettings/get/' + field + '/' + companyId);
      }
      return this.get('/api/globalSettings/get/' + field);
    },
    updateGlobalSettings (fields) {
      return this.post('/api/globalSettings/save', { fields: fields });
    },
    addTruckMaintenanceType (newName) {
      return this.post('/api/globalSettings/addMaintenanceType', { newName: newName });
    },
    t (key, params = {}) {
      return this.$i18n.t(key, params);
    },
    tc (key, params = {}) {
      return this.$i18n.tc(key, params);
    },
    clearLocalStorage (startsWith) {
      if (typeof localStorage === 'undefined') return false;
      for (let key in localStorage) {
        if (key.indexOf(startsWith) === 0) {
          localStorage.removeItem(key);
        }
      }
    },
    setLocalStorageItem (name, value) {
      if (typeof localStorage === 'undefined') return false;
      localStorage.setItem(name, value);
    },
    getLocalStorageItem (name) {
      if (typeof localStorage === 'undefined') return false;
      return localStorage.getItem(name);
    },
    removeFromLocalStorageItem (name) {
      if (typeof localStorage === 'undefined') return false;
      return localStorage.removeItem(name);
    },
    setSessionStorageItem (name, value) {
      if (typeof sessionStorage === 'undefined') return false;
      sessionStorage.setItem(name, value);
    },
    getSessionStorageItem (name) {
      if (typeof sessionStorage === 'undefined') return false;
      return sessionStorage.getItem(name);
    },
    removeFromSessionStorageItem (name) {
      if (typeof sessionStorage === 'undefined') return false;
      return sessionStorage.removeItem(name);
    },
    selectAllInputValue ($event) {
      $event.target.select();
    },
    setDarkTheme (isDark) {
      this.$vuetify.theme.dark = isDark;
      this.setLocalStorageItem('isDark', isDark);
    },
    toggleDarkTheme () {
      this.setDarkTheme(!this.$vuetify.theme.dark);
    },
    setLanguageLocale (locale) {
      this.$i18n.locale = locale;
      this.setLocalStorageItem('locale', locale);
    },
    readWithFileReader (file, cb) {
      // let ext = '.' + file.name.split('.').pop();
      // if (ext.match(/jpg|png|jpeg|pdf/gi)) {
      let fr = new FileReader();
      fr.readAsDataURL(file);
      fr.addEventListener('load', () => {
        cb(fr.result);
      });
      // }
    },
    readWithFileReaderAsync (file, cb) {
      return new Promise(resolve => {
        // let ext = '.' + file.name.split('.').pop();
        // if (ext.match(/jpg|png|jpeg|pdf/gi)) {
        let fr = new FileReader();
        fr.readAsDataURL(file);
        fr.addEventListener('load', () => {
          resolve(fr.result);
        });
      });
      // }
    },
    uploadUserFile (folder, file, cb, errCb) {
      let formData = new FormData();
      formData.append('file', file);
      formData.append('folder', folder);
      formData.append('by_user', this.$store.getters.currentUser.id);
      formData.append('current_route', this.$router.currentRoute.fullPath);

      if (this.$store.getters.token) {
        axios.defaults.headers.common['Authorization'] = this.$store.getters.token;
      }
      // Make the request to the POST /single-file URL
      axios.post('/userFileUpload', formData, {
        baseURL: this.$store.getters.host,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(function (response) {
        cb(response.data);
      }).catch(function (error) {
        errCb(error);
      });
    },
    uploadUserFileAsync (folder, file) {
      return new Promise(async (resolve, reject) => {
        let formData = new FormData();
        formData.append('file', file);
        formData.append('folder', folder);
        formData.append('by_user', this.$store.getters.currentUser.id);
        formData.append('current_route', this.$router.currentRoute.fullPath);

        if (this.$store.getters.token) {
          axios.defaults.headers.common['Authorization'] = this.$store.getters.token;
        }
        // Make the request to the POST /single-file URL
        axios.post('/userFileUpload', formData, {
          baseURL: this.$store.getters.host,
          headers: { 'Content-Type': 'multipart/form-data' }
        }).then(function (response) {
          resolve({ status: 'ok', data: response.data });
        }).catch(function (error) {
          resolve({ status: 'error', msg: error });
        });
      });
    },
    uploadUserFileToS3SignedUrlAsync (folder, file) {
      return new Promise(async (resolve, reject) => {
        const uuid = uuidv4();
        const name = file.name;
        const ext = name.substring(name.lastIndexOf('.') + 1);
        const signedUrlData = await this.$root.post('/getFileSignedPostUrl', {
          'uuid': uuid,
          'name': name,
          'folder': folder,
          'ext': ext,
          'size': file.size,
          'hash': file.hash,
          'Content-Type': file.type,
          'current_route': this.$router.currentRoute.fullPath
        });
        const formData = new FormData();
        // Actual file has to be appended last.
        formData.append('file', file);
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', signedUrlData.signedRequest);
        xhr.send(file);
        resolve({ status: 'ok', hash: uuid + '.' + ext });
        /*const r = await axios.put(url.signedRequest, file, {
          headers: {
            'Content-Type': file.type
          }
        });*/

        /*let formData = new FormData();
        formData.append('uuid', uuidv4);
        formData.append('file', file);
        formData.append('folder', folder);
        formData.append('by_user', this.$store.getters.currentUser.id);
        formData.append('current_route', this.$router.currentRoute.fullPath);

        // Make the request to the POST /single-file URL
        axios.post(url, formData, {
          baseURL: this.$store.getters.host,
          headers: { 'Content-Type': 'multipart/form-data' }
        }).then(function (response) {
          resolve({ status: 'ok', data: response.data });
        }).catch(function (error) {
          resolve({ status: 'error', msg: error });
        });*/
      });
    },
    backFromViewAs () {
      this.$cookies.set('t', this.$cookies.get('ogt'), '30d', '', '', true, 'Strict');
      this.$cookies.remove('ogt');
      this.$store.commit('setToken', this.$cookies.get('t'));
      this.removeFromLocalStorageItem('viewAs');
      this.removeFromLocalStorageItem('viewAsName');
      window.location.reload();
    },
    setViewAs (userId, username) {
      this.getUserToken(userId).then((response) => {
        if (response.status === 'ok') {
          this.$cookies.set('ogt', this.$store.getters.token, '30d', '', '', true, 'Strict');
          this.$cookies.set('t', response.token, '30d', '', '', true, 'Strict');
          this.$store.commit('setToken', this.$cookies.get('t'));
          this.setLocalStorageItem('viewAs', userId);
          this.setLocalStorageItem('viewAsName', username);
          window.location = '/user/dashboard';
        }
      });
    },
    showPasswordDialog (callback) {
      this.$store.commit('setPasswordDialog', true);
      this.$store.commit('setPasswordDialogCb', callback);
    },
    reloadNewVersion () {
      if ('serviceWorker' in navigator) {
        // await this.setState({ loadingMessage: 'Updating Your Experience' })
        navigator.serviceWorker.getRegistrations().then(function (registrations) {
          registrations.map(r => {
            r.unregister();
          });
        });
        // await AsyncStorage.setItem('appVersion', this.state.serverAppVersion)
        window.location.reload(true);
      }
    },
    setDefaultHQ (place) {}, // Overridable function
    getDefaultHQ () {
      if (this.$root.defaultHQPlace.length === 1) {
        this.$root.setDefaultHQ(this.$root.defaultHQPlace[0]);
      } else {
        this.$root.defaultHQModal = true;
      }
    },
    formatDate (date) {
      if (!date) return null;
      let [year, month, day] = date.substr(0, 10).split('-');
      if (!month || !day || !year) return null;
      return `${month}/${day}/${year}`;
    },
    parseDate (date) {
      if (!date) return null;
      const [month, day, year] = date.split('/');
      if (!month || !day || !year) return null;
      return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    },
    genReportFileAttObject (id, type, item, from, to, selected, report_number, isDOT, title) {
      const reportUrl = this.$store.getters.host +
        '/api/reports/pdf/' +
        this.$store.getters.token + '/' +
        this.$root.companyId + '/' +
        this.$store.getters.currentUser.id + '/' +
        type + '/' +
        item + '/' +
        from + '/' +
        to + '/' +
        selected + '/' +
        (report_number || '0') + '/' +
        (id || '0') + '/' +
        isDOT + '/' +
        encodeURI(title);
      return {
        attUrl: reportUrl,
        downloadAttUrl: reportUrl,
        isPdf: true,
        folder: true,
        file: true,
        directDownload: true,
        filename: type + '_report_' + report_number + '.pdf'
      };
    },
    onSendReport(type, item, from, to, selected, report_number, id, isDOT) {
      this.$root.sendReportLoading = true;
      this.$root.getReportById(id).then(response => {
        if (response.status === 'ok') {
          const fields = response.result;
          this.$root.sendViaEmailAttArray = [
            this.$root.genReportFileAttObject(id, type, item, from, to, selected, report_number, isDOT, fields.title)
          ];
          this.sendViaEmailRecipients = fields.recipients;
          this.$root.sendViaEmailTitle = fields.title;
          this.$root.sendViaEmailKey = new Date().getTime();
          this.$root.sendViaEmailModal = true;
          this.$root.sendReportLoading = false;
        }
      });
    },
    getReportById(id, type = '0') {
      return this.$root.get('/api/reports/getReportsGroup/' + id + '/' + type);
    },
    getReportPdfData(type, item, from, to, selected, report_number, id, displayHeaderFooter) {
      return this.$root.get('/api/reports/pdfData/' +
                            this.$store.getters.token + '/' +
                            parseInt(this.$root.companyId) + '/' +
                            this.$store.getters.currentUser.id + '/' +
                            type + '/' +
                            item + '/' +
                            from + '/' +
                            to + '/' +
                            selected + '/' +
                            (report_number || '0') + '/' +
                            (id || '0') + '/' +
                            displayHeaderFooter);
    },
    onDownloadReport(type, item, from, to, selected, report_number, id, isDOT, openInBrowser = true, inNewWindow = false) {
      if (inNewWindow) {
        //this.$root.viewInNewWindowsReportLoading = true;
      } else if (openInBrowser) {
        //this.$root.viewReportLoading = true;
      } else {
        this.$root.downloadReportLoading = true;
      }
      this.$root.get('/api/reports/getReportsGroup/' + id + '/' + type).then(response => {
        if (response.status === 'ok') {
          const fields = response.result;
          const url = this.$store.getters.host +
                      '/api/reports/pdf/' +
                      this.$store.getters.token + '/' +
                      this.$root.companyId + '/' +
                      this.$store.getters.currentUser.id + '/' +
                      type + '/' +
                      item + '/' +
                      from + '/' +
                      to + '/' +
                      selected + '/' +
                      (report_number || '0') + '/' +
                      (id || '0') + '/' +
                      encodeURI(this.$root.currentReportFileName) + '/' +
                      isDOT +
                      (openInBrowser ? '' : '/download');
          window.open(url, '_blank');
          /*if (inNewWindow) {
            this.$root.viewInNewWindowsReportLoading = false;
            window.open(url, '_blank');
          } else {*/
            //window.location.href = url;
            setTimeout(() => {
              this.$root.viewReportLoading = false;
              this.$root.downloadReportLoading = false;
            }, 5000);
          //}
        }
      });
    },
    moneyValue(val, toFixed = 2) {
      return '$' + parseFloat(val).round(toFixed).toLocaleString();
    },
    moneyNumber(val, toFixed = 2) {
      return parseFloat(val).round(toFixed);
    },
    fileSizeString(bytes, si=false, dp=1) {
      const thresh = si ? 1000 : 1024;

      if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
      }

      const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
      let u = -1;
      const r = 10**dp;

      do {
        bytes /= thresh;
        ++u;
      } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

      return bytes.toFixed(dp) + ' ' + units[u];
    },
    minutesToHHMM (minutes) {
      const m = minutes % 60;
      const h = (minutes - m) / 60;
      return (h < 10 ? '0' : '') + h.toString() + ':' + (m < 10 ? '0' : '') + m.toString();
    },
    overrideFieldCallback() {},
    setOverrideFieldTypeAndPrefix(isTime, prefix) {
      this.$root.overrideFieldIsTimeFormat = isTime;
      this.$root.overrideFieldPrefix = prefix;
    },
    setOverrideFieldValue () {
      let value = this.$root.overrideFieldValue;
      if (this.$root.overrideFieldIsTimeFormat) {
        const _d = this.$root.overrideFieldValue.split(':');
        if (_d.length === 2) {
          value = (parseInt(_d[0]) * 60) + parseInt(_d[1]);
        } else {
          return;
        }
      }
      this.$root.setOverriddenFieldValue(
          this.$root.overrideFieldUser,
          this.$root.overrideFieldItemType,
          this.$root.overrideFieldItemId,
          this.$root.overrideFieldName,
          value,
          this.$root.overrideFieldDate,
          this.$root.overrideFieldNote)
      .then(response => {
          this.$root.overrideFieldDialog = false;
          this.$root.overrideFieldReportId = 0;
          this.$root.overrideFieldUser = 0;
          this.$root.overrideFieldName = '';
          this.$root.overrideFieldItemType = 0;
          this.$root.overrideFieldItemId = 0;
          this.$root.overrideFieldValue = 0;
          this.$root.overrideFieldDate = '';
          this.$root.overrideFieldNote = '';
          this.overrideFieldCallback();
        });
    },
    getOverriddenFieldValue (userId, itemType, itemId, fieldName) {
      return this.$root.post('/api/reports/getOverriddenFieldValue', {
        userId: userId,
        itemType: itemType,
        itemId: itemId,
        fieldName: fieldName
      });
    },
    setOverriddenFieldValue (userId, itemType, itemId, fieldName, customValue, customDate, note) {
      return this.$root.post('/api/reports/setOverriddenFieldValue', {
        userId: userId,
        itemType: itemType,
        itemId: itemId,
        fieldName: fieldName,
        customValue: customValue,
        customDate: customDate,
        note: note
      });
    },
    removeOverriddenFieldValue (userId, itemType, itemId, fieldName) {
      return this.$root.post('/api/reports/removeOverriddenFieldValue', {
        userId: userId,
        itemType: itemType,
        itemId: itemId,
        fieldName: fieldName
      });
    },
    openGmapsDirections (item) {
      let params = {};
      const locations = item.locations;
      if (locations.length === 0) return;
      //params.map_action = 'map';
      params.origin = locations[0].name;
      params.destination = locations[locations.length - 1].name;
      params.travelmode = 'driving';

      if (locations.length > 2) {
        let waypoints = [];
        for (let i = 1; i < locations.length - 1; i++) {
          waypoints.push(locations[i].name);
        }
        params.waypoints = waypoints.join('|');
      }
      const url = 'https://www.google.com/maps/dir/?api=1&' + new URLSearchParams(params).toString();
      console.log(url);
      window.open(url, '_blank',
        'width=1280, height=720,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=yes,directories=no,status=yes');
    },
    async getInvoiceLogoPath(companyId = 0) {
      /*if (window.location.origin.indexOf('freighthyperion.com') >= 0) {
            return process.env.BASE_URL + 'img/hyperion_logo_text.svg';
          }
          if (window.location.origin.indexOf('tgl.us.com') >= 0) {
            return process.env.BASE_URL + 'img/titan_full_logo_white.png';
          }*/

      return new Promise(resolve => {
        this.$root.get('/getCompanyLogo/' + companyId).then(response => {
          if (response.status === 'ok') {
            resolve(process.env.BASE_URL + 'img/' + response.result);
          } else {
            resolve(null);
          }
        });
      });
    },
    async getCompanySettings() {
      return this.$root.get('/api/companies/getCompanySettings/' + this.companyId);
    }
  },
  render: h => h(App)
}).$mount('#app');
