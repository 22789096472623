<template>
  <div>
    <div v-if="error !== ''">
      <v-flex class="text-center error pa-2">
        <h1 class="title">{{error}}</h1>
      </v-flex>
    </div>
    <div v-else>
      <v-card class="ma-1" :loading="loading">
        <v-card-title>
          {{$t('Trucks.Maintenance')}}
          <v-btn class="ml-2 success" small @click="onAddNewModal()" :disabled="isTodayMaintenanceExist"><v-icon left>mdi-hammer-wrench</v-icon>{{$t('Trucks.NewMaintenance')}}</v-btn>
        </v-card-title>
        <v-card-text  style="max-height: 400px; overflow-y: auto;">
          <v-list dense three-line>
            <template v-for="(item,index) in itemsByDate">
              <v-list-item :key="index" @click="onAddNewModal(item.maintenanceDATE)">
                <v-list-item-content>
                  <v-list-item-title>
                    <b>{{item.maintenanceDATEFormatted}}</b>
                    {{$t('CreatedBy')}}:
                    <template v-for="creator in item.creators">
                      <UserSnippet :key="item.maintenanceDATE + creator.id"
                                   :avatar-only="true"
                                   :user-data="creator"
                                   :small="true"
                                   :override-tooltip="creator.firstName + ' ' + creator.lastName"
                      />
                      &nbsp;<a :key="'name' + item.maintenanceDATE + creator.id" href="#" @click.stop="$root.onShowUserPreview(creator.id, creator.firstName + ' ' + creator.lastName)">{{creator.firstName}} {{creator.lastName}}</a>
                    </template>
                  </v-list-item-title>
                  <v-list-item-subtitle>{{parseInt(itemsByDate[item.maintenanceDATE].miles).toLocaleString()}}&nbsp;{{$t('milesShort')}}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{item.types.join(', ')}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn small text @click.stop="onOpenReport(items[item.maintenanceDATE])"><v-icon left>mdi-file-chart</v-icon>{{$t('Report')}}</v-btn>
                  <v-btn small text @click.stop="onOpenReport(items[item.maintenanceDATE], true)"><v-icon left>mdi-file-chart</v-icon>{{$t('DOTReport')}}</v-btn>

                </v-list-item-action>
              </v-list-item>
              <v-divider :key="'divider' + index"/>
            </template>
          </v-list>
        </v-card-text>
      </v-card>

      <v-dialog v-model="addDialog" fullscreen scrollable transition="slide-y-reverse-transition">
        <v-card tile>
          <v-toolbar dark :color="$root.currentTheme.primary" dense fixed style="flex:0;">
            <v-toolbar-items>
              <v-btn icon dark @click="() => { addDialog = false; fetchData(); $emit('fetch') }"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar-items>
            <v-toolbar-title class="text-no-wrap">
              {{truckName}}&mdash;{{$t('Trucks.CreateMaintenance')}}
            </v-toolbar-title>
          </v-toolbar>
          <v-card-title>
            <v-layout wrap>
              <v-flex shrink class="pt-1">
                <v-btn icon @click="incrementDate(-1)"><v-icon>mdi-chevron-left</v-icon></v-btn>
              </v-flex>
              <v-flex shrink style="max-width: 10rem;">
                <v-menu v-model="maintenanceDATEMenu"
                        :disabled="isMaintenanceDATEDisabled"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      class="ma-1"
                      :disabled="isMaintenanceDATEDisabled"
                      v-model="maintenanceDATEFormatted"
                      @blur="maintenanceDATE = $root.parseDate(maintenanceDATEFormatted)"
                      :label="$t('Trucks.maintenanceDate')"
                      persistent-hint v-on="on" dense outlined hide-details/>
                    <!--hint="MM/DD/YYYY" -->
                  </template>
                  <v-date-picker
                    @change="onMaintenanceDateChange"
                    v-model="maintenanceDATE"
                    no-title
                    @input="maintenanceDATEMenu = false"/>
                </v-menu>
              </v-flex>
              <v-flex shrink class="pt-1">
                <v-btn icon @click="incrementDate(1)"><v-icon>mdi-chevron-right</v-icon></v-btn>
              </v-flex>
              <v-flex shrink class="pt-1">
                <v-btn icon @click.stop="fetchData(maintenanceDATE)"><v-icon>mdi-refresh</v-icon></v-btn>
              </v-flex>
              <v-flex xs12 md6 class="float-right">
                    <v-combobox
                      ref="typeCombo"
                      class="mt-1"
                      :label="$root.t('Trucks.AddMaintenanceTypeLabel')"
                      v-model="newMaintenanceType"
                      item-id="name"
                      item-text="name"
                      :items="truckMaintenanceTypes"
                      dense outlined hide-details
                      append-outer-icon="mdi-plus"
                      @click:append-outer="onCreateNewMaintenanceType"
                    />
              </v-flex>
              <template v-if="!$root.isMobile">
                <v-flex grow></v-flex>
                <v-flex shrink class="pt-1">
                  <v-btn text @click.stop="onOpenReport(itemsByDateAndType[maintenanceDATE])"><v-icon left>mdi-file-chart</v-icon>{{$t('Report')}}</v-btn>
                </v-flex>
                <v-flex shrink class="pt-1">
                  <v-btn text @click.stop="onOpenReport(itemsByDateAndType[maintenanceDATE], true)"><v-icon left>mdi-file-chart</v-icon>{{$t('DOTReport')}}</v-btn>
                </v-flex>
              </template>
            </v-layout>
          </v-card-title>
          <v-card-text>
            <v-slide-group show-arrows>
              <v-slide-item v-for="item in truckMaintenanceTypes">
                <v-chip @click="() => { onCreateNewMaintenanceType(item.name) }"
                        class="mr-1"
                >
                  <v-icon color="success" left>mdi-plus</v-icon>
                  {{ item.name }}
                </v-chip>
              </v-slide-item>
            </v-slide-group>
            <!--<v-expansion-panels
              v-model="panel"
              multiple
            >-->
              <template v-for="(item, index) in itemsByDateAndType[maintenanceDATE]">
                <!--<v-expansion-panel :key="'record' + index + item.id + fetchKey">
                  <v-expansion-panel-header>{{item.maintenanceType}}</v-expansion-panel-header>
                  <v-expansion-panel-content>-->
                <v-card class="mt-2"
                        :key="'recordCard' + index + item.id + fetchKey">
                    <MaintenanceRecord :key="'record' + index + item.id + fetchKey"
                                       :maintenanceType="item.maintenanceType"
                                       :truckMaintenanceTypes="truckMaintenanceTypes"
                                       :truckId="truckId"
                                       :maintenanceDATE="item.maintenanceDATE"
                                       :record="item"
                                       @on-delete="removeMaintenanceByIndex(index, item)"
                                       @on-fetch="fetchData"
                    />
                </v-card>
                  <!--</v-expansion-panel-content>
                </v-expansion-panel>-->
              </template>
            <!--</v-expansion-panels>-->
            <template v-if="$root.isMobile">
              <v-flex shrink class="pt-1">
                <v-btn text @click.stop="onOpenReport(itemsByDateAndType[maintenanceDATE])"><v-icon left>mdi-file-chart</v-icon>{{$t('Report')}}</v-btn>
              </v-flex>
              <v-flex shrink class="pt-1">
                <v-btn text @click.stop="onOpenReport(itemsByDateAndType[maintenanceDATE], true)"><v-icon left>mdi-file-chart</v-icon>{{$t('DOTReport')}}</v-btn>
              </v-flex>
            </template>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="reportTemplateModal"
                scrollable fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card tile>
          <v-toolbar dark color="#015aaa" dense style="flex:0;" class="no-print">
            <v-btn icon dark @click="() => { reportTemplateModal = false; }">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
            <!--  <template v-if="isReportMode">
                {{$t('Invoices.InvoicesReport')}}&nbsp;{{dateRange[0]}}&nbsp;{{dateRange[0] !== dateRange[1] ? ' &mdash; ' + dateRange[1] : ''}}
              </template>
              <template v-else-if="selected.length > 0">
                {{$t('Invoices.InvoicesReport')}}
              </template>
              <v-spacer></v-spacer>-->
            </v-toolbar-title>
            <v-btn @click="onPrintReport(isDOTReport)" text :icon="$root.isMobile">
              <v-icon :left="!$root.isMobile">mdi-printer</v-icon><template v-if="!$root.isMobile">{{$t('Print')}}</template>
            </v-btn>
            <!--<v-toolbar-items>
              <v-btn icon @click="$root.print()">
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </v-toolbar-items>-->
          </v-toolbar>
          <v-card-text class="pt-2">
            <TruckMaintenanceReportTemplate :entries="reportTemplateItems" :isDOT="isDOTReport"/>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import trucksMixin from './../../mixins/trucksMixin';
import UserSnippet from './../../components/UserSnippet';
import MaintenanceRecord from './MaintenanceRecord';
import TruckMaintenanceReportTemplate from '../Reports/TruckMaintenanceReportTemplate';

export default {
  props: ['truckId', 'truckName'],
  components: { TruckMaintenanceReportTemplate, MaintenanceRecord, UserSnippet },
  mixins: [ trucksMixin ],
  data () {
    return {
      loading: false,
      error: '',
      valid: false,
      panel: [],
      itemsByDateAndType: {},
      itemsByDate: {},
      items: {},
      truckMaintenanceTypes: [],
      fetchKey: new Date().getTime(),
      addDialog: false,
      isMaintenanceDATEDisabled: false,
      maintenanceDATE: null,
      maintenanceDATEMenu: false,
      maintenanceDATEFormatted: '',
      newMaintenanceType: '',
      reportTemplateModal: false,
      isDOTReport: false,
      reportTemplateItems: [],
      isTodayMaintenanceExist: false
    };
  },
  mounted () {
    this.$nextTick(() => {
      this.$root.getGlobalSettingsField('trucksMaintenanceTypes').then((response) => {
        if (response.status === 'ok') {
          this.truckMaintenanceTypes = JSON.parse(response.result).reverse();
        }
      });
      // add recent types for the current truck
      this.listMaintenanceTypes(this.truckId).then((response) => {
        if (response.status === 'ok') {
          for (let i = 0; i < response.results.length; i++) {
            if (!this.truckMaintenanceTypes.find((el => el.name === response.results[i].maintenanceType))) {
              this.truckMaintenanceTypes.push({
                name: response.results[i].maintenanceType,
                daysInterval: 0,
                milesInterval: 0
              });
            }
          }
        }
      });
      this.fetchData();
    });
  },
  watch: {
    maintenanceDATE () {
      this.fetchData(this.maintenanceDATE);
    },
    addDialog () {
      if (!this.addDialog) {
        this.fetchData();
      }
    }
  },
  beforeDestroy () {
  },
  methods: {
    onCreateNewMaintenanceType (overrideName = '') {
      let _newMaintenanceType = JSON.parse(JSON.stringify(this.newMaintenanceType));
      if (!_newMaintenanceType) {
        _newMaintenanceType = {};
        _newMaintenanceType.name = this.$refs.typeCombo.$refs.input.value;
        _newMaintenanceType.daysInterval = 0;
        _newMaintenanceType.milesInterval = 0;
      }

      if (overrideName.length > 0) {
        _newMaintenanceType = {
          name: overrideName,
          daysInterval: 0,
          milesInterval: 0
        };
      }
      if (_newMaintenanceType.name) {
        if (!this.itemsByDateAndType.hasOwnProperty(this.maintenanceDATE)) {
          this.$set(this.itemsByDateAndType, this.maintenanceDATE, []);
        }
        this.$set(this.itemsByDateAndType[this.maintenanceDATE], this.itemsByDateAndType[this.maintenanceDATE].length, {
          maintenanceType: _newMaintenanceType.name,
          maintenanceDATE: this.maintenanceDATE,
          partName: '',
          partCode: '',
          partAmount: '',
          isReplaced: false,
          partWear: '',
          partSize: '',
          newPartName: '',
          newPartCode: '',
          attUPLOAD: '',
          notes: '',
          isEnteredManuallyMiles: 0,
          isEnteredManuallyUsageMiles: 0,
          isEnteredManuallyUsageTime: 0,
          nextMaintenanceMiles: 0,
          nextMaintenanceDATE: 0,
          isScheduledDoneId: _newMaintenanceType.isScheduledId || 0
        });
        // this.panel.push(this.itemsByDateAndType[this.maintenanceDATE].length - 1);
        // this.$set(this, 'newMaintenanceType', { name: ''});
      } else {
        this.$root.toast(this.$i18n.t('Trucks.ErrorMessages.MaintenanceTypeShouldBeSelectedOrTypedMsg'), { color: 'error' });
      }
    },
    removeMaintenanceByIndex (index, item) {
      if (item.hasOwnProperty('id')) {
        this.$dialog.confirm(this.$root.t('Trucks.RemoveMaintenanceTypeConfirmationMsg')).then(dialog => {
          this.$root.removeTruckMaintenance(item.id).then((response) => {
            if (response.status === 'ok') {
              this.itemsByDateAndType[this.maintenanceDATE].splice(index, 1);
            }
          });
        });
      } else {
        this.itemsByDateAndType[this.maintenanceDATE].splice(index, 1);
      }
    },
    onAddNewModal (maintenanceDATE = '', addTypeAtStart = '', isScheduledId = 0) {
      if (maintenanceDATE === '') {
        this.maintenanceDATE = new Date().toISOString().split('T')[0];
      } else {
        this.maintenanceDATE = maintenanceDATE;
      }

      this.isMaintenanceDATEDisabled = isScheduledId > 0;
      this.maintenanceDATEFormatted = this.$root.formatDate(this.maintenanceDATE);
      this.fetchData(this.maintenanceDATE).then(() => {
        this.addDialog = true;
        this.$nextTick(() => {
          if (addTypeAtStart !== '') {
            this.newMaintenanceType = {
              name: addTypeAtStart,
              daysInterval: 0,
              milesInterval: 0,
              isScheduledId: isScheduledId
            };
            this.onCreateNewMaintenanceType();
          }
        });
      });
    },
    onMaintenanceDateChange () {
      this.maintenanceDATEFormatted = this.$root.formatDate(this.maintenanceDATE);
    },
    getTruckMaintenanceFirstUrl (field) {
      const docs = this.$root.getDocumentsFromField(field, 'maintenanceAtt', '');
      if (docs.length > 0) {
        return docs[0].url;
      }
      return '';
    },
    incrementDate (days) {
      this.maintenanceDATE = this.$moment.moment(this.maintenanceDATE).clone().add(days, 'days').format('YYYY-MM-DD');
      this.maintenanceDATEFormatted = this.$root.formatDate(this.maintenanceDATE);
      this.fetchData(this.maintenanceDATE);
    },
    onOpenReport (items, isDOTReport = false) {
      //this.$root.setLocalStorageItem('reportsTimeFrame', 'day');
      //this.$root.setLocalStorageItem('reportsDateOffset', 0);
      //this.$root.setLocalStorageItem('reportsDateRangeFrom', date);
      //this.$root.setLocalStorageItem('reportsDateRangeTo', date);
      //this.$root.route('/reports/truckMaintenance');

      //this.$root.route('/reports/print/truckMaintenance/0/' + date + '/' + date);
      this.$set(this, 'reportTemplateItems', items);
      this.$set(this, 'isDOTReport', isDOTReport);
      this.reportTemplateModal = true;
    },
    onPrintReport (isDOTReport) {
      if (this.reportTemplateItems.length > 0) {
        const maintenanceDATE = this.reportTemplateItems[0].maintenanceDATE;
        const truckId = this.reportTemplateItems[0].truckId;
        let ids = [];
        for (let i = 0; i < this.reportTemplateItems.length; i++) {
          ids.push(this.reportTemplateItems[i].id);
        }
        this.$root.route('/reports/print/truckMaintenance/' + this.$root.companyId + '/' + truckId + '/' + maintenanceDATE + '/' + maintenanceDATE + '/' + ids.join(',') + '/0/0/0/' + (isDOTReport ? '1' : '0'));
      }
    },
    fetchData (date = '', showLoading = true) {
      return new Promise(resolve => {
        let i = 0;
        let _items = [];
        let item = {};
        if (!this.$root.isTrucksViewAllowed) {
          this.error = this.$root.t('Errors.AccessDeniedAuthorizationFailed');
          this.loading = false;
          return;
        }
        if (showLoading) {
          this.loading = true;
        }
        this.$root.getMaintenanceList(this.truckId, date).then((response) => {
          if (response.status === 'ok') {
            this.$set(this, 'itemsByDateAndType', {});
            this.$set(this, 'itemsByDate', {});
            this.$set(this, 'items', {});
            for (i = 0; i < response.results.length; i++) {
              item = response.results[i];
              if (!this.isTodayMaintenanceExist && response.curDate === item.maintenanceDATEFormatted) this.isTodayMaintenanceExist = true;

              if (date === '') {
                if (!this.itemsByDate.hasOwnProperty(item.maintenanceDATE)) {
                  this.$set(this.items, item.maintenanceDATE, []);
                  this.itemsByDate[item.maintenanceDATE] = {
                    maintenanceDATE: item.maintenanceDATE,
                    maintenanceDATEFormatted: item.maintenanceDATEFormatted,
                    miles: item.miles,
                    count: 0,
                    creators: [],
                    types: []
                  };
                }

                this.$set(this.items[item.maintenanceDATE], this.items[item.maintenanceDATE].length, item);
                this.itemsByDate[item.maintenanceDATE].count++;
                if (this.itemsByDate[item.maintenanceDATE].types.indexOf(item.maintenanceType) < 0) {
                  this.itemsByDate[item.maintenanceDATE].types.push(item.maintenanceType + (parseInt(item.status) === 10 ? '(' + this.$root.t('Trucks.maintenanceScheduledOnly') + ')' : ''));
                }
                if (!this.itemsByDate[item.maintenanceDATE].creators.find((el) => el.id === item.userCreatedId)) {
                  this.itemsByDate[item.maintenanceDATE].creators.push({
                    id: item.userCreatedId,
                    firstName: item.userCreatedFirstName,
                    lastName: item.userCreatedLastName,
                    avatarUPLOAD: item.userCreatedAvatarUPLOAD
                  });
                }
              }
            }
            if (date !== '') {
              _items = response.results;
              for (i = 0; i < _items.length; i++) {
                if (!this.itemsByDateAndType.hasOwnProperty(_items[i].maintenanceDATE)) {
                  this.$set(this.itemsByDateAndType, _items[i].maintenanceDATE, []);
                }
                this.$set(this.itemsByDateAndType[_items[i].maintenanceDATE], this.itemsByDateAndType[_items[i].maintenanceDATE].length, _items[i]);
              }
            }
          }
        }).finally(() => {
          this.fetchKey = new Date().getTime();
          this.loading = false;
          resolve();
        });
      });
    }
  }
};
</script>
