<template>
  <div class="text-center">
    <v-overlay :value="loading || !mounted" :absolute="true">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <div v-if="error">
      <v-flex class="text-center error pa-2">
        <h1 class="title">{{error}}</h1>
      </v-flex>
    </div>
    <v-form ref="form" v-model="valid" lazy-validation v-else>
      <v-layout wrap>
        <v-flex xs12 class="pa-2">
          <!--<v-card class="pa-2 text-center" :style="!showDynamicMap ? { backgroundImage: `url('${$root.mapBgPath}')`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', minHeight: $root.isMobile ? '200px' : '500px'} : null">
            <v-btn v-if="!showDynamicMap" @click="showDynamicMap = !showDynamicMap;" :style="{marginTop: $root.isMobile ? '70px' : '200px'}"><v-icon left large>mdi-map</v-icon>{{$t('ShowDynamicMap')}}</v-btn>-->
          <v-card  v-if="showDynamicMap">
            <GoogleMapLoader v-if="$root.isUseGoogleMaps"
                             :height="$root.isMobile ? '300px' : '500px'" ref="map"
                             :showTraffic="false"
                             @change="updateMap"
                             :showTrucks="false"
                             :key="$store.getters['loads/getLocationsKey']"
                             :waypoints="locations"
                             :loadTruck="!isFinished && truckLat && truckLng ? {lat: truckLat, lng: truckLng} : null"
                             :truckTelemetry="truckTelemetry"
                             :trucks="loadTrucksArray"
                             :loadId="loadId"
                             :loadStarted="loadStartedTS"
                             :loadEnded="loadFinishedTS"
                             :showCustomIcons="true"
                             :loadName="truckName + '<hr/>' +fields.uid"/>
            <OSMMapLoader v-else
                           ref="map"
                           idOverride='osm_map_load_page'
                           :height="$root.isMobile ? '300px' : '500px'"
                           :showTraffic="false"
                           :showTrucks="false"
                           :key="$store.getters['loads/getLocationsKey']"
                           :waypoints="locations"
                           :loadTruck="!isFinished && truckLat && truckLng ? {lat: truckLat, lng: truckLng} : null"
                           :truckTelemetry="truckTelemetry"
                           :trucks="loadTrucksArray"
                           :loadId="loadId"
                           :loadStarted="loadStartedTS"
                           :loadEnded="loadFinishedTS"
                           :showCustomIcons="true"
                           :loadName="truckName + '<hr/>' +fields.uid"/>
          </v-card>
            <!--:truckId="!isFinished && fields.truck > 0 ? fields.truck : null"-->
          <!--</v-card>-->
        </v-flex>

        <v-flex xs12>
          <v-layout>
            <v-flex shrink>
              <v-btn @click="$root.route('/loads/list')" icon class="mt-1">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </v-flex>
            <v-flex grow class="pt-1" style="max-width: calc(100% - 48px);overflow: hidden;">
              <h2 class="text-left">
                <v-chip color="error" v-if="fields.deleted === 1">{{$t('Deleted')}}</v-chip>
                {{$t('Load')}} #{{loadId}}&nbsp;{{pickupLocationName}}&nbsp;-&nbsp;{{deliveryLocationName}}&nbsp;${{fields.Rate}}&nbsp;
              </h2>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex  v-if="loadId"
                 :class="$root.isMobile ? 'text-center pt-2 grow' : 'text-left pt-2 shrink'"
                 :style="$root.isMobile ? {} : { paddingLeft: '36px' }">
            <v-btn small class="mb-1" color="success" @click="$root.onShowLoadEditPreview(loadId)" v-if="isLoadsEditAllowed">
              <v-icon :left="!$root.isMobile">mdi-pen</v-icon>{{$root.isMobile ? '' : $t('Edit')}}
            </v-btn>
            <v-btn small class="mb-1 ml-1" v-if="!showDynamicMap" @click="showDynamicMap = !showDynamicMap;">
              <v-icon :left="!$root.isMobile">mdi-map</v-icon>{{$root.isMobile ? '' : $t('ShowDynamicMap')}}
            </v-btn>
            <v-btn small class="mb-1 ml-1" v-if="showDynamicMap" @click="showDynamicMap = !showDynamicMap;">
              <v-icon :left="!$root.isMobile">mdi-map</v-icon>{{$root.isMobile ? '' : $t('HideDynamicMap')}}
            </v-btn>

            <v-btn small class="mb-1 ml-1" color="warning" @click="restoreLoad()" v-if="fields.deleted === 1">
              <v-icon :left="!$root.isMobile">mdi-restore</v-icon>{{$root.isMobile ? '' : $t('Restore')}}
            </v-btn>
            <v-btn small class="mb-1 ml-1" color="error" @click="deleteLoad()" v-else>
              <v-icon :left="!$root.isMobile">mdi-delete</v-icon>{{$root.isMobile ? '' : $t('Delete')}}
            </v-btn>
        </v-flex>
        <v-flex>
          <v-switch
            :disabled="isFieldReadOnly('isEasyMode') || $root.isHideTopEditBtn"
            v-if="!isFieldHidden('isEasyMode')"
            style="margin-left: 0px; margin-top: 7px;"
            data-cy="isEasyModeSWITCH"
            @change="onIsEasyModeSwitchChange"
            class="ml-10"
            v-model="isEasyMode"
            inset
            dense
            :label="$t('Loads.isEasyMode')"/>
        </v-flex>
        <v-flex xs12 sm6 md4 xl2 class="text-no-wrap" v-if="isOverrideEditLockAllowed">
          <v-spacer></v-spacer>
          <v-select
            v-model="isOverrideEditLock"
            :label="$t('Loads.OverrideEditLock')"
            class="mt-1"
            :items="$store.getters.editOverrideOptions"
            @change="onOverrideEditLockChange"
            outlined dense hide-details/>
          <!--<v-switch inset
                    class="mt-2"
                    v-model="isOverrideEditLock"
                    v-if="isOverrideEditLockAllowed"

                    @change="onOverrideEditLockChange"
          >
          </v-switch>-->
        </v-flex>
        <v-flex xs12 class="text-left">
          <h4 class="ml-2">
            {{$root.getDateTimeStringFromTimestamp(fields.started)}}&nbsp;-&nbsp;{{$root.getDateTimeStringFromTimestamp(fields.ended)}}&nbsp;
            <a @click.stop="$root.onShowUserPreview(fields.dispatcher, dispatcherName)">{{dispatcherName}}</a>
          </h4>
        </v-flex>
        <v-flex xs12 wrap class="text-right" v-if="loadId">
          <v-alert type="warning" dense border="left" prominent class="text-left" v-if="fields.vrStatus === $store.getters.verifyStatuses.WAITING_FURTHER_ACTIONS">
            <v-row align="center">
              <v-col class="grow">
                <b>{{$t('Loads.MarkAsWaitingFurtherActions')}}</b>&nbsp;<br/>
                <span class="label">
                  <UserSnippet :user-data="{id: fields.vrCreator, avatarUPLOAD: fields.vrCreatorAvatarUPLOAD, firstName: fields.vrCreatorFirstName, lastName: fields.vrCreatorLastName}" :avatar-only="true"/>
                  &nbsp;<b>{{$t('Created')}}</b>: {{fields.vrCreatedPST}}</span>
                <br/><br/>
                <span v-html="fields.vrNote ? fields.vrNote.replace(/(?:\r\n|\r|\n)/g, '<br/>') : ''"></span>
              </v-col>
              <v-col class="shrink">
                <v-btn @click="() => {vrModal = true;}" light><v-icon left>mdi-history</v-icon>{{$t('History')}}</v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-flex>

        <v-flex xs12 wrap class="text-right" v-if="loadId">
          <v-alert type="info" dense border="left" prominent class="text-left" v-if="fields.vrStatus === $store.getters.verifyStatuses.EDITING_REQUEST">
            <v-row align="center">
              <v-col class="grow">
                <b>{{$t('Loads.EditingUnlockRequested')}}</b>&nbsp;<br/>
                <span class="label">
                  <UserSnippet :user-data="{id: fields.vrCreator, avatarUPLOAD: fields.vrCreatorAvatarUPLOAD, firstName: fields.vrCreatorFirstName, lastName: fields.vrCreatorLastName}" :avatar-only="true"/>
                  &nbsp;<b>{{$t('Created')}}</b>: {{fields.vrCreatedPST}}</span>
                <br/><br/>
                <span v-html="fields.vrEditingRequestNote ? fields.vrEditingRequestNote.replace(/(?:\r\n|\r|\n)/g, '<br/>') : ''"></span>
              </v-col>
              <v-col class="shrink">
                <v-btn @click="() => {vrModal = true;}" light><v-icon left>mdi-history</v-icon>{{$t('History')}}</v-btn>
              </v-col>
            </v-row>
          </v-alert>

          <v-alert type="success" dense border="left" prominent class="text-left" v-if="fields.vrStatus === $store.getters.verifyStatuses.EDITING_OVERRIDE">
            <v-row align="center">
              <v-col class="grow">
                <b>{{$t('Loads.OverrideEditLock')}}</b>&nbsp;<br/>
                <span class="label">
                  <UserSnippet :user-data="{id: fields.vrCreator, avatarUPLOAD: fields.vrCreatorAvatarUPLOAD, firstName: fields.vrCreatorFirstName, lastName: fields.vrCreatorLastName}" :avatar-only="true"/>
                  &nbsp;<b>{{$t('Created')}}</b>: {{fields.vrCreatedPST}}</span>&nbsp;
                <br/><br/>
                <span v-html="fields.vrNote ? fields.vrNote.replace(/(?:\r\n|\r|\n)/g, '<br/>') : ''"></span>
                <br/>
                <small>{{$t('Loads.AfterEditingMarkAsRefined')}}</small>
              </v-col>
              <v-col class="shrink">
                <v-btn @click="() => {vrModal = true;}" light><v-icon left>mdi-history</v-icon>{{$t('History')}}</v-btn>
              </v-col>
            </v-row>
          </v-alert>

          <v-alert type="success" dense border="left" prominent class="text-left" v-if="fields.vrStatus === $store.getters.verifyStatuses.VERIFIED">
            <v-row align="center">
              <v-col class="grow">
                <b>{{$t('Loads.Verified')}}</b>&nbsp;<br/>
                <span class="label">
                  <UserSnippet :user-data="{id: fields.vrCreator, avatarUPLOAD: fields.vrCreatorAvatarUPLOAD, firstName: fields.vrCreatorFirstName, lastName: fields.vrCreatorLastName}" :avatar-only="true"/>
                  &nbsp;<b>{{$t('Created')}}</b>: {{fields.vrCreatedPST}}</span>

                <br/><br/>
                <span v-html="fields.vrNote ? fields.vrNote.replace(/(?:\r\n|\r|\n)/g, '<br/>') : ''"></span>
              </v-col>
              <v-col class="shrink">
                <v-btn @click="() => {vrModal = true;}" light><v-icon left>mdi-history</v-icon>{{$t('History')}}</v-btn>
              </v-col>
            </v-row>
          </v-alert>

          <v-alert type="error" dense border="left" prominent class="text-left" v-if="fields.vrStatus === $store.getters.verifyStatuses.REFINE">
            <v-row align="center">
              <v-col class="grow">
                <b>{{$t('Loads.Refine')}}</b>&nbsp;<br/>
                <span class="label">
                  <UserSnippet :user-data="{id: fields.vrCreator, avatarUPLOAD: fields.vrCreatorAvatarUPLOAD, firstName: fields.vrCreatorFirstName, lastName: fields.vrCreatorLastName}" :avatar-only="true"/>
                  &nbsp;<b>{{$t('Created')}}</b>: {{fields.vrCreatedPST}}</span>&nbsp;
                <br/><br/>
                <span v-html="fields.vrRefineNote ? fields.vrRefineNote.replace(/(?:\r\n|\r|\n)/g, '<br/>') : ''"></span>
              </v-col>
              <v-col class="shrink">
                <v-btn @click="() => {vrModal = true;}" light><v-icon left>mdi-history</v-icon>{{$t('History')}}</v-btn>
              </v-col>
            </v-row>
          </v-alert>

          <v-alert type="warning" dense border="left" prominent class="text-left" v-if="fields.vrStatus === $store.getters.verifyStatuses.REFINED">
            <v-row align="center">
              <v-col class="grow">
                <b>{{$t('Loads.Refined')}}</b>&nbsp;<br/>
                <span class="label">
                  <UserSnippet :user-data="{id: fields.vrCreator, avatarUPLOAD: fields.vrCreatorAvatarUPLOAD, firstName: fields.vrCreatorFirstName, lastName: fields.vrCreatorLastName}" :avatar-only="true"/>
                  &nbsp;<b>{{$t('Created')}}</b>: {{fields.vrCreatedPST}}</span>&nbsp;
                <br/><br/>
                <span v-html="fields.vrRefinedNote ? fields.vrRefinedNote.replace(/(?:\r\n|\r|\n)/g, '<br/>') : ''"></span>
              </v-col>
              <v-col class="shrink">
                <v-btn @click="() => { vrModal = true; }" light><v-icon left>mdi-history</v-icon>{{$t('History')}}</v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-flex>
        <v-flex xs12 v-if="loadId && $root.isMobile" class="text-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
              >
                {{$t('Loads.Actions')}}<v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <template v-if="$root.isAccessRightAllowEdit($store.getters.loadsRights['isVerifyAllowed'])">
                <v-list-item @click="setVerified(fields.dispatcher || creator, loadId, $store.getters.verifyStatuses.VERIFIED, '')" v-if="fields.vrStatus !== $store.getters.verifyStatuses.VERIFIED">
                  <v-list-item-title>
                    <v-icon left color="success">mdi-check-decagram</v-icon>
                    {{$t('Loads.MarkAsVerified')}}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="onMarkAsRefine(fields.dispatcher || creator, loadId, fields.vrRefineNote)">
                  <v-list-item-title>
                    <v-icon left color="error">mdi-alert-decagram</v-icon>
                    {{$t('Loads.MarkAsRefine')}}
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-else-if="fields.vrStatus === $store.getters.verifyStatuses.REFINE || isOverrideEditLock > 0">
                <v-list-item @click="onMarkAsRefined(fields.dispatcher || creator, loadId, fields.vrRefinedNote)">
                  <v-icon left color="success">mdi-check-decagram</v-icon>
                  {{$t('Loads.MarkAsRefined')}}
                </v-list-item>
              </template>
              <template v-if="!isOverrideEditLockAllowed && $root.isHideTopEditBtn && fields.vrStatus !== $store.getters.verifyStatuses.EDITING_REQUEST">
                <v-list-item @click="onRequestEdit(fields.dispatcher || creator, loadId, fields.vrEditingRequestNote)">
                  <v-icon left>mdi-lock-open-variant</v-icon>
                  {{$t('Loads.RequestEditUnlock')}}
                </v-list-item>
              </template>
              <template v-if="fields.vrStatus !== $store.getters.verifyStatuses.WAITING_FURTHER_ACTIONS">
                <v-list-item @click="onMarkAsWFA(fields.dispatcher || creator, loadId, fields.vrNote)">
                  <v-icon left color="warning">mdi-check-decagram</v-icon>
                  {{$t('Loads.MarkAsWaitingFurtherActions')}}
                </v-list-item>
              </template>
              <template v-if="isInvoicesViewAllowed && invoicesArray.length">
                <!-- Invoices dropdown -->
                <v-list-item v-for="item in invoicesArray"
                             :key="item.id"
                             @click="$root.onShowInvoicePreview(item.id, item.invoiceNumber)">
                  <v-list-item-title>{{$t('GoToInvoice')}} #{{ item.invoiceNumber }} <v-chip small color="success">${{ item.total }}</v-chip></v-list-item-title>
                </v-list-item>
                <v-list-item v-if="isInvoiceCreateAllowed"
                             @click="$root.route('/invoices/create/' + loadId)">
                  <v-list-item-title><v-icon left color="success">mdi-plus</v-icon>{{$t('LeftMenu.CreateInvoice')}}</v-list-item-title>
                </v-list-item>
              </template>
              <template v-else-if="isInvoiceCreateAllowed">
                <v-list-item @click="$root.route('/invoices/create/' + loadId)">
                  <v-icon left>fa-file-invoice-dollar</v-icon>
                  {{$t('LeftMenu.CreateInvoice')}}
                </v-list-item>
              </template>
              <template v-if="isLoadsCreateAllowed">
                <v-list-item @click="onCloneLoad">
                  <v-icon left>mdi-content-duplicate</v-icon>
                  {{$t('Clone')}}
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
          <template v-if="isLoadsEditAllowed">
            <v-menu offset-y :key="'finishedDropdown_' + $root.loadModalKey">
              <template v-slot:activator="{ on, attrs }">
                <v-btn :color="getFinishedDropdownColor" v-bind="attrs" v-on="on" class="ma-1" :disabled="!isLoadsEditAllowed">
                  <v-icon left>
                    {{finished === 0 ? 'mdi-truck-fast' : ''}}
                    {{finished === 1 ? 'mdi-check' : ''}}
                    {{finished === 2 ? 'mdi-flag-checkered' : ''}}
                    {{finished === 3 ? 'mdi-cancel' : ''}}
                  </v-icon>
                  {{finished === 0 ? $t('Loads.notFinishedStatus') : ''}}
                  {{finished === 1 ? $t('Loads.finishedStatus') : ''}}
                  {{finished === 2 ? $t('Loads.finishedWithTONUStatus') : ''}}
                  {{finished === 3 ? $t('Loads.Canceled') : ''}}
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item key="notFinished" @click="setFinished(0)">
                  <v-list-item-icon><v-icon left>mdi-truck-fast</v-icon></v-list-item-icon>
                  <v-list-item-title>{{ $t('Loads.notFinish') }}</v-list-item-title>
                </v-list-item>
                <v-list-item key="finished" @click="setFinished(1)">
                  <v-list-item-icon><v-icon left>mdi-check</v-icon></v-list-item-icon>
                  <v-list-item-title>{{ $t('Loads.finish') }}</v-list-item-title>
                </v-list-item>
                <v-list-item key="finishedWithTONU" @click="setFinished(2)">
                  <v-list-item-icon><v-icon left>mdi-flag-checkered</v-icon></v-list-item-icon>
                  <v-list-item-title>{{ $t('Loads.finishWithTONU') }}</v-list-item-title>
                </v-list-item>
                <v-list-item key="canceled" @click="setFinished(3)">
                  <v-list-item-icon><v-icon left>mdi-cancel</v-icon></v-list-item-icon>
                  <v-list-item-title>{{ $t('Loads.Cancel') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <!--<v-btn class="ma-1" :disabled="prevLoadId === 0" @click="$root.route('/loads/page/' + prevLoadId)"><v-icon>mdi-chevron-left</v-icon></v-btn>
          <v-btn class="ma-1" :disabled="nextLoadId === 0" @click="$root.route('/loads/page/' + nextLoadId)"><v-icon>mdi-chevron-right</v-icon></v-btn>-->
        </v-flex>
        <v-flex xs12 wrap class="text-right" v-else-if="loadId">
            <template v-if="$root.isAccessRightAllowEdit($store.getters.loadsRights['isVerifyAllowed'])">
                <v-btn class="ma-1" @click="setVerified(fields.dispatcher || creator, loadId, $store.getters.verifyStatuses.VERIFIED, '')" v-if="fields.vrStatus !== $store.getters.verifyStatuses.VERIFIED">
                  <v-icon left color="success">mdi-check-decagram</v-icon>
                  {{$t('Loads.MarkAsVerified')}}
                </v-btn>
                <v-btn class="ma-1" @click="onMarkAsRefine(fields.dispatcher || creator, loadId, fields.vrRefineNote)">
                  <v-icon left color="error">mdi-alert-decagram</v-icon>
                  {{$t('Loads.MarkAsRefine')}}
                </v-btn>
            </template>
            <template v-else-if="fields.vrStatus === $store.getters.verifyStatuses.REFINE || isOverrideEditLock > 0">
              <v-btn class="ma-1" @click="onMarkAsRefined(fields.dispatcher || creator, loadId, fields.vrRefinedNote)">
                <v-icon left color="success">mdi-check-decagram</v-icon>
                {{$t('Loads.MarkAsRefined')}}
              </v-btn>
            </template>
            <template v-if="!isOverrideEditLockAllowed && $root.isHideTopEditBtn && fields.vrStatus !== $store.getters.verifyStatuses.EDITING_REQUEST">
              <v-btn class="mr-1 mt-1" @click="onRequestEdit(fields.dispatcher || creator, loadId, fields.vrEditingRequestNote)">
                <v-icon left>mdi-lock-open-variant</v-icon>
                {{$t('Loads.RequestEditUnlock')}}
              </v-btn>
            </template>
            <template v-if="fields.vrStatus !== $store.getters.verifyStatuses.WAITING_FURTHER_ACTIONS">
              <v-btn class="ma-1" @click="onMarkAsWFA(fields.dispatcher || creator, loadId, fields.vrNote)">
                <v-icon left color="warning">mdi-check-decagram</v-icon>
                {{$t('Loads.MarkAsWaitingFurtherActions')}}
              </v-btn>
            </template>
            <template v-if="isTasksViewAllowed">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" class="ma-1">
                    <v-icon left>mdi-clipboard-list</v-icon>{{$t('LeftMenu.Tasks')}}
                    <template v-if="tasksArray.length > 0">
                      ({{tasksArray.length}})
                    </template>
                    <v-icon right>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="item in tasksArray"
                               :key="'task' + item.id"
                               @click="$root.route('/tasks/page/' + item.id)">
                    <v-list-item-title>#{{ item.id }} - {{item.name}}</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="isTasksCreateAllowed"
                               @click="$root.route('/tasks/create/load/' + loadId)">
                    <v-list-item-title><v-icon left color="success">mdi-plus</v-icon>{{$t('LeftMenu.CreateTask')}}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <v-btn class="ma-1" @click="onLoadChat">
              <v-icon left>mdi-chat</v-icon>
              {{$t('LeftMenu.Chat')}}
            </v-btn>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" class="mr-1 mt-1" @click="openTrackingNotificationsModal()" v-if="$root.isAccessRightAllowView($store.getters.loadsRights['trackingNotifications'])">
                  <v-icon :color="isTrackingNotifications ? 'success' : null">mdi-radar</v-icon>
                </v-btn>
              </template>
              <span>Tracking Notifications</span>
            </v-tooltip>
            <template v-if="isInvoicesViewAllowed && invoicesArray.length">
              <!-- Invoices dropdown -->
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" class="ma-1" :disabled="!isInvoiceCreateAllowed && invoicesArray.length === 0">
                    <v-icon left>fa-file-invoice-dollar</v-icon>Invoices
                    <template v-if="invoicesArray.length > 0">
                      ({{invoicesArray.length}})
                    </template>
                    <v-icon right>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="item in invoicesArray"
                               :key="item.id"
                               @click="$root.onShowInvoicePreview(item.id, item.invoiceNumber)">
                    <v-list-item-title>{{$t('GoToInvoice')}} #{{ item.invoiceNumber }} <v-chip small color="success">${{ item.total }}</v-chip></v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="isInvoiceCreateAllowed"
                               @click="$root.route('/invoices/create/' + loadId)">
                    <v-list-item-title><v-icon left color="success">mdi-plus</v-icon>{{$t('LeftMenu.CreateInvoice')}}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-else-if="isInvoiceCreateAllowed">
              <v-btn class="ma-1" @click="$root.route('/invoices/create/' + loadId)">
                <v-icon left>fa-file-invoice-dollar</v-icon>
                {{$t('LeftMenu.CreateInvoice')}}
              </v-btn>
            </template>
          <!--
            <v-tooltip bottom v-if="!invoiceId && !isInvoiceCreateAllowed">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" class="mr-1 mt-1" color="warning">
                  <v-icon left>fa-file-invoice-dollar</v-icon>
                  {{$t('LeftMenu.CreateInvoice')}}
                </v-btn>
              </template>
              <span>{{$t('Loads.DocumentsNotMeetRequirements')}}</span>
            </v-tooltip>

            <v-btn class="mr-1 mt-1" @click="$root.route('/invoices/page/' + invoiceId)" v-if="invoiceId">
              <v-icon left>mdi-arrow-right-bold</v-icon>
              {{$t('GoToInvoice')}}
            </v-btn>-->
            <template v-if="isLoadsCreateAllowed">
              <v-btn class="ma-1" @click="onCloneLoad">
                <v-icon left>mdi-content-duplicate</v-icon>
                {{$t('Clone')}}
              </v-btn>
            </template>
            <template v-if="isLoadsEditAllowed">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn :color="getFinishedDropdownColor" v-bind="attrs" v-on="on" class="ma-1" :disabled="!isLoadsEditAllowed">
                    <v-icon left>
                      {{finished === 0 ? 'mdi-truck-fast' : ''}}
                      {{finished === 1 ? 'mdi-check' : ''}}
                      {{finished === 2 ? 'mdi-flag-checkered' : ''}}
                      {{finished === 3 ? 'mdi-cancel' : ''}}
                    </v-icon>
                    {{finished === 0 ? $t('Loads.notFinishedStatus') : ''}}
                    {{finished === 1 ? $t('Loads.finishedStatus') : ''}}
                    {{finished === 2 ? $t('Loads.finishedWithTONUStatus') : ''}}
                    {{finished === 3 ? $t('Loads.Canceled') : ''}}
                    <v-icon right>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item key="notFinished" @click="setFinished(0)">
                    <v-list-item-icon><v-icon left>mdi-truck-fast</v-icon></v-list-item-icon>
                    <v-list-item-title>{{ $t('Loads.notFinish') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item key="finished" @click="setFinished(1)">
                    <v-list-item-icon><v-icon left>mdi-check</v-icon></v-list-item-icon>
                    <v-list-item-title>{{ $t('Loads.finish') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item key="finishedWithTONU" @click="setFinished(2)">
                    <v-list-item-icon><v-icon left>mdi-flag-checkered</v-icon></v-list-item-icon>
                    <v-list-item-title>{{ $t('Loads.finishWithTONU') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item key="canceled" @click="setFinished(3)">
                    <v-list-item-icon><v-icon left>mdi-cancel</v-icon></v-list-item-icon>
                    <v-list-item-title>{{ $t('Loads.Cancel') }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <v-btn class="ma-1" :disabled="prevLoadId === 0" @click="$root.route('/loads/page/' + prevLoadId)"><v-icon>mdi-chevron-left</v-icon></v-btn>
            <v-btn class="ma-1" :disabled="nextLoadId === 0" @click="$root.route('/loads/page/' + nextLoadId)"><v-icon>mdi-chevron-right</v-icon></v-btn>
        </v-flex>

        <v-flex xs12 class="pa-2">
          <LoadProgressBar ref="loadProgressBarRef" :truckTelemetry="truckTelemetry" :locations="locations" :key="loadProgressBarKey"/>
        </v-flex>

        <v-flex xs12 class="pa-1">
          <v-tabs v-model="tabs" grow @change="tabChanged" :show-arrows="true" centered>
            <v-tab href="#progress" key="progress">
              <v-icon :left="!$vuetify.breakpoint.xs">mdi-view-list</v-icon>
              <span v-if="!$vuetify.breakpoint.xs">{{$t('Loads.ProgressSteps')}}</span>
            </v-tab>

            <v-tab href="#information" key="information">
              <v-icon :left="!$vuetify.breakpoint.xs">mdi-information</v-icon>
              <span v-if="!$vuetify.breakpoint.xs">{{$t('Loads.Information')}}</span>
            </v-tab>

            <v-tab href="#documents" key="documents">
              <v-icon :left="!$vuetify.breakpoint.xs">mdi-file-document</v-icon>
              <span v-if="!$vuetify.breakpoint.xs">{{$t('Loads.uploadDocuments')}}</span>
            </v-tab>

            <v-tab href="#expenses" key="expenses"  v-if="$root.isAccessRightAllowView($store.getters.loadsRights['expenses'])">
              <v-icon :left="!$vuetify.breakpoint.xs">mdi-cash-multiple</v-icon>
              <span v-if="!$vuetify.breakpoint.xs">{{$t('Loads.ExpensesTitle')}}</span>
            </v-tab>

            <v-tab href="#financial" key="financial" v-if="$root.isFinancialDataAllowedToView">
              <v-icon :left="!$vuetify.breakpoint.xs">mdi-finance</v-icon>
              <span v-if="!$vuetify.breakpoint.xs">{{$t('Loads.FinancialData')}}</span>
            </v-tab>

            <v-tab href="#mails" key="mails" v-if="$root.isMailsViewAllowed && mailsCount > 0">
              <v-icon :left="!$vuetify.breakpoint.xs">mdi-email</v-icon>
              <span v-if="!$vuetify.breakpoint.xs">{{$t('Loads.Mails')}}</span>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tabs">
            <v-tab-item value="progress">
              <LoadProgress ref="loadProgressRef"
                            :key="locationsListKey"
                            :isEasyMode="fields.isEasyMode === 1 ? true : false"
                            :loadId="loadId"
                            @saveDocuments="saveDocuments"
                            @calculateProgress="() => { if($refs.loadProgressBarRef){$refs.loadProgressBarRef.calculateProgress()}}"
                            :locationsHidden="isFieldHidden('changeLocationsList')"
                            :locationsReadOnly="isFieldReadOnly('changeLocationsList')"
                            :locationStatusHidden="isFieldHidden('updateLocationsStatus')"
                            :locationStatusReadOnly="isFieldReadOnly('updateLocationsStatus')"
                            :locationDetailsHidden="isFieldHidden('updateLocationsDetails')"
                            :locationDetailsReadOnly="isFieldReadOnly('updateLocationsDetails')"
                            :isDriverOwnerOperator="parseInt(fields.isDriverOwnerOperator) === 1"

                            :locationOdometerHidden="isFieldHidden('locationOdometer')"
                            :locationOdometerReadOnly="isFieldReadOnly('locationOdometer')"
                            :odometerUPLOADHidden="isFieldHidden('odometerUPLOAD')"
                            :odometerUPLOADReadOnly="isFieldReadOnly('odometerUPLOAD')"

                            :trucksInfo = "trucksInfo"
                            :driversInfo = "driversInfo"
                            :usersInfo = "usersInfo"

                            :readonly="!isLoadsEditAllowed"

                            :currentLat="truckLat"
                            :currentLng="truckLng"
                            :finished="fields.finished"
                            :started="fields.started"
                            :ended="fields.ended"
                            :shipper="fields.shipper"
                            :consignee="fields.consignee"
                            :appointmentData="appointmentData"
                            :isReturnToHQ="isReturnToHQ"
                            :onlyTruck="loadTruckId && loadTruckId > 0 ? loadTruckId : null"
                            :isSetDriverOORateAllowed="isSetDriverOORateAllowed"
              />
              <v-layout wrap>
                <v-flex xs12 md3 class="text-left">
                  <v-switch
                    class="ml-10 mt-5"
                    :disabled="isFieldReadOnly('isReturnToHQ') || $root.isHideTopEditBtn"
                    style="margin-top:-5px;"
                    data-cy="isReturnToHQ"
                    v-model="isReturnToHQ"
                    :loading="isReturnToHQLoading"
                    @change="onReturnSwitchChange"
                    inset
                    :label="$t('Loads.AddReturnToHQLocation')"></v-switch>
                </v-flex>
                <v-flex xs12 md3 class="text-left">
                  <v-switch
                    class="ml-10 mt-5"
                    :disabled="isFieldReadOnly('isDriverAllowedReturnToHQ') || $root.isHideTopEditBtn"
                    style="margin-top:-5px;"
                    data-cy="isDriverAllowedReturnToHQ"
                    v-model="isDriverAllowedReturnToHQ"
                    @change="onIsDriverAllowedReturnToHQChange"
                    inset
                    :label="$t('Loads.isDriverAllowedReturnToHQ')"></v-switch>
                </v-flex>
                <v-flex xs12 md3 class="text-left">
                  <v-switch
                    class="ml-10 mt-5"
                    :disabled="isFieldReadOnly('isDriverAllowedToContinue') || $root.isHideTopEditBtn"
                    style="margin-top:-5px;"
                    data-cy="isDriverAllowedToContinue"
                    v-model="isDriverAllowedToContinue"
                    @change="onIsDriverAllowedToContinueChange"
                    inset
                    :label="$t('Loads.isDriverAllowedToContinue')"></v-switch>
                </v-flex>
                <!--
                <v-flex xs12 md3 class="text-right ma-1" v-if="$store.getters['isUserRoleSuperAdmin']">
                  <v-btn class="btn warning mt-4" :loading="updateOdometersLoading" @click="onUpdateOdometersFromLogs(loadId)"><v-icon left>mdi-counter</v-icon>{{$t('Loads.UpdateOdometersFromLogs')}}</v-btn>
                </v-flex>
                -->
              </v-layout>
            </v-tab-item>

            <v-tab-item value="information">
              <v-card>
                <LoadInformation :loadId="loadId"/>
              </v-card>
            </v-tab-item>

            <v-tab-item value="documents">
              <LoadDocuments :fields="fields"
                             :loadId="loadId"
                             :readonly="!isLoadsEditAllowed"
                             :delete-load-documents-allowed="!isFieldHidden('deleteDocuments') && !isFieldReadOnly('deleteDocuments')"
                             :delete-load-photos-allowed="!isFieldHidden('deleteLoadPhotos') && !isFieldReadOnly('deleteLoadPhotos')"
                             />
            </v-tab-item>

            <v-tab-item value="expenses">
              <LoadExpenses :expensesArray="expenses"
                            @expensesSaved="()=> { fetchData(); loadExpensesKey=new Date().getTime();}"
                            v-if="!isFieldHidden('expenses')"
                            :readOnly="isFieldReadOnly('expenses') || !isLoadsEditAllowed"
                            :preselectedDriver="fields.driver"
                            :preselectedTruck="loadTrucksArray && loadTrucksArray.length > 0 ? loadTrucksArray[0] : null"
                            :isFinished="isFinished"
                            :loadId="loadId">
              </LoadExpenses>
            </v-tab-item>

            <v-tab-item value="financial" v-if="$root.isFinancialDataAllowedToView">
              <v-layout class="text-center" justify-center>
                <v-flex style="max-width: 1000px;">
                  <FinancialDataForLoad :loadId="loadId"
                                        :expenses="expenses"
                                        :key="loadExpensesKey"
                                        :locations="locations"
                                        :isFullFinancialData="$root.isFullFinancialDataAllowedToView"
                                        :isDispatcherFinancialData="$root.isDispatcherFinancialDataAllowedToView"
                                        :isDriverFinancialData="$root.isDriverFinancialDataAllowedToView"
                                        :excludeDispatcherPayment="parseInt(fields.isExcludeDispatcherPayment) === 1 ? true : false"
                  />
                </v-flex>
              </v-layout>
            </v-tab-item>

            <v-tab-item value="mails" v-if="mailsCount > 0">
              <v-layout class="text-left" justify-center>
                <MailsThread v-if="loadId" :loadId="loadId"></MailsThread>
              </v-layout>
            </v-tab-item>

          </v-tabs-items>

          <v-flex xs12 class="pa-1" style="background-color: #fff;">
            <v-textarea
              data-cy="LoadNotes"
              class="mb-1"
              :disabled="!isLoadsEditAllowed"
              :label="$t('Notes')"
              v-model="fields.notes"
              outlined dense hide-details
            >
            </v-textarea>
            <v-btn v-if="isLoadsEditAllowed"
                   style="width:100%;"
                   :disabled="!isLoadsEditAllowed"
                   color="primary"
                   @click="saveNotes">
              <v-icon left>mdi-content-save</v-icon>
              {{$t('SaveNotes')}}
            </v-btn>
          </v-flex>
        </v-flex>

      </v-layout>
    </v-form>

    <v-dialog v-model="trackingNotificationsDialog" persistent width="500">
      <TrackingNotifications
        :key="trackingNotificationsDialogKey"
        :loadId="this.loadId"
        :isTrackingNotifications="isTrackingNotifications"
        :isTrackingNotificationsSendByRequest="isTrackingNotificationsSendByRequest"
        :trackingNotificationsRecipients="trackingNotificationsRecipients"
        :trackingNotificationsInCopy="trackingNotificationsInCopy"
        :trackingNotificationsInterval="trackingNotificationsInterval"
        :trackingNotificationsSubject="trackingNotificationsSubject"
        :trackingNotificationsMessage="trackingNotificationsMessage"
        @changed="onTrackingNotificationsChanged"
        @closed="onTrackingNotificationsClosed"
      ></TrackingNotifications>
    </v-dialog>

    <!-- TODO replace with LoadVerifyModal compoonent -->
    <v-dialog v-model="verifyModal" max-width="500">
      <v-card>
        <v-card-title v-if="verifyModalStatus === $store.getters.verifyStatuses.REFINE">
          <h2>{{$t('Loads.RefineNotes')}}</h2><v-spacer></v-spacer><v-btn icon @click="verifyModal=false;"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-title v-if="verifyModalStatus === $store.getters.verifyStatuses.REFINED">
          <h2>{{$t('Loads.RefinedNotes')}}</h2><v-spacer></v-spacer><v-btn icon @click="verifyModal=false;"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-title v-if="verifyModalStatus === $store.getters.verifyStatuses.EDITING_REQUEST">
          <h2>{{$t('Loads.RequestEditUnlockNotes')}}</h2><v-spacer></v-spacer><v-btn icon @click="verifyModal=false;"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-title v-if="verifyModalStatus === $store.getters.verifyStatuses.WAITING_FURTHER_ACTIONS">
          <h2>{{$t('Loads.MarkAsWaitingFurtherActions')}}</h2><v-spacer></v-spacer><v-btn icon @click="verifyModal=false;"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-layout>
            <v-flex xs12>
              <v-textarea dense outlined
                          :disabled="!isLoadsEditAllowed"
                          :error-messages="verifyModalNoteErrorMsg"
                          v-model="verifyModalNote"></v-textarea>
            </v-flex>
          </v-layout>
          <v-layout v-if="isLoadsEditAllowed">
            <v-flex xs12>
              <v-btn @click="verifyModalEditId > 0 ? updateVerifyNote() : markAsRefine()" color="primary" width="100%">
                {{verifyModalEditId > 0 ? $t('Update') : $t('Save')}}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="vrModal" max-width="720" scrollable>
      <v-card>
        <v-card-title>
          <h4>{{$t('History')}}</h4>
          <v-spacer></v-spacer>
          <v-btn icon class="mr-4" @click="vrModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <LoadRefinedHistory :vr-data="vrData" @on-verify-edit="onVerifyEdit"/>
      </v-card>
    </v-dialog>

    <v-dialog v-model="cloneModal" max-width="720" scrollable>
      <v-card>
        <v-card-title>
          <h4>{{$t('Clone')}}</h4>
          <v-spacer></v-spacer>
          <v-btn icon class="mr-4" @click="cloneModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-textarea outlined dense hide-details v-model="clonedAppointmentDetails"></v-textarea>
        </v-card-text>
        <v-card-actions class="text-right">
          <v-spacer/>
          <v-btn class="primary" @click="cloneLoad()">{{$t('Clone')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mask } from 'vue-the-mask';
import GoogleMapLoader from './../../components/GoogleMapLoader.vue';
import LoadDocuments from '../../components/LoadDocuments.vue';
import LoadProgress from '../../components/LoadProgress.vue';
import LoadExpenses from '../../components/LoadExpenses.vue';
import FinancialDataForLoad from '../../components/FinancialDataForLoad.vue';
import LoadProgressBar from '../../components/LoadProgressBar.vue';
import LoadInformation from '../../components/LoadInformation.vue';
import TrackingNotifications from '../../components/TrackingNotifications.vue';
import MailsThread from '../../components/MailsThread';

import userFormsCommonMixin from '../../mixins/userFormsCommonMixin';
import fieldLoadingMixin from '../../mixins/fieldLoadingMixin';
import fieldAccessRightMixin from '../../mixins/fieldAccessRightsMixin';
import loadsMixin from '../../mixins/loadsMixin';
import UserSnippet from '../../components/UserSnippet';
import LoadRefinedHistory from '../../components/LoadRefineHistory';
import OSMMapLoader from "../../components/OSMMapLoader";

export default {
  directives: { mask },
  components: {
    OSMMapLoader,
    LoadRefinedHistory,
    UserSnippet,
    LoadDocuments,
    GoogleMapLoader,
    LoadProgress,
    LoadExpenses,
    FinancialDataForLoad,
    LoadProgressBar,
    LoadInformation,
    TrackingNotifications,
    MailsThread },
  mixins: [loadsMixin, userFormsCommonMixin, fieldLoadingMixin, fieldAccessRightMixin],
  props: ['id', 'truckId'],
  data: () => ({
    fields: {
      type: '',
      uid: '',
      title: '',
      bid: 0,
      started: 0,
      ended: 0,
      deleted: 0,
      finished: 0,
      dispatcher: 0,
      truck: 0,
      truckOO: 0,
      driver: 0,
      codriver: 0,
      driverOO: 0,
      pickupLocation: '',
      pickupDATETIME: '',
      deliveryLocation: '',
      deliveryDATETIME: '',
      isFactoring: 0,
      factoringPercent: 0,
      isDriverOwnerOperator: 0,
      driverOwnerOperatorBonus: 0,
      driverOwnerOperatorPercent: 0,
      Rate: 0,
      RatePerMile: 0,
      distanceInMiles: 0,
      Detention: 0,
      Layover: 0,
      Fine: 0,
      DriverAssist: 0,
      Idle: 0,
      EmptyMiles: 0,
      OwnerOperatorBonus: 0,
      isLocalLoad: 0,
      driverLocalRate: 0,
      codriverLocalRate: 0,
      isAllMiles: 0,
      driverAllMilesRate: 0,
      codriverAllMilesRate: 0,
      isReturnToHQ: 0,
      driverReturnRate: 0,
      codriverReturnRate: 0,
      driverRate: 0,
      codriverRate: 0,
      isEmptyMiles: 0,
      driverPickupMilesRate: 0,
      codriverPickupMilesRate: 0,
      isPickupMiles: 0,
      isZipToZipMiles: 0,
      driverZipToZipRate: 0,
      codriverZipToZipRate: 0,
      dispatcherPaymentPercent: 0,
      dispatcherPaymentPercentFromBonus: 0,
      dispatcherPartialRate: 0,
      dispatcherLTLRate: 0,
      dispatcherReturnRate: 0,
      isExcludeDispatcherPayment: 0,
      isDedicatedTruck: 0,
      isEasyMode: 0,
      loadPhotosUPLOAD: '[]',
      rateConUPLOAD: '[]',
      publicRateConUPLOAD: '[]',
      doUPLOAD: '[]',
      bolUPLOAD: '[]',
      podUPLOAD: '[]',
      driversReportsUPLOAD: '[]',
      notes: '',
      isDriverAllowedReturnToHQ: 0,
      isDriverAllowedToContinue: 0,
      shipper: '',
      consignee: '',
      vrStatus: null,
      vrNote: '',
      vrRefineNote: '',
      vrRefinedNote: '',
      vrEditingRequestNote: '',
      vrCreatedPST: '',
      vrCreator: 0,
      vrCreatorFirstName: '',
      vrCreatorAvatarUPLOAD: '',
      vrCreatorLastName: ''
    },
    mounted: false,
    loading: false,
    error: false,
    valid: true,
    tabs: null,
    locations: [],
    alphabet: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'],
    totalDuration: 0,
    totalDistance: 0,

    loadPhotosUPLOADCount: 0,
    rateConUPLOADCount: 0,
    publicRateConUPLOADCount: 0,
    doUPLOADCount: 0,
    bolUPLOADCount: 0,
    podUPLOADCount: 0,

    dispatcherInitials: '',
    isBrokerWithFactoring: false,
    driversTab: 0,
    HOSTabsKey: new Date().getTime(),
    pickupDATETIME: '',
    deliveryDATETIME: '',

    dispatcherName: '',
    brokerName: '',
    truckName: '',
    truckOOName: '',
    truckLat: 0,
    truckLng: 0,
    truckTelemetry: {},
    driverOOName: '',
    driverName: '',
    codriverName: '',
    bidSubject: '',
    expenses: [],

    invoiceId: 0,
    pickupLocationName: '',
    deliveryLocationName: '',
    prevLoadId: 0,
    nextLoadId: 0,

    deliveryStep: 0,
    deliveryStepIterator: 0,

    parentLoadId: 0,
    parentLoadUID: '',
    parentLoadTitle: '',
    documentsTab: 0,
    loadProgressKey: 'lpk',
    loadExpensesKey: 0,
    loadProgressBarKey: 'lpbk',
    // Tracking notifications
    trackingNotificationsDialogKey: 0,
    trackingNotificationsDialog: false,
    isTrackingNotifications: false,
    isTrackingNotificationsSendByRequest: false,
    trackingNotificationsRecipients: [],
    trackingNotificationsInCopy: [],
    trackingNotificationsInterval: 3600,
    trackingNotificationsSubject: '',
    trackingNotificationsMessage: '',
    recipientsList: [],
    isFinished: false,
    finished: 0,
    pooling: null,
    loadProgressInterval: null,
    isReturnToHQ: false,
    isReturnToHQLoading: false,
    isEasyMode: false,
    isDriverAllowedReturnToHQ: false,
    isDriverAllowedToContinue: false,
    driversRates: {},
    driversInfo: {},
    usersInfo: {},
    trucksInfo: {},
    filesLoading: false,
    isInvoiceCreateAllowed: false,
    isLoadPhotosRequired: false,
    isRateConRequired: false,
    isDeliveryOrderRequired: false,
    isProfOfDeliveryRequired: false,
    isBillOfLandingRequired: false,
    isOverrideEditLockAllowed: false,
    isOverrideEditLock: 0,
    verifyModal: false,
    verifyModalUserId: 0,
    verifyModalLoadId: 0,
    verifyModalStatus: 0,
    verifyModalEditId: 0,
    verifyModalNote: '',
    invoicesArray: [],
    tasksArray: [],
    showDynamicMap: false,
    verifyModalNoteErrorMsg: '',
    appointmentData: {},
    vrData: [],
    vrModal: false,
    mailsCount: 0,
    updateOdometersLoading: false,
    isSetDriverOORateAllowed: false,
    cloneModal: false,
    clonedAppointmentDetails: '',
    creator: 0,
    locationsListKey: 'locationsListKey' + new Date().getTime()
  }),
  computed: {
    loadId () {
      return this.id || this.$route.params.id;
    },
    loadTruckId () {
      return this.truckId || this.$route.params.truckId;
    },
    loadStartedTS () {
      return this.fields.started;
      /* if (this.locations.length > 0) {
        return this.locations[0].approvedByDispatcherTS || this.fields.started;
      }
      return null; */
    },
    loadFinishedTS () {
      return this.fields.ended;
      /* if (this.locations.length > 0) {
        return this.locations[this.locations.length - 1].approvedByDispatcherTS || this.fields.ended;
      }
      return null; */
    },
    loadTrucksArray () {
      if (!this.locations) return [];
      let ret = {};
      let i = 0;
      for (i = 1; i < this.locations.length; i++) {
        if (this.locations[i].truck > 0) {
          ret[this.locations[i].truck] = true;
        }
      }
      return Object.keys(ret).length > 0 ? Object.keys(ret) : null;
    },
    getFinishedDropdownColor () {
      if (this.finished === 3) return 'error';
      if (this.finished > 0) return 'success';
      return null;
    }
  },
  watch: {
  },
  beforeDestroy () {
    clearInterval(this.polling);
    clearInterval(this.loadProgressInterval);
  },
  mounted () {
    if (this.$store.getters.role === 'Driver') {
      this.error = 'Access Denied';
      this.mounted = true;
      return;
    }

    this.$root.getGlobalSettingsField('beforeDetentionHours').then((response) => {
      if (response.status === 'ok') {
        this.$store.dispatch('loads/set', { field: 'maxWaitingHoursUntilDetentionInSeconds', value: parseFloat(response.result) * 60 * 60 });
      }
    });
    this.$root.getGlobalSettingsField('detentionPerHour').then((response) => {
      if (response.status === 'ok') {
        this.$store.dispatch('loads/set', { field: 'detentionPrice', value: parseFloat(response.result) });
      }
    });
    this.loading = true;
    this.fetchData().then(async () => {
      this.recipientsList = await this.$root.getRecipientsListByBroker(this.fields.broker);
      for (let i = 1; i < this.locations.length; i++) {
        if (this.locations[i].driverOnSiteCheck > 0 || this.locations[i].driverDoneCheck > 0) {
        } else if (this.locations[i].driverOnTheWayCheck > 0) {
          if (this.$refs.loadProgressRef) {
            this.$refs.loadProgressRef.updateCurrentDistanceAndDuration(i);
          }
        }
      }

      if (this.$refs.loadProgressBarRef) {
        this.$refs.loadProgressBarRef.calculateProgress();
      }

      if (this.$root.getLocalStorageItem('load_tab')) {
        this.tabs = this.$root.getLocalStorageItem('load_tab');
      }
    });
    this.$nextTick(() => {
      /* this.polling = setInterval(() => {
                // Do not call interval methods if page not visible
                if (!this.$store.getters.isPageVisible) return;
                this.fetchData();
            }, this.$store.getters.pullInterval); */

      setTimeout(this.updateLoadLocations, this.$store.getters.checkInterval);
    });
    this.mounted = true;
  },
  methods: {
    onUpdateOdometersFromLogs (id) {
      this.updateOdometersLoading = true;
      this.$root.updateOdometersFromLogs(id).then(() => {
        this.updateOdometersLoading = false;
        this.$root.refresh();
      });
    },
    onLoadChat () {
      const loadId = this.loadId;
      this.$root.createNewThreadForItem(2, loadId).then((response) => {
        if (response.status === 'ok') {
          this.$root.route('/chats/list/' + response.threadId + '/' + response.userThreadId);
        }
      });
    },
    onVerifyEdit (item) {
      this.verifyModalEditId = item.id;
      if (item.status === this.$store.getters.verifyStatuses.REFINE) {
        this.verifyModalNote = item.refineNote;
      }
      if (item.status === this.$store.getters.verifyStatuses.REFINED) {
        this.verifyModalNote = item.refinedNote;
      }
      this.verifyModal = true;
    },
    onMarkAsRefine (userId, loadId, notes) {
      this.verifyModalUserId = userId;
      this.verifyModalLoadId = loadId;
      this.verifyModalStatus = this.$store.getters.verifyStatuses.REFINE;
      if (notes) {
        this.verifyModalNote = notes;
      }
      this.verifyModal = true;
    },
    onMarkAsRefined (userId, loadId, notes) {
      this.verifyModalUserId = userId;
      this.verifyModalLoadId = loadId;
      this.verifyModalStatus = this.$store.getters.verifyStatuses.REFINED;
      if (notes) {
        this.verifyModalNote = notes;
      }
      this.verifyModal = true;
    },
    onMarkAsWFA (userId, loadId, notes) {
      this.verifyModalUserId = userId;
      this.verifyModalLoadId = loadId;
      console.warn(this.$store.getters);
      this.verifyModalStatus = this.$store.getters.verifyStatuses.WAITING_FURTHER_ACTIONS;
      if (notes) {
        this.verifyModalNote = notes;
      }
      this.verifyModal = true;
    },
    onRequestEdit (userId, loadId, notes) {
      this.verifyModalUserId = userId;
      this.verifyModalLoadId = loadId;
      this.verifyModalStatus = this.$store.getters.verifyStatuses.EDITING_REQUEST;
      if (notes) {
        this.verifyModalNote = notes;
      }
      this.verifyModal = true;
    },
    markAsRefine () {
      if (this.verifyModalNote.length === 0) {
        this.verifyModalNoteErrorMsg = this.$root.t('Validators.FieldIsRequired');
        return;
      }
      this.verifyModalNoteErrorMsg = '';
      this.setVerified(this.verifyModalUserId, this.verifyModalLoadId, this.verifyModalStatus, this.verifyModalNote);
    },
    updateVerifyNote () {
      this.editVerifiedNote(this.verifyModalEditId, this.verifyModalNote).then((response) => {
        if (response.status === 'ok') {
          this.fetchData();
          this.verifyModalEditId = 0;
          this.verifyModalUserId = 0;
          this.verifyModalLoadId = 0;
          this.verifyModalStatus = 0;
          this.verifyModalNote = '';
          this.verifyModal = false;
        }
      });
    },
    setVerified (userId, loadId, status = 1, note = '') {
      this.addVerifiedNote(userId, loadId, status, note).then((response) => {
        if (response.status === 'ok') {
          this.fetchData();
          this.verifyModalEditId = 0;
          this.verifyModalUserId = 0;
          this.verifyModalLoadId = 0;
          this.verifyModalStatus = 0;
          this.verifyModalNote = '';
          this.verifyModal = false;
        }
      });
    },
    getEditingOverrideName (value) {
      let i = 0;
      for (i = 0; i < this.$store.getters.editOverrideOptions.length; i++) {
        if (this.$store.getters.editOverrideOptions[i].value === value) {
          return this.$store.getters.editOverrideOptions[i].text;
        }
      }
      return 'N/A';
    },
    onOverrideEditLockChange () {
      this.$dialog.confirm(this.$i18n.t('PleaseConfirmYourChoice')).then(dialog => {
        this.$root.setEditOverride(this.loadId, this.isOverrideEditLock);
        this.setVerified(
          this.fields.dispatcher,
          this.loadId,
          this.$store.getters.verifyStatuses.EDITING_OVERRIDE,
          this.getEditingOverrideName(this.isOverrideEditLock));
      });
    },
    onReturnSwitchChange () {
      if (this.isReturnToHQ) {
        this.$root.setDefaultHQ = async (place) => {
          this.addReturnToHQToLoad(this.loadId, place).then(() => {
            this.fetchData();
          });
        };
        this.$root.getDefaultHQ();
      } else {
        this.removeReturnToHQFromLoad(this.loadId).then(() => {
          this.fetchData();
        });
      }
    },
    onIsEasyModeSwitchChange () {
      this.$root.saveLoad({
        isEasyMode: this.isEasyMode ? 1 : 0
      }, this.loadId).then((response) => {
        this.locationsListKey = 'locationsListKey' + new Date().getTime();
      });
    },
    onIsDriverAllowedReturnToHQChange () {
      this.$root.saveLoad({
        isDriverAllowedReturnToHQ: this.isDriverAllowedReturnToHQ ? 1 : 0
      }, this.loadId);
    },
    onIsDriverAllowedToContinueChange () {
      this.$root.saveLoad({
        isDriverAllowedToContinue: this.isDriverAllowedToContinue ? 1 : 0
      }, this.loadId);
    },
    updateLoadLocations () {
      if (!this.loadId) return;
      // Check if we still on the load page
      if (this.$route.path.indexOf('/loads/page/') < 0) return;

      this.$root.getLoadUpdated(this.loadId).then((response) => {
        if (response.status === 'ok') {
          if (this.$store.getters['loads/getLastLoadUpdated'] !== response.results) {
            this.$store.commit('loads/updateLoadUpdated', response.results);
            this.fetchData();
          }
          setTimeout(this.updateLoadLocations, this.$store.getters.checkInterval);
        }
      });
    },
    setFinished (finishedValue = 1) {
      // let i = 0;
      // let notApprovedNumber = 0;
      let confirmMsgLang = 'Loads.finishWithTONUConfirmationMessage';
      if (finishedValue === 0) confirmMsgLang = 'Loads.notFinishConfirmationMessage';
      if (finishedValue === 1) confirmMsgLang = 'Loads.finishConfirmationMessage';
      if (finishedValue === 2) confirmMsgLang = 'Loads.finishWithTONUConfirmationMessage';
      if (finishedValue === 3) confirmMsgLang = 'Loads.finishWithCancelConfirmationMessage';

      this.$dialog.confirm(this.$root.t(confirmMsgLang)).then(dialog => {
        /* // If load canceled ask about cancel all not approved locations
        if (finisedValue === 3) {
          // this.$dialog.confirm(this.$root.t('Loads.onCancelAllNotApprovedLocationsMsg')).then(dialog => {
          for (i = 0; i < this.locations.length; i++) {
            if (parseInt(this.locations[i].approvedByDispatcherTS) === 0) {
              notApprovedNumber = this.locations[i].number;
              break;
            }
          }
          this.$root.cancelAllNextLocations(this.loadId, notApprovedNumber);
        } */
        this.loading = true;
        this.$root.finishLoad(this.loadId, finishedValue, this.isReturnToHQ).then((response) => {
          if (response.status === 'ok') {
            if (response.addReturnToHQLocation) {
              this.$root.setDefaultHQ = async (place) => {
                this.addReturnToHQToLoad(this.loadId, place).then(() => {
                  this.fetchData();
                });
              }
              this.$root.getDefaultHQ();
            }
            this.loading = false;
            this.fetchData();
          }
        });
      }).catch(() => {
      });
    },
    onCloneLoad () {
      if (this.locations.length > 0) {
        const firstLocationId = this.locations[0].id.toString();
        if (this.appointmentData && this.appointmentData[firstLocationId] && this.appointmentData[firstLocationId].length > 0) {
          this.clonedAppointmentDetails = this.appointmentData[firstLocationId][0].details.replace(/<p><br><\/p>/img, '').replace(/(<p[^>]+?>|<p>)/img, '').replace(/<\/p>/img, '\n');
        }
      }
      this.cloneModal = true;
    },
    cloneLoad () {
      this.$dialog.confirm(this.$root.t('Loads.CloneConfirmationMessage')).then(dialog => {
        this.$root.cloneLoad(this.loadId, this.clonedAppointmentDetails).then((response) => {
          if (response && response.status === 'ok') {
            this.$dialog.confirm(this.$root.t('Loads.GoToClonedLoads')).then(dialog => {
              if (response.clonedLoadId && parseInt(response.clonedLoadId) > 0) {
                this.$root.route('/loads/page/' + response.clonedLoadId);
              }
            });
          } else {
            if (response && response.msg) {
              this.$dialog.alert(response.msg, {
                okText: 'Ok'
              });
            }
            console.error(response);
          }
        });
      }).catch(() => {
      });
    },
    saveNotes () {
      return new Promise((resolve, reject) => {
        this.$dialog.confirm(this.$root.t('SaveNotesConfirmationMsg')).then(dialog => {
          // Save
          if (this.loadId) {
            this.$root.saveLoad({ notes: this.fields.notes }, this.loadId);
          }
          if (this.invoiceId > 0) {
            this.$root.saveInvoice({ notes: this.fields.notes }, this.invoiceId);
          }
        }).catch(() => {
        });
      });
    },
    tabChanged (value) {
      if (!this.mounted) return;
      this.$root.setLocalStorageItem('load_tab', this.tabs);
    },
    openTrackingNotificationsModal () {
      this.trackingNotificationsDialog = true;
      this.trackingNotificationsDialogKey = new Date().getTime();
    },
    onTrackingNotificationsChanged (isEnabled) {
      this.isTrackingNotifications = isEnabled;
      this.trackingNotificationsDialog = false;
      this.fetchData();
    },
    onTrackingNotificationsClosed () {
      this.trackingNotificationsDialog = false;
    },
    saveDocuments (data) {
      let fields = {};
      fields[data.field] = data.value;
      this.$root.saveLoad(fields, this.loadId);
    },
    save () {
      return new Promise((resolve, reject) => {
        this.$dialog.confirm(this.$root.t('Loads.SaveConfirmationMsg')).then(dialog => {
          this.$root.saveLoad({
            Detention: parseFloat(this.fields.Detention),
            Layover: parseFloat(this.fields.Layover),
            Fine: parseFloat(this.fields.Fine),
            DriverAssist: parseFloat(this.fields.DriverAssist),
            Idle: parseFloat(this.fields.Idle)
          }, this.loadId).then(() => {
            resolve();
          });
        }).catch((e) => {
          console.error('ERROR', e);
          reject(e);
        });
      });
    },
    saveFiles (field) {
      return new Promise((resolve, reject) => {
        this.$dialog.confirm(this.$root.t('Loads.SaveFilesConfirmationMsg')).then(dialog => {
          let fields = {};
          fields[field] = this.fields[field];
          this.$root.saveLoad(fields, this.loadId).then(() => {
            this.$set(this.isFilesUpdated, field, false);
            resolve();
          });
        }).catch(() => {
        });
      });
    },
    updateMap () {
      this.$nextTick(() => {
        if (this.$refs.map) {
          this.$refs.map.init();
          this.$forceUpdate();
        }
      });
    },
    deleteLoad () {
      this.$dialog.confirm(this.$root.t('Loads.LoadDeleteConfirmationMessage')).then(dialog => {
        this.$root.deleteLoad(this.loadId);
        this.$root.route('/loads/list');
        this.$root.loadModalKey = new Date().getTime();
        this.$root.refresh();
      });
    },
    restoreLoad () {
      this.$dialog.confirm(this.$root.t('Loads.LoadRestoreConfirmationMessage')).then(dialog => {
        this.$root.restoreLoad(this.loadId);
        this.fetchData();
        this.$root.loadModalKey = new Date().getTime();
        this.$root.refresh();
      });
    },
    fetchData () {
      if (this.loadId) {
        if (!this.isLoadsViewAllowed) {
          this.error = this.$root.t('Errors.AccessDeniedAuthorizationFailed');
          this.loading = false;
          return;
        }
      }

      this.updatingEnabled = true;
      this.applyRights('', 'loads', {
        rateType: 0,
        changeLocationsList: 0,
        updateLocationsStatus: 0,
        updateLocationsDetails: 0,
        locationOdometer: 0,
        odometerUPLOAD: 0,
        expenses: 0,
        deleteLoadPhotos: 0,
        deleteDocuments: 0
      });

      return new Promise((resolve, reject) => {
        let d = 0;
        this.$root.getLoad(this.loadId).then((response) => {
          if (response.status === 'ok') {
            this.isSetDriverOORateAllowed = response.isSetDriverOORateAllowed;
            this.mailsCount = parseInt(response.mailsCount);
            this.invoicesArray = response.invoicesArray;
            this.tasksArray = response.tasksArray;
            this.appointmentData = response.appointmentData;
            this.isInvoiceCreateAllowed = response.isInvoiceCreateAllowed;
            this.isLoadPhotosRequired = response.isLoadPhotosRequired;
            this.isRateConRequired = response.isRateConRequired;
            this.isDeliveryOrderRequired = response.isDeliveryOrderRequired;
            this.isProfOfDeliveryRequired = response.isProfOfDeliveryRequired;
            this.isBillOfLandingRequired = response.isBillOfLandingRequired;
            this.isOverrideEditLockAllowed = response.isOverrideEditLockAllowed;
            this.isOverrideEditLock = parseInt(response.result.isOverrideEditLock);
            this.$store.dispatch('loads/setChildLocations', response.childLocations);

            if (response.hasOwnProperty('trucks')) {
              for (d = 0; d < response.trucks.length; d++) {
                this.trucksInfo[parseInt(response.trucks[d].id)] = {
                  id: response.trucks[d].id,
                  name: response.trucks[d].name,
                  truckAvatarUPLOAD: response.trucks[d].truckAvatarUPLOAD
                };
              }
            }
            // Set users
            if (response.hasOwnProperty('users')) {
              for (d = 0; d < response.users.length; d++) {
                // Set driver info
                this.usersInfo[parseInt(response.users[d].id)] = {
                  id: response.users[d].id,
                  name: response.users[d].name,
                  firstName: response.users[d].firstName,
                  lastName: response.users[d].lastName,
                  avatarUPLOAD: response.users[d].avatarUPLOAD
                };
              }
            }
            // Set drivers rates
            if (response.hasOwnProperty('drivers')) {
              for (d = 0; d < response.drivers.length; d++) {
                // Set driver info
                this.driversInfo[parseInt(response.drivers[d].id)] = {
                  id: response.drivers[d].id,
                  name: response.drivers[d].name,
                  firstName: response.drivers[d].firstName,
                  lastName: response.drivers[d].lastName,
                  avatarUPLOAD: response.drivers[d].avatarUPLOAD
                };
                // Set driver rates
                this.driversRates[response.drivers[d].id] = [];
                if (response.drivers[d].driverRates) {
                  this.driversRates[response.drivers[d].id] = response.drivers[d].driverRates;
                }
                this.driversRates[response.drivers[d].id].unshift({
                  name: 'Current Rate',
                  soloRate: parseFloat(response.drivers[d].driverCurrentSoloRate),
                  teamRate: parseFloat(response.drivers[d].driverCurrentTeamRate) });
              }
            }
            // Check and set invoice id if available
            if (response.result['invoiceId']) {
              this.invoiceId = parseInt(response.result['invoiceId']);
            }
            this.$root.isHideTopEditBtn = !response.isEditAllowed;
            // Set prev next id
            this.prevLoadId = parseInt(response.prev_id);
            this.nextLoadId = parseInt(response.next_id);

            this.$store.commit('loads/updateLoadUpdated', response.result['updated']);

            this.creator = response.result['creator'];
            this.fields.type = response.result['type'];
            this.fields.uid = response.result['uid'];
            this.fields.title = response.result['title'];
            this.fields.bid = response.result['bid'];
            this.fields.dispatcher = response.result['dispatcher'];
            this.fields.truck = response.result['truck'];
            this.fields.truckOO = response.result['truckOO'];
            this.fields.broker = response.result['broker'];
            this.fields.started = response.result['started'];
            this.fields.ended = response.result['ended'];
            this.fields.deleted = response.result['deleted'];
            this.fields.parent = response.result['parent'];
            this.fields.Rate = response.result['Rate'];
            this.fields.RatePerMile = response.result['RatePerMile'];
            this.fields.distanceInMiles = response.result['distanceInMiles'];
            this.fields.bid = response.result['bid'];
            this.fields.driverOO = response.result['driverOO'];
            this.fields.driver = response.result['driver'];
            this.fields.codriver = response.result['codriver'];
            this.fields.Detention = response.result['Detention'];
            this.fields.Layover = response.result['Layover'];
            this.fields.Fine = response.result['Fine'];
            this.fields.DriverAssist = response.result['DriverAssist'];
            this.fields.Idle = response.result['Idle'];
            this.fields.shipper = response.result['shipper'];
            this.fields.consignee = response.result['consignee'];

            this.fields.EmptyMiles = response.result['EmptyMiles'];

            this.fields.rateConUPLOAD = response.result['rateConUPLOAD'];
            this.fields.publicRateConUPLOAD = response.result['publicRateConUPLOAD'];
            this.fields.doUPLOAD = response.result['doUPLOAD'];
            this.fields.bolUPLOAD = response.result['bolUPLOAD'];
            this.fields.podUPLOAD = response.result['podUPLOAD'];
            this.fields.loadPhotosUPLOAD = response.result['loadPhotosUPLOAD'];
            this.fields.driversReportsUPLOAD = response.result['driversReportsUPLOAD'];

            this.rateConUPLOADCount = response.result['rateConUPLOAD'] ? JSON.parse(response.result['rateConUPLOAD']).length : 0;
            this.publicRateConUPLOADCount = response.result['publicRateConUPLOAD'] ? JSON.parse(response.result['publicRateConUPLOAD']).length : 0;
            this.doUPLOADCount = response.result['doUPLOAD'] ? JSON.parse(response.result['doUPLOAD']).length : 0;
            this.bolUPLOADCount = response.result['bolUPLOAD'] ? JSON.parse(response.result['bolUPLOAD']).length : 0;
            this.podUPLOADCount = response.result['podUPLOAD'] ? JSON.parse(response.result['podUPLOAD']).length : 0;
            this.loadPhotosUPLOADCount = response.result['loadPhotosUPLOAD'] ? JSON.parse(response.result['loadPhotosUPLOAD']).length : 0;

            this.fields.dispatcherPaymentPercent = response.result['dispatcherPaymentPercent'];
            this.fields.dispatcherPaymentPercentFromBonus = response.result['dispatcherPaymentPercentFromBonus'];
            this.fields.dispatcherPartialRate = response.result['dispatcherPartialRate'];
            this.fields.dispatcherLTLRate = response.result['dispatcherLTLRate'];
            this.fields.dispatcherReturnRate = response.result['dispatcherReturnRate'];

            this.fields.isExcludeDispatcherPayment = response.result['isExcludeDispatcherPayment'];

            this.fields.isFactoring = response.result['isFactoring'];
            this.fields.factoringPercent = response.result['factoringPercent'];
            this.fields.isDriverOwnerOperator = response.result['isDriverOwnerOperator'];
            this.fields.driverOwnerOperatorBonus = response.result['driverOwnerOperatorBonus'];
            this.fields.driverOwnerOperatorPercent = response.result['driverOwnerOperatorPercent'];

            this.fields.driverRate = response.result['driverRate'];
            this.fields.codriverRate = response.result['codriverRate'];

            this.fields.isEmptyMiles = response.result['isEmptyMiles'];
            this.fields.driverPickupMilesRate = response.result['driverPickupMilesRate'];
            this.fields.codriverPickupMilesRate = response.result['codriverPickupMilesRate'];

            this.fields.isPickupMiles = response.result['isPickupMiles'];
            this.fields.driverPickupMilesRate = response.result['driverPickupMilesRate'];
            this.fields.codriverPickupMilesRate = response.result['codriverPickupMilesRate'];

            this.fields.isZipToZipMiles = response.result['isZipToZipMiles'];
            this.fields.driverZipToZipRate = response.result['driverZipToZipRate'];
            this.fields.codriverZipToZipRate = response.result['codriverZipToZipRate'];

            this.fields.isLocalLoad = response.result['isLocalLoad'];
            this.fields.driverLocalRate = response.result['driverLocalRate'];
            this.fields.codriverLocalRate = response.result['codriverLocalRate'];

            this.fields.isAllMiles = response.result['isAllMiles'];
            this.fields.driverAllMilesRate = response.result['driverAllMilesRate'];
            this.fields.codriverAllMilesRate = response.result['codriverAllMilesRate'];

            this.$set(this.fields, 'isReturnToHQ', response.result['isReturnToHQ']);
            this.$set(this, 'isReturnToHQ', parseInt(this.fields.isReturnToHQ) === 1);

            this.fields.isDriverAllowedReturnToHQ = response.result['isDriverAllowedReturnToHQ'];
            this.isDriverAllowedReturnToHQ = parseInt(this.fields.isDriverAllowedReturnToHQ) === 1;

            this.fields.isDriverAllowedToContinue = response.result['isDriverAllowedToContinue'];
            this.$set(this, 'isDriverAllowedToContinue', parseInt(this.fields.isDriverAllowedToContinue) === 1);

            this.fields.driverReturnRate = response.result['driverReturnRate'];
            this.fields.codriverReturnRate = response.result['codriverReturnRate'];

            this.fields.isDedicatedTruck = response.result['isDedicatedTruck'];
            this.fields.isEasyMode = response.result['isEasyMode'];
            this.$set(this, 'isEasyMode', parseInt(this.fields.isEasyMode) === 1);
            this.fields.notes = response.result['notes'];

            this.vrData = response.result['vrData'];
            if (this.vrData.length > 0) {
              const vrLast = this.vrData[this.vrData.length - 1];
              this.fields.vrStatus = parseInt(vrLast['status']);
              this.fields.vrNote = vrLast['note'];
              this.fields.vrRefineNote = vrLast['refineNote'];
              this.fields.vrRefinedNote = vrLast['refinedNote'];
              this.fields.vrEditingRequestNote = vrLast['editingRequestNote'];
              this.fields.vrCreatedPST = vrLast['createdPST'];
              this.fields.vrUpdatedPST = vrLast['updatedPST'];
              this.fields.vrCreator = vrLast['userCreated'];
              this.fields.vrCreatorFirstName = vrLast['firstName'];
              this.fields.vrCreatorAvatarUPLOAD = vrLast['avatarUPLOAD'];
              this.fields.vrCreatorLastName = vrLast['lastName'];
            }

            this.dispatcherName = response.result['dispatcherName'];
            this.brokerName = response.result['brokerName'];
            this.truckName = response.result['truckName'];
            this.truckOOName = response.result['truckOOName'];
            this.driverOOName = response.result['driverOOName'];
            this.driverName = response.result['driverName'];
            this.codriverName = response.result['codriverName'];
            this.bidSubject = response.result['bidSubject'];
            this.fields.finished = parseInt(response.result['finished']);
            this.finished = parseInt(response.result['finished']);
            this.isFinished = parseInt(response.result['finished']) !== 0;

            if (parseInt(this.fields.parent) > 0) {
              this.parentLoadId = response.result['parentLoadId'];
              this.parentLoadUID = response.result['parentLoadUID'];
              this.parentLoadTitle = response.result['parentLoadTitle'];
            }

            this.truckLat = response.result['truckLat'];
            this.truckLng = response.result['truckLng'];

            this.$store.commit('loads/setTruckLatLng', {
              lat: this.truckLat,
              lng: this.truckLng
            });

            if (response.result['truckKeeptruckindata']) {
              this.truckTelemetry = JSON.parse(response.result['truckKeeptruckindata']);
            }

            if (response.result['expensesJSON']) {
              this.expenses = JSON.parse(response.result['expensesJSON']);
            }

            if (response.result['locationsJSON']) {
              this.locations = JSON.parse(response.result['locationsJSON'].replace(/\n/g, '\\n').replace(/\r/g, '\\r').replace(/\t/g, '\\t'));
              if (this.locations.length > 1) {
                this.pickupLocationName = this.$root.trimAddress(this.locations[1].name);
                this.deliveryLocationName = this.$root.trimAddress(this.locations[this.locations.length - 1].name);
              }
            }
            // Tracking Notifications
            if (response.result.hasOwnProperty('isTrackingNotifications')) {
              this.isTrackingNotifications = parseInt(response.result['isTrackingNotifications']) === 1;
            }
            if (response.result.hasOwnProperty('isSendTrackingInfoByRequest')) {
              this.isTrackingNotificationsSendByRequest = parseInt(response.result['isSendTrackingInfoByRequest']) === 1;
            }
            if (response.result.hasOwnProperty('trackingNotificationsInterval')) {
              this.trackingNotificationsInterval = parseInt(response.result['trackingNotificationsInterval']);
            }
            if (response.result.trackingNotificationsData && response.result.trackingNotificationsData.indexOf('{') === 0) {
              const trackingNotificationsData = JSON.parse(response.result.trackingNotificationsData);
              if (trackingNotificationsData.hasOwnProperty('subject')) {
                this.trackingNotificationsSubject = trackingNotificationsData['subject'];
              }
              if (trackingNotificationsData.hasOwnProperty('message')) {
                this.trackingNotificationsMessage = trackingNotificationsData['message'];
              }
              if (trackingNotificationsData.hasOwnProperty('recipients')) {
                this.trackingNotificationsRecipients = trackingNotificationsData['recipients'];
              }
              if (trackingNotificationsData.hasOwnProperty('in_copy')) {
                this.trackingNotificationsInCopy = trackingNotificationsData['in_copy'];
              }
            } else {
              // Load tracking notifications subject and message from the global settings
              this.$root.getGlobalSettingsField('trackingEmailSubjectTemplate').then((response) => {
                if (response.status === 'ok') {
                  this.trackingNotificationsSubject = response.result;
                }
              });
              this.$root.getGlobalSettingsField('trackingEmailMessageTemplate').then((response) => {
                if (response.status === 'ok') {
                  this.trackingNotificationsMessage = response.result;
                }
              });
            }

            // Calc total
            this.totalDuration = 0;
            this.totalDistance = 0;
            if (this.locations.length > 0) {
              for (let i = 0; i < this.locations.length - 1; i++) {
                this.totalDuration += this.locations[i].duration;
                this.totalDistance += this.locations[i].distance;
              }
            }

            this.$store.dispatch('loads/setLocations', this.locations);
            this.loadProgressBarKey = 'lpbk' + new Date().getTime();
            this.loadProgressKey = 'lpk' + new Date().getTime();
            this.locationsListKey = 'locationsListKey' + new Date().getTime();
            this.updateMap();
          } else {
            this.error = response.msg;
          }
          resolve();
          this.loading = false;
        });
      });
    }
  }
};
</script>
