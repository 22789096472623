<template>
  <div>
    <div v-if="error !== ''">
      <v-flex class="text-center error pa-2">
        <h1 class="title">{{error}}</h1>
      </v-flex>
    </div>
    <div v-else style="page-break-inside: avoid; page-break-after: avoid; page-break-before: avoid;">
      <v-card tile class="ma-0" flat :loading="loading" style="width: 23cm !important; margin: auto !important;">
        <v-card-actions>
          <!--<v-btn @click="$emit('print', truckId, reportId)" v-if="reportId"><v-icon left>mdi-printer</v-icon>{{$t('Print')}}</v-btn>-->
          <v-layout>
            <v-text-field v-model="fields.miles"
                          :label="$t('Trucks.AnnualInspectionsOdometerMiles')"
                          :disabled="isReadOnly"
                          prepend-inner-icon="mdi-counter"
                          dense outlined
                          class="mt-5"/>
            <v-checkbox :label="$t('Trucks.AnnualInspectionsQuarterly')" :disabled="isReadOnly" v-model="fields.isQuaterly" class="ml-8 mt-5"/>
            <v-checkbox :label="$t('Trucks.AnnualInspectionsAnnual')" :disabled="isReadOnly" v-model="fields.isAnnual" class="ml-2 mt-5"/>
          </v-layout>
          <v-spacer/>
          <v-btn v-if="!printed && !isReadOnly && !reportId" class="primary" :loading="loadingCreate" @click="save(reportId)">{{reportId ? $t('Save') : $t('Create')}}</v-btn>
          <v-btn v-if="!printed && reportId > 0" @click="$emit('download', fileUID, reportNumber)"><v-icon left>mdi-file-pdf-box</v-icon>{{$t('Download')}}</v-btn>
          <v-btn v-if="!printed && reportId > 0" :loading="loadingCreate" class="error" @click="remove(reportId)"><v-icon left>mdi-delete</v-icon>{{$t('Delete')}}</v-btn>
        </v-card-actions>
        <v-card-text>
          <v-layout style="border-spacing: 0px;">
            <v-flex xs6 class="pa-2">
              <img responsive height="80" :src="logoPath"/>
            </v-flex>
            <v-flex xs6>
                <table style="margin: auto; border-spacing: 0px; float: right;">
                  <tr>
                    <td colspan="2" class="text-center white--text" style="border: 1px solid black; background-color: #666; ">ANNUAL INSPECTION REPORT</td>
                  </tr>
                  <tr>
                    <td style="border: 1px solid black;">REPORT NUMBER</td>
                    <td style="border: 1px solid black;">FLEET UNIT NUMBER</td>
                  </tr>
                  <tr>
                    <td style="border: 1px solid black;">
                      <v-text-field v-if="!isReadOnly" dense outlined hide-details v-model="fields.reportNumber"/>
                      <div v-else class="font-weight-bold">{{fields.reportNumber}}&nbsp;</div>
                    </td>
                    <td style="border: 1px solid black;">
                      <v-text-field v-if="!isReadOnly" dense outlined hide-details v-model="fields.fleetUnitNumber"/>
                      <div v-else class="font-weight-bold">{{fields.fleetUnitNumber}}&nbsp;</div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="border: 1px solid black;" class="pt-1">
                      <v-text-field v-if="!isReadOnly" dense outlined hide-details v-model="fields.reportDATE" maxlength="10" label="Date" @change="reportDATEChanged"/>
                      <div v-else class="font-weight-bold">{{fields.reportDATE}}&nbsp;</div>
                    </td>
                  </tr>
                </table>
            </v-flex>
          </v-layout>
          <table style="width: 100%; margin: auto; border-spacing: 0px; background-color: white !important;" class="table-annual-inspection-meta" :key="'meta'+fetchKey">
            <tr>
              <td style="border: 1px solid black; width: 50%;">
                <v-text-field v-if="!isReadOnly" dense outlined hide-details class="mt-1" label="MOTOR CARRIER OPERATOR" v-model="fields.motorCarrierOperator"/>
                <div v-else class="font-weight-bold pl-1">
                  <small class="ma-0 pa-0 d-block text--secondary" style="height:0.6rem;">MOTOR CARRIER OPERATOR</small>
                  {{fields.motorCarrierOperator}}&nbsp;
                </div>
              </td>
              <td style="border: 1px solid black; width: 50%;">
                <v-text-field v-if="!isReadOnly" dense outlined hide-details class="mt-1" label="INSPECTOR'S NAME (PRINT OR TYPE)" v-model="fields.inspectorsName"/>
                <div v-else class="font-weight-bold pl-1">
                  <small class="ma-0 pa-0 d-block text--secondary" style="height:0.6rem;">INSPECTOR'S NAME (PRINT OR TYPE)</small>
                  {{fields.inspectorsName}}&nbsp;
                </div>
              </td>
            </tr>
            <tr>
              <td style="border: 1px solid black;" class="pt-1">
                <v-text-field v-if="!isReadOnly" dense outlined hide-details label="ADDRESS" v-model="fields.address"/>
                <div v-else class="font-weight-bold pl-1">
                  <small class="ma-0 pa-0 d-block text--secondary" style="height:0.6rem;">ADDRESS</small>
                  {{fields.address}}&nbsp;
                </div>
              </td>
              <td style="border: 1px solid black; padding-top: 5px; overflow: auto;">
                <v-layout :wrap="!isReadOnly">
                  <v-flex class="pl-1" style="font-size: 0.5rem;">THIS INSPECTOR MEETS THE QUALIFICATION REQUIREMENTS IN SECTION 396.19</v-flex>
                  <v-flex v-if="!isReadOnly"><v-checkbox dense hide-details label="Yes" v-model="fields.isInspectorMeetsTheQualification"/></v-flex>
                  <v-flex v-else class="font-weight-bold pl-1 text-no-wrap">
                    <v-checkbox dense
                                class="ma-0 pa-0"
                                disabled
                                hide-details
                                :label="fields.isInspectorMeetsTheQualification ? $t('Yes') : $t('No')"
                                v-model="fields.isInspectorMeetsTheQualification"/>
                  </v-flex>
                </v-layout>
              </td>
            </tr>
            <tr>
              <td style="border: 1px solid black;" class="pt-1">
                <v-textarea v-if="!isReadOnly" rows="3" dense outlined hide-details label="CITY, STATE, ZIP CODE" v-model="fields.cityStateZip"/>
                <div v-else class="font-weight-bold pl-1">
                  <small class="ma-0 pa-0 d-block text--secondary" style="height:0.6rem;">CITY, STATE, ZIP CODE</small>
                  {{fields.cityStateZip}}&nbsp;
                </div>
              </td>
              <td  style="border: 1px solid black;" v-if="!isReadOnly">
                <v-layout wrap>
                  <v-flex class="ml-1">
                    VEHICLE IDENTIFICATION (&check;) AND COMPLETE
                  </v-flex>
                  <v-flex style="margin-top: -10px;">
                    <v-checkbox dense hide-details @change="vehicleIndentificationChanged('vehicleIndentificationLicPlate')" v-model="vehicleIndentificationLicPlate">
                      <template v-slot:label>
                        <small>LIC. PLATE NO</small>
                      </template>
                    </v-checkbox>
                  </v-flex>
                  <v-flex style="margin-top: -10px;">
                    <v-checkbox dense hide-details @change="vehicleIndentificationChanged('vehicleIndentificationVin')" v-model="vehicleIndentificationVin">
                      <template v-slot:label>
                        <small>VIN</small>
                      </template>
                    </v-checkbox>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex shrink>
                    <v-checkbox dense hide-details label="" @change="vehicleIndentificationChanged('vehicleIndentificationOther')" v-model="vehicleIndentificationOther">
                      <template v-slot:label>
                        <small>OTHER</small>
                      </template>
                    </v-checkbox>
                  </v-flex>
                  <v-flex grow class="text-center pl-2 pr-2">
                    <v-text-field dense outlined hide-details v-model="fields.vehicleIdentification"/>
                  </v-flex>
                </v-layout>
              </td>
              <td style="border: 1px solid black;" v-else>
                <v-layout wrap>
                  <v-flex class="ml-1" style="font-size: 0.5rem;">
                    VEHICLE IDENTIFICATION (&check;) AND COMPLETE
                  </v-flex>
                  <v-flex style="margin-top: -10px;">
                    <v-checkbox dense hide-details @change="vehicleIndentificationChanged('vehicleIndentificationLicPlate')" v-model="vehicleIndentificationLicPlate">
                      <template v-slot:label>
                        <small style="font-size: 0.5rem;">LIC. PLATE NO</small>
                      </template>
                    </v-checkbox>
                  </v-flex>
                  <v-flex style="margin-top: -10px;">
                    <v-checkbox dense hide-details @change="vehicleIndentificationChanged('vehicleIndentificationVin')" v-model="vehicleIndentificationVin">
                      <template v-slot:label>
                        <small style="font-size: 0.5rem;">VIN</small>
                      </template>
                    </v-checkbox>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex shrink style="margin-top: -13px;">
                    <v-checkbox dense hide-details label="" @change="vehicleIndentificationChanged('vehicleIndentificationOther')" v-model="vehicleIndentificationOther">
                      <template v-slot:label>
                        <small style="font-size: 0.5rem;">OTHER</small>
                      </template>
                    </v-checkbox>
                  </v-flex>
                  <v-flex grow class="text-center" style="margin-top: -5px;">
                    <div class="font-weight-bold">{{fields.vehicleIdentification}}</div>
                  </v-flex>
                </v-layout>
              </td>
            </tr>
            <tr>
              <td style="border: 1px solid black;" v-if="!isReadOnly">
                <v-layout>
                  <v-flex class="text-no-wrap pl-1">
                    VEHICLE TYPE
                  </v-flex>
                  <v-flex style="margin-top: -10px;">
                    <v-checkbox dense hide-details @change="onVehicleTypeChanged('vehicleTypeTractor')" v-model="vehicleTypeTractor">
                      <template v-slot:label>
                        <small>TRACTOR</small>
                      </template>
                    </v-checkbox>
                  </v-flex>
                  <v-flex style="margin-top: -10px;">
                    <v-checkbox dense hide-details @change="onVehicleTypeChanged('vehicleTypeTrailer')" v-model="vehicleTypeTrailer">
                      <template v-slot:label>
                        <small>TRAILER</small>
                      </template>
                    </v-checkbox>
                  </v-flex>
                  <v-flex style="margin-top: -10px;">
                    <v-checkbox dense hide-details @change="onVehicleTypeChanged('vehicleTypeTruck')" v-model="vehicleTypeTruck">
                      <template v-slot:label>
                        <small>TRUCK</small>
                      </template>
                    </v-checkbox>
                  </v-flex>
                  <v-flex style="margin-top: -10px;">
                    <v-checkbox dense hide-details @change="onVehicleTypeChanged('vehicleTypeOther')" v-model="vehicleTypeOther">
                      <template v-slot:label>
                        <small>OTHER</small>
                      </template>
                    </v-checkbox>
                  </v-flex>
                </v-layout>
                <v-layout v-if="fields.vehicleOther">
                  <v-flex grow class="text-center pl-2 pr-2">
                    <v-text-field dense outlined hide-details v-model="fields.vehicleOther"/>
                  </v-flex>
                </v-layout>
              </td>
              <td style="border: 1px solid black;" v-else>
                <v-layout>
                  <v-flex class="text-no-wrap pl-1" style="font-size: 0.5rem;">
                    VEHICLE TYPE
                  </v-flex>
                  <v-flex style="margin-top: -10px;">
                    <v-checkbox dense hide-details @change="onVehicleTypeChanged('vehicleTypeTractor')" v-model="vehicleTypeTractor">
                      <template v-slot:label>
                        <small style="font-size: 0.5rem;">TRACTOR</small>
                      </template>
                    </v-checkbox>
                  </v-flex>
                  <v-flex style="margin-top: -10px;">
                    <v-checkbox dense hide-details @change="onVehicleTypeChanged('vehicleTypeTrailer')" v-model="vehicleTypeTrailer">
                      <template v-slot:label>
                        <small style="font-size: 0.5rem;">TRAILER</small>
                      </template>
                    </v-checkbox>
                  </v-flex>
                  <v-flex style="margin-top: -10px;">
                    <v-checkbox dense hide-details @change="onVehicleTypeChanged('vehicleTypeTruck')" v-model="vehicleTypeTruck">
                      <template v-slot:label>
                        <small style="font-size: 0.5rem;">TRUCK</small>
                      </template>
                    </v-checkbox>
                  </v-flex>
                  <v-flex style="margin-top: -10px;">
                    <v-checkbox dense hide-details @change="onVehicleTypeChanged('vehicleTypeOther')" v-model="vehicleTypeOther">
                      <template v-slot:label>
                        <small style="font-size: 0.5rem;">OTHER</small>
                      </template>
                    </v-checkbox>
                  </v-flex>
                </v-layout>
                <v-layout v-if="fields.vehicleOther">
                  <v-flex grow class="text-center" style="margin-top: -10px;">
                    <v-text-field v-if="!isReadOnly" dense outlined hide-details v-model="fields.vehicleOther"/>
                    <div v-else class="font-weight-bold">{{fields.vehicleOther}}</div>
                  </v-flex>
                </v-layout>
              </td>
              <td style="border: 1px solid black;" class="pt-1">
                <v-textarea v-if="!isReadOnly" rows="3" dense outlined hide-details label="INSPECTION AGENCY/LOCATION (OPTIONAL)" v-model="fields.inspectionAgencyLocation"/>
                <div v-else class="font-weight-bold">
                  <small class="ma-0 pa-0 d-block text--secondary" style="height:0.6rem;">INSPECTION AGENCY/LOCATION (OPTIONAL)</small>
                  {{fields.inspectionAgencyLocation}}&nbsp;
                </div>
              </td>
            </tr>
          </table>
          <table style="margin: auto; border-spacing: 0px; background-color: white !important;" class="mt-0" :key="fetchKey"  v-if="items && items.length > 0">
            <thead>
              <tr style="background-color: #666;">
                <th colspan="12" class="text-center white--text">VEHICLE COMPONENTS INSPECTED</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="width: 33%; vertical-align: top; border-left: 1px solid black; padding: 0px !important;">
                  <table class="table-annual-inspection">
                    <tr>
                      <th class="ok-label">OK</th>
                      <th class="needs-repair-label">NEEDS REPAIR</th>
                      <th class="repair-date-label">REPAIRED DATE</th>
                      <th class="item-label">ITEM</th>
                    </tr>
                    <tr>
                      <td colspan="3"></td>
                      <td class="inspection-group-label">1.&nbsp;{{items[0].name}}</td>
                    </tr>
                    <template v-for="(child,index) in items[0].childs">
                      <tr :key="child.id">
                        <td class="pa-0 text-center text-center">
                          <v-checkbox v-if="!isReadOnly" dense hide-details class="ma-0 pa-0" @change="isOkChanged(0, index)" v-model="items[0].childs[index].isOkByDefault"/>
                          <small v-else-if="!items[0].childs[index].isOkByDefault && !items[0].childs[index].needsRepair" style="margin-right: 2px; margin-left: 2px;">N/A</small>
                          <template v-else-if="items[0].childs[index].isOkByDefault">&check;</template>
                        </td>
                        <td class="needs-repair-label pa-0 text-center">
                          <v-checkbox v-if="!isReadOnly" dense hide-details class="ma-0 pa-0" @change="isNeedsRepairChanged(0, index)" v-model="items[0].childs[index].needsRepair"/>
                          <template v-else-if="items[0].childs[index].needsRepair">&check;</template>
                        </td>
                        <td class="text-center pa-0">
                          <!--<span v-if="!items[0].childs[index].isOkByDefault && !items[0].childs[index].needsRepair">N/A</span>-->
                          <v-menu v-if="items[0].childs[index].needsRepair"
                                  v-model="repairDateMenus['0_' + index]"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px">
                            <template v-slot:activator="{ on }">
                              <a href="#" v-on="on">{{items[0].childs[index].repairedDATE}}</a>
                            </template>
                            <v-date-picker
                              @change="() => { items[0].childs[index].repairedDATE = $root.formatDate(repairDates['0_' + index]); }"
                              v-model="repairDates['0_' + index]"
                              no-title
                              @input="repairDateMenus['0_' + index] = false"></v-date-picker>
                          </v-menu>
                        </td>
                        <td class="pl-5 pa-0">
                          {{$root.alphabet[index]}}.&nbsp;{{child.name}}
                        </td>
                      </tr>
                    </template>
                    <tr>
                      <td colspan="3"></td>
                      <td class="inspection-group-label">2.&nbsp;{{items[1].name}}</td><!--/////////////////////////////////////////////////////////////////////////////////////////////////-->
                    </tr>
                    <template v-for="(child, index) in items[1].childs">
                      <tr :key="child.id">
                        <td class="text-center">
                          <v-checkbox v-if="!isReadOnly" dense hide-details class="ma-0 pa-0" @change="isOkChanged(1, index)" v-model="items[1].childs[index].isOkByDefault"/>
                          <small v-else-if="!items[1].childs[index].isOkByDefault && !items[1].childs[index].needsRepair" style="margin-right: 2px; margin-left: 2px;">N/A</small>
                          <template v-else-if="items[1].childs[index].isOkByDefault">&check;</template>
                        </td>
                        <td class="needs-repair-label text-center">
                          <v-checkbox v-if="!isReadOnly" dense hide-details class="ma-0 pa-0" @change="isNeedsRepairChanged(1, index)" v-model="items[1].childs[index].needsRepair"/>
                          <template v-else-if="items[1].childs[index].needsRepair">&check;</template>
                        </td>
                        <td class="text-center">
                          <v-menu v-if="items[1].childs[index].needsRepair"
                                  v-model="repairDateMenus['1_' + index]"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px">
                            <template v-slot:activator="{ on }">
                              <a href="#" v-on="on">{{items[1].childs[index].repairedDATE}}</a>
                            </template>
                            <v-date-picker
                              @change="() => { items[1].childs[index].repairedDATE = $root.formatDate(repairDates['1_' + index]); }"
                              v-model="repairDates['1_' + index]"
                              no-title
                              @input="repairDateMenus['1_' + index] = false"></v-date-picker>
                          </v-menu>
                        </td>
                        <td class="pl-4">
                          {{$root.alphabet[index]}}.&nbsp;{{child.name}}
                        </td>
                      </tr>
                    </template>
                    <tr>
                      <td colspan="3"></td>
                      <td class="inspection-group-label">3.&nbsp;{{items[2].name}}</td><!--/////////////////////////////////////////////////////////////////////////////////////////////////-->
                    </tr>
                    <template v-for="(child, index) in items[2].childs">
                      <tr :key="child.id">
                        <td class="text-center">
                          <v-checkbox v-if="!isReadOnly" dense hide-details class="ma-0 pa-0" @change="isOkChanged(2, index)" v-model="items[2].childs[index].isOkByDefault"/>
                          <small v-else-if="!items[2].childs[index].isOkByDefault && !items[2].childs[index].needsRepair" style="margin-right: 2px; margin-left: 2px;">N/A</small>
                          <template v-else-if="items[2].childs[index].isOkByDefault">&check;</template>
                        </td>
                        <td class="needs-repair-label text-center">
                          <v-checkbox v-if="!isReadOnly" dense hide-details class="ma-0 pa-0" @change="isNeedsRepairChanged(2, index)" v-model="items[2].childs[index].needsRepair"/>
                          <template v-else-if="items[2].childs[index].needsRepair">&check;</template>
                        </td>
                        <td class="text-center">
                          <v-menu v-if="items[2].childs[index].needsRepair"
                                  v-model="repairDateMenus['2_' + index]"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px">
                            <template v-slot:activator="{ on }">
                              <a href="#" v-on="on">{{items[2].childs[index].repairedDATE}}</a>
                            </template>
                            <v-date-picker
                              @change="() => { items[2].childs[index].repairedDATE = $root.formatDate(repairDates['2_' + index]); }"
                              v-model="repairDates['2_' + index]"
                              no-title
                              @input="repairDateMenus['2_' + index] = false"></v-date-picker>
                          </v-menu>
                        </td>
                        <td class="pl-4">
                          {{$root.alphabet[index]}}.&nbsp;{{child.name}}
                        </td>
                      </tr>
                    </template>
                    <!--<tr style="height: 230px;">
                      <td></td>
                      <td class="needs-repair-label"></td>
                      <td></td>
                      <td></td>
                    </tr>-->
                  </table>
                </td>

                <td style="width: 33%; vertical-align: top;border-left: 1px solid black;border-right: 1px solid black; padding: 0px !important;">
                  <table class="table-annual-inspection">
                    <tr>
                      <th class="ok-label">OK</th>
                      <th class="needs-repair-label">NEEDS REPAIR</th>
                      <th class="repair-date-label">REPAIRED DATE</th>
                      <th class="item-label">ITEM</th>
                    </tr>
                    <tr>
                      <td colspan="3"></td>
                      <td class="inspection-group-label">4.&nbsp;{{items[3].name}}</td><!--/////////////////////////////////////////////////////////////////////////////////////////////////-->
                    </tr>
                    <template v-for="(child, index) in items[3].childs">
                      <tr :key="child.id">
                        <td class="text-center">
                          <v-checkbox v-if="!isReadOnly" dense hide-details class="ma-0 pa-0" @change="isOkChanged(3, index)" v-model="items[3].childs[index].isOkByDefault"/>
                          <small v-else-if="!items[3].childs[index].isOkByDefault && !items[3].childs[index].needsRepair" style="margin-right: 2px; margin-left: 2px;">N/A</small>
                          <template v-else-if="items[3].childs[index].isOkByDefault">&check;</template>
                        </td>
                        <td class="needs-repair-label text-center">
                          <v-checkbox v-if="!isReadOnly" dense hide-details class="ma-0 pa-0" @change="isNeedsRepairChanged(3, index)" v-model="items[3].childs[index].needsRepair"/>
                          <template v-else-if="items[3].childs[index].needsRepair">&check;</template>
                        </td>
                        <td class="text-center">
                          <v-menu v-if="items[3].childs[index].needsRepair"
                                  v-model="repairDateMenus['3_' + index]"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px">
                            <template v-slot:activator="{ on }">
                              <a href="#" v-on="on">{{items[3].childs[index].repairedDATE}}</a>
                            </template>
                            <v-date-picker
                              @change="() => { items[3].childs[index].repairedDATE = $root.formatDate(repairDates['3_' + index]); }"
                              v-model="repairDates['3_' + index]"
                              no-title
                              @input="repairDateMenus['3_' + index] = false"></v-date-picker>
                          </v-menu>
                        </td>
                        <td class="pl-4">
                          {{$root.alphabet[index]}}.&nbsp;{{child.name}}
                        </td>
                      </tr>
                    </template>
                    <tr>
                      <td colspan="3"></td>
                      <td class="inspection-group-label">5.&nbsp;{{items[4].name}}</td><!--/////////////////////////////////////////////////////////////////////////////////////////////////-->
                    </tr>
                    <template v-for="(child, index) in items[4].childs">
                      <tr :key="child.id">
                        <td class="text-center">
                          <v-checkbox v-if="!isReadOnly" dense hide-details class="ma-0 pa-0" @change="isOkChanged(4, index)" v-model="items[4].childs[index].isOkByDefault"/>
                          <small v-else-if="!items[4].childs[index].isOkByDefault && !items[4].childs[index].needsRepair" style="margin-right: 2px; margin-left: 2px;">N/A</small>
                          <template v-else-if="items[4].childs[index].isOkByDefault">&check;</template>
                        </td>
                        <td class="needs-repair-label text-center">
                          <v-checkbox v-if="!isReadOnly" dense hide-details class="ma-0 pa-0" @change="isNeedsRepairChanged(4, index)" v-model="items[4].childs[index].needsRepair"/>
                          <template v-else-if="items[4].childs[index].needsRepair">&check;</template>
                        </td>
                        <td class="text-center">
                          <v-menu v-if="items[4].childs[index].needsRepair"
                                  v-model="repairDateMenus['4_' + index]"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px">
                            <template v-slot:activator="{ on }">
                              <a href="#" v-on="on">{{items[4].childs[index].repairedDATE}}</a>
                            </template>
                            <v-date-picker
                              @change="() => { items[4].childs[index].repairedDATE = $root.formatDate(repairDates['4_' + index]); }"
                              v-model="repairDates['4_' + index]"
                              no-title
                              @input="repairDateMenus['4_' + index] = false"></v-date-picker>
                          </v-menu>
                        </td>
                        <td class="pl-4">
                          {{$root.alphabet[index]}}.&nbsp;{{child.name}}
                        </td>
                      </tr>
                    </template>
                    <tr>
                      <td colspan="3"></td>
                      <td class="inspection-group-label">6.&nbsp;{{items[5].name}}</td><!--/////////////////////////////////////////////////////////////////////////////////////////////////-->
                    </tr>
                    <template v-for="(child, index) in items[5].childs">
                      <tr :key="child.id">
                        <td class="text-center">
                          <v-checkbox v-if="!isReadOnly" dense hide-details class="ma-0 pa-0" @change="isOkChanged(5, index)" v-model="items[5].childs[index].isOkByDefault"/>
                          <small v-else-if="!items[5].childs[index].isOkByDefault && !items[5].childs[index].needsRepair" style="margin-right: 2px; margin-left: 2px;">N/A</small>
                          <template v-else-if="items[5].childs[index].isOkByDefault">&check;</template>
                        </td>
                        <td class="needs-repair-label text-center">
                          <v-checkbox v-if="!isReadOnly" dense hide-details class="ma-0 pa-0" @change="isNeedsRepairChanged(5, index)" v-model="items[5].childs[index].needsRepair"/>
                          <template v-else-if="items[5].childs[index].needsRepair">&check;</template>
                        </td>
                        <td class="text-center">
                          <v-menu v-if="items[5].childs[index].needsRepair"
                                  v-model="repairDateMenus['5_' + index]"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px">
                            <template v-slot:activator="{ on }">
                              <a href="#" v-on="on">{{items[5].childs[index].repairedDATE}}</a>
                            </template>
                            <v-date-picker
                              @change="() => { items[5].childs[index].repairedDATE = $root.formatDate(repairDates['5_' + index]); }"
                              v-model="repairDates['5_' + index]"
                              no-title
                              @input="repairDateMenus['5_' + index] = false"></v-date-picker>
                          </v-menu>
                        </td>
                        <td class="pl-4">
                          {{$root.alphabet[index]}}.&nbsp;{{child.name}}
                        </td>
                      </tr>
                    </template>
                    <tr>
                      <td colspan="3"></td>
                      <td class="inspection-group-label">7.&nbsp;{{items[6].name}}</td><!--/////////////////////////////////////////////////////////////////////////////////////////////////-->
                    </tr>
                    <template v-for="(child, index) in items[6].childs">
                      <tr :key="child.id">
                        <td class="text-center">
                          <v-checkbox v-if="!isReadOnly" dense hide-details class="ma-0 pa-0" @change="isOkChanged(6, index)" v-model="items[6].childs[index].isOkByDefault"/>
                          <small v-else-if="!items[6].childs[index].isOkByDefault && !items[6].childs[index].needsRepair" style="margin-right: 2px; margin-left: 2px;">N/A</small>
                          <template v-else-if="items[6].childs[index].isOkByDefault">&check;</template>
                        </td>
                        <td class="needs-repair-label text-center">
                          <v-checkbox v-if="!isReadOnly" dense hide-details class="ma-0 pa-0" @change="isNeedsRepairChanged(6, index)" v-model="items[6].childs[index].needsRepair"/>
                          <template v-else-if="items[6].childs[index].needsRepair">&check;</template>
                        </td>
                        <td class="text-center">
                          <v-menu v-if="items[6].childs[index].needsRepair"
                                  v-model="repairDateMenus['6_' + index]"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px">
                            <template v-slot:activator="{ on }">
                              <a href="#" v-on="on">{{items[6].childs[index].repairedDATE}}</a>
                            </template>
                            <v-date-picker
                              @change="() => { items[6].childs[index].repairedDATE = $root.formatDate(repairDates['6_' + index]); }"
                              v-model="repairDates['6_' + index]"
                              no-title
                              @input="repairDateMenus['6_' + index] = false"></v-date-picker>
                          </v-menu>
                        </td>
                        <td class="pl-4">
                          {{$root.alphabet[index]}}.&nbsp;{{child.name}}
                        </td>
                      </tr>
                    </template>
                    <tr>
                      <td colspan="3"></td>
                      <td class="inspection-group-label">8.&nbsp;{{items[7].name}}</td><!--/////////////////////////////////////////////////////////////////////////////////////////////////-->
                    </tr>
                    <template v-for="(child, index) in items[7].childs">
                      <tr :key="child.id">
                        <td class="text-center">
                          <v-checkbox v-if="!isReadOnly" dense hide-details class="ma-0 pa-0" @change="isOkChanged(7, index)" v-model="items[7].childs[index].isOkByDefault"/>
                          <small v-else-if="!items[7].childs[index].isOkByDefault && !items[7].childs[index].needsRepair">N/A</small>
                          <template v-else-if="items[7].childs[index].isOkByDefault">&check;</template>
                        </td>
                        <td class="needs-repair-label text-center">
                          <v-checkbox v-if="!isReadOnly" dense hide-details class="ma-0 pa-0" @change="isNeedsRepairChanged(7, index)" v-model="items[7].childs[index].needsRepair"/>
                          <template v-else-if="items[7].childs[index].needsRepair">&check;</template>
                        </td>
                        <td class="text-center">
                          <v-menu v-if="items[7].childs[index].needsRepair"
                                  v-model="repairDateMenus['7_' + index]"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px">
                            <template v-slot:activator="{ on }">
                              <a href="#" v-on="on">{{items[7].childs[index].repairedDATE}}</a>
                            </template>
                            <v-date-picker
                              @change="() => { items[7].childs[index].repairedDATE = $root.formatDate(repairDates['7_' + index]); }"
                              v-model="repairDates['7_' + index]"
                              no-title
                              @input="repairDateMenus['7_' + index] = false"></v-date-picker>
                          </v-menu>
                        </td>
                        <td class="pl-4">
                          {{$root.alphabet[index]}}.&nbsp;{{child.name}}
                        </td>
                      </tr>
                    </template>
                  </table>
                </td>
                <td style="width: 33%; vertical-align: top; border-right: 1px solid black; padding: 0px !important;">
                  <table class="table-annual-inspection">
                    <tr>
                      <th class="ok-label">OK</th>
                      <th class="needs-repair-label">NEEDS REPAIR</th>
                      <th class="repair-date-label">REPAIRED DATE</th>
                      <th class="item-label">ITEM</th>
                    </tr>
                    <tr>
                      <td colspan="3"></td>
                      <td class="inspection-group-label">9.&nbsp;{{items[8].name}}</td><!--/////////////////////////////////////////////////////////////////////////////////////////////////-->
                    </tr>
                    <template v-for="(child, index) in items[8].childs">
                      <tr :key="child.id">
                        <td class="text-center">
                          <v-checkbox v-if="!isReadOnly" dense hide-details class="ma-0 pa-0" @change="isOkChanged(8, index)" v-model="items[8].childs[index].isOkByDefault"/>
                          <small v-else-if="!items[8].childs[index].isOkByDefault && !items[8].childs[index].needsRepair">N/A</small>
                          <template v-else-if="items[8].childs[index].isOkByDefault">&check;</template>
                        </td>
                        <td class="needs-repair-label text-center">
                          <v-checkbox v-if="!isReadOnly" dense hide-details class="ma-0 pa-0" @change="isNeedsRepairChanged(8, index)" v-model="items[8].childs[index].needsRepair"/>
                          <template v-else-if="items[8].childs[index].needsRepair">&check;</template>
                        </td>
                        <td class="text-center">
                          <v-menu v-if="items[8].childs[index].needsRepair"
                                  v-model="repairDateMenus['8_' + index]"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px">
                            <template v-slot:activator="{ on }">
                              <a href="#" v-on="on">{{items[8].childs[index].repairedDATE}}</a>
                            </template>
                            <v-date-picker
                              @change="() => { items[8].childs[index].repairedDATE = $root.formatDate(repairDates['8_' + index]); }"
                              v-model="repairDates['8_' + index]"
                              no-title
                              @input="repairDateMenus['8_' + index] = false"></v-date-picker>
                          </v-menu>
                        </td>
                        <td class="pl-4">
                          {{$root.alphabet[index]}}.&nbsp;{{child.name}}
                        </td>
                      </tr>
                    </template>
                    <tr>
                      <td colspan="3"></td>
                      <td class="inspection-group-label">10.&nbsp;{{items[9].name}}</td><!--/////////////////////////////////////////////////////////////////////////////////////////////////-->
                    </tr>
                    <template v-for="(child, index) in items[9].childs">
                      <tr :key="child.id">
                        <td class="text-center">
                          <v-checkbox v-if="!isReadOnly" dense hide-details class="ma-0 pa-0" @change="isOkChanged(9, index)" v-model="items[9].childs[index].isOkByDefault"/>
                          <small v-else-if="!items[9].childs[index].isOkByDefault && !items[9].childs[index].needsRepair">N/A</small>
                          <template v-else-if="items[9].childs[index].isOkByDefault">&check;</template>
                        </td>
                        <td class="needs-repair-label text-center">
                          <v-checkbox v-if="!isReadOnly" dense hide-details class="ma-0 pa-0" @change="isNeedsRepairChanged(9, index)" v-model="items[9].childs[index].needsRepair"/>
                          <template v-else-if="items[9].childs[index].needsRepair">&check;</template>
                        </td>
                        <td class="text-center">
                          <v-menu v-if="items[9].childs[index].needsRepair"
                                  v-model="repairDateMenus['9_' + index]"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px">
                            <template v-slot:activator="{ on }">
                              <a href="#" v-on="on">{{items[9].childs[index].repairedDATE}}</a>
                            </template>
                            <v-date-picker
                              @change="() => { items[9].childs[index].repairedDATE = $root.formatDate(repairDates['9_' + index]); }"
                              v-model="repairDates['9_' + index]"
                              no-title
                              @input="repairDateMenus['9_' + index] = false"></v-date-picker>
                          </v-menu>
                        </td>
                        <td class="pl-4">
                          {{$root.alphabet[index]}}.&nbsp;{{child.name}}
                        </td>
                      </tr>
                    </template>
                    <tr>
                      <td colspan="3"></td>
                      <td class="inspection-group-label">11.&nbsp;{{items[10].name}}</td><!--/////////////////////////////////////////////////////////////////////////////////////////////////-->
                    </tr>
                    <template v-for="(child, index) in items[10].childs">
                      <tr :key="child.id">
                        <td class="text-center">
                          <v-checkbox v-if="!isReadOnly" dense hide-details class="ma-0 pa-0" @change="isOkChanged(10, index)" v-model="items[10].childs[index].isOkByDefault"/>
                          <small v-else-if="!items[10].childs[index].isOkByDefault && !items[10].childs[index].needsRepair">N/A</small>
                          <template v-else-if="items[10].childs[index].isOkByDefault">&check;</template>
                        </td>
                        <td class="needs-repair-label text-center">
                          <v-checkbox v-if="!isReadOnly" dense hide-details class="ma-0 pa-0" @change="isNeedsRepairChanged(10, index)" v-model="items[10].childs[index].needsRepair"/>
                          <template v-else-if="items[10].childs[index].needsRepair">&check;</template>
                        </td>
                        <td class="text-center">
                          <v-menu v-if="items[10].childs[index].needsRepair"
                                  v-model="repairDateMenus['10_' + index]"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px">
                            <template v-slot:activator="{ on }">
                              <a href="#" v-on="on">{{items[10].childs[index].repairedDATE}}</a>
                            </template>
                            <v-date-picker
                              @change="() => { items[10].childs[index].repairedDATE = $root.formatDate(repairDates['10_' + index]); }"
                              v-model="repairDates['10_' + index]"
                              no-title
                              @input="repairDateMenus['10_' + index] = false"></v-date-picker>
                          </v-menu>
                        </td>
                        <td class="pl-4">
                          {{$root.alphabet[index]}}.&nbsp;{{child.name}}
                        </td>
                      </tr>
                    </template>
                    <tr>
                      <td colspan="3"></td>
                      <td class="inspection-group-label">12.&nbsp;{{items[11].name}}</td><!--/////////////////////////////////////////////////////////////////////////////////////////////////-->
                    </tr>
                    <template v-for="(child, index) in items[11].childs">
                      <tr :key="child.id">
                        <td class="text-center">
                          <v-checkbox v-if="!isReadOnly" dense hide-details class="ma-0 pa-0" @change="isOkChanged(11, index)" v-model="items[11].childs[index].isOkByDefault"/>
                          <small v-else-if="!items[11].childs[index].isOkByDefault && !items[11].childs[index].needsRepair">N/A</small>
                          <template v-else-if="items[11].childs[index].isOkByDefault">&check;</template>
                        </td>
                        <td class="needs-repair-label text-center">
                          <v-checkbox v-if="!isReadOnly" dense hide-details class="ma-0 pa-0" @change="isNeedsRepairChanged(11, index)" v-model="items[11].childs[index].needsRepair"/>
                          <template v-else-if="items[11].childs[index].needsRepair">&check;</template>
                        </td>
                        <td class="text-center">
                          <v-menu v-if="items[11].childs[index].needsRepair"
                                  v-model="repairDateMenus['11_' + index]"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px">
                            <template v-slot:activator="{ on }">
                              <a href="#" v-on="on">{{items[11].childs[index].repairedDATE}}</a>
                            </template>
                            <v-date-picker
                              @change="() => { items[11].childs[index].repairedDATE = $root.formatDate(repairDates['11_' + index]); }"
                              v-model="repairDates['11_' + index]"
                              no-title
                              @input="repairDateMenus['11_' + index] = false"></v-date-picker>
                          </v-menu>
                        </td>
                        <td class="pl-4">
                          {{$root.alphabet[index]}}.&nbsp;{{child.name}}
                        </td>
                      </tr>
                    </template>
                    <tr>
                      <td colspan="3"></td>
                      <td class="inspection-group-label">13.&nbsp;{{items[12].name}}</td><!--/////////////////////////////////////////////////////////////////////////////////////////////////-->
                    </tr>
                    <template v-for="(child, index) in items[12].childs">
                      <tr :key="child.id">
                        <td class="text-center">
                          <v-checkbox v-if="!isReadOnly" dense hide-details class="ma-0 pa-0" @change="isOkChanged(12, index)" v-model="items[12].childs[index].isOkByDefault"/>
                          <small v-else-if="!items[12].childs[index].isOkByDefault && !items[12].childs[index].needsRepair">N/A</small>
                          <template v-else-if="items[12].childs[index].isOkByDefault">&check;</template>
                        </td>
                        <td class="needs-repair-label text-center">
                          <v-checkbox v-if="!isReadOnly" dense hide-details class="ma-0 pa-0" @change="isNeedsRepairChanged(12, index)" v-model="items[12].childs[index].needsRepair"/>
                          <template v-else-if="items[12].childs[index].needsRepair">&check;</template>
                        </td>
                        <td class="text-center">
                          <v-menu v-if="items[12].childs[index].needsRepair"
                                  v-model="repairDateMenus['12_' + index]"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px">
                            <template v-slot:activator="{ on }">
                              <a href="#" v-on="on">{{items[12].childs[index].repairedDATE}}</a>
                            </template>
                            <v-date-picker
                              @change="() => { items[12].childs[index].repairedDATE = $root.formatDate(repairDates['12_' + index]); }"
                              v-model="repairDates['12_' + index]"
                              no-title
                              @input="repairDateMenus['12_' + index] = false"></v-date-picker>
                          </v-menu>
                        </td>
                        <td class="pl-4">
                          {{$root.alphabet[index]}}.&nbsp;{{child.name}}
                        </td>
                      </tr>
                    </template>
                    <tr>
                      <td colspan="3"></td>
                      <td class="inspection-group-label">14.&nbsp;{{items[13].name}}</td><!--/////////////////////////////////////////////////////////////////////////////////////////////////-->
                    </tr>
                    <template v-for="(child, index) in items[13].childs">
                      <tr :key="child.id">
                        <td class="text-center">
                          <v-checkbox v-if="!isReadOnly" dense hide-details class="ma-0 pa-0" @change="isOkChanged(13, index)" v-model="items[13].childs[index].isOkByDefault"/>
                          <small v-else-if="!items[13].childs[index].isOkByDefault && !items[13].childs[index].needsRepair">N/A</small>
                          <template v-else-if="items[13].childs[index].isOkByDefault">&check;</template>
                        </td>
                        <td class="needs-repair-label text-center">
                          <v-checkbox v-if="!isReadOnly" dense hide-details class="ma-0 pa-0" @change="isNeedsRepairChanged(13, index)" v-model="items[13].childs[index].needsRepair"/>
                          <template v-else-if="items[13].childs[index].needsRepair">&check;</template>
                        </td>
                        <td class="text-center">
                          <v-menu v-if="items[13].childs[index].needsRepair"
                                  v-model="repairDateMenus['12_' + index]"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px">
                            <template v-slot:activator="{ on }">
                              <a href="#" v-on="on">{{items[13].childs[index].repairedDATE}}</a>
                            </template>
                            <v-date-picker
                              @change="() => { items[13].childs[index].repairedDATE = $root.formatDate(repairDates['13_' + index]); }"
                              v-model="repairDates['13_' + index]"
                              no-title
                              @input="repairDateMenus['12_' + index] = false"></v-date-picker>
                          </v-menu>
                        </td>
                        <td class="pl-4">
                          {{$root.alphabet[index]}}.&nbsp;{{child.name}}
                        </td>
                      </tr>
                    </template>
                    <tr>
                      <td colspan="4">List any other condition which may prevent safe operation of this vehicle.</td>
                    </tr>
                    <tr>
                      <td><v-checkbox dense hide-details class="ma-0"/></td>
                      <td class="needs-repair-label"><v-checkbox dense hide-details class="ma-0"/></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><v-checkbox dense hide-details class="ma-0"/></td>
                      <td class="needs-repair-label"><v-checkbox dense hide-details class="ma-0"/></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <!--  <tr>
                      <td><v-checkbox dense hide-details class="ma-0"/></td>
                      <td class="needs-repair-label"><v-checkbox dense hide-details class="ma-0"/></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                       <td><v-checkbox dense hide-details class="ma-0"/></td>
                       <td class="needs-repair-label"><v-checkbox dense hide-details class="ma-0"/></td>
                       <td></td>
                       <td></td>
                     </tr>
                    <tr>
                       <td><v-checkbox dense hide-details class="ma-0"/></td>
                       <td class="needs-repair-label"><v-checkbox dense hide-details class="ma-0"/></td>
                       <td></td>
                       <td></td>
                     </tr>
                     <tr>
                       <td><v-checkbox dense hide-details class="ma-0"/></td>
                       <td class="needs-repair-label"><v-checkbox dense hide-details class="ma-0"/></td>
                       <td></td>
                       <td></td>
                     </tr>
                     <tr>
                       <td><v-checkbox dense hide-details class="ma-0"/></td>
                       <td class="needs-repair-label"><v-checkbox dense hide-details class="ma-0"/></td>
                       <td></td>
                       <td></td>
                     </tr>
                     <tr>
                       <td><v-checkbox dense hide-details class="ma-0"/></td>
                       <td class="needs-repair-label"><v-checkbox dense hide-details class="ma-0"/></td>
                       <td></td>
                       <td></td>
                     </tr>-->
                  </table>
                </td>
              </tr>
              <tr>
                <td colspan="12" style="border: 1px solid black; font-size: 12px; line-height: 14px;">
                  INSTRUCTIONS: MARK COLUMN ENTRIES TO VERIFY INSPECTION: _X_ OK, _X_ NEEDS REPAIR, _NA_ IF ITEMS DO NOT APPLY, REPAIRED DATE
                </td>
              </tr>
              <tr>
                <td colspan="12" style="font-size: 12px; line-height: 14px;">
                  CERTIFICATION: THIS VEHICLE HAS PASSED ALL THE INSPECTION ITEMS FOR THE ANNUAL VEHICLE INSPECTION REPORT IN ACCORDANCE WITH 49 CFR 396.
                </td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
        <v-card-actions v-if="!isReadOnly">
          <v-spacer/><v-btn class="primary" :loading="loadingCreate" @click="save(reportId)">{{reportId ? $t('Save') : $t('Create')}}</v-btn>
          <v-btn class="error" v-if="reportId" :loading="loadingCreate" @click="remove(reportId)">{{$t('Remove')}}</v-btn>
        </v-card-actions>
      </v-card>
      <!--<v-btn v-if="reportId > 0" @click="onRebuild">Rebuild</v-btn>-->
    </div>
  </div>
</template>

<script>
import trucksMixin from './../../mixins/trucksMixin';

export default {
  props: ['truckId', 'reportId', 'lastReportNumber', 'isReadOnly', 'companyId', 'fileUID', 'reportNumber', 'printed'],
  components: { },
  mixins: [ trucksMixin ],
  data () {
    return {
      loading: false,
      error: '',
      valid: false,
      publicPath: process.env.BASE_URL,
      logoPath: '',
      items: [],
      fields: {
        truckId: 0,
        miles: 0,
        reportDATE: '',
        reportNumber: '',
        fleetUnitNumber: '',
        motorCarrierOperator: '',
        inspectorsName: '',
        address: '',
        cityStateZip: '',
        isInspectorMeetsTheQualification: 0,
        vehicleIdentification: '',
        vehicleOther: '',
        inspectionAgencyLocation: '',
        isQuaterly: false,
        isAnnual: false
      },
      truckName: '',
      truckType: '',
      truckLicPlate: '',
      truckMiles: '',
      truckLastAnnualInspectionDATE: '',
      vehicleTypeTractor: false,
      vehicleTypeTrailer: false,
      vehicleTypeTruck: false,
      vehicleTypeOther: false,
      vehicleIndentificationLicPlate: true,
      vehicleIndentificationVin: false,
      vehicleIndentificationOther: false,
      answers: [],
      repairDateMenus: {},
      repairDates: {},
      loadingCreate: false,
      isTrailer: false,
      fetchKey: 'fetchKey'
    };
  },
  async mounted () {
    /*if (window.location.origin.indexOf('freighthyperion.com') >= 0) {
      this.fields.motorCarrierOperator = 'Hyperion UTS Inc.';
    }
    if (window.location.origin.indexOf('tgl.us.com') >= 0) {
      this.fields.motorCarrierOperator = 'Titan Group Logistics Inc.';
    }*/

    this.$root.getInvoiceLogoPath(this.companyId).then(path => {
      this.logoPath = path;
    });

    this.$root.getGlobalSettingsField('annualInspectionReportAddress').then((response) => {
      if (response.status === 'ok') {
        this.fields.address = response.result;
      }
    });

    this.$root.getGlobalSettingsField('annualInspectionReportCityStateZip').then((response) => {
      if (response.status === 'ok') {
        this.fields.cityStateZip = response.result;
      }
    });

    // Init fields
    if (!this.reportId) {
      this.fields.truckId = this.truckId;

      this.$root.getTruck(this.truckId).then(async (response) => {
        if (response.status === 'ok') {
          const truckData = response.result;

          this.truckName = truckData.name;
          this.truckType = parseInt(truckData.isTrailer) === 1 ? truckData.trailerType : truckData.type;
          this.isTrailer = parseInt(truckData.isTrailer) === 1;
          this.truckLicPlate = truckData.licensePlate;
          this.truckMiles = truckData.miles;
          this.truckLastAnnualInspectionDATE = truckData.lastAnnualInspectionDATE;

          if (truckData.owner !== '') {
            this.fields.motorCarrierOperator = truckData.owner;
          }
          this.fields.miles = this.truckMiles;
          this.fields.vehicleIdentification = this.truckLicPlate;
          this.fields.fleetUnitNumber = this.truckName.trim().indexOf(' ') > 0 ? this.truckName.split(' ')[0] : this.truckName;
          if (this.truckType.toLowerCase().indexOf('tractor') >= 0) {
            this.vehicleTypeTractor = true;
            this.vehicleTypeTrailer = false;
            this.vehicleTypeTruck = false;
            this.vehicleTypeOther = false;
          } else if (this.truckType.toLowerCase().indexOf('trailer') >= 0) {
            this.vehicleTypeTrailer = true;
            this.vehicleTypeTractor = false;
            this.vehicleTypeTruck = false;
            this.vehicleTypeOther = false;
          } else if (this.truckType.toLowerCase().indexOf('truck') >= 0) {
            this.vehicleTypeTruck = true;
            this.vehicleTypeTractor = false;
            this.vehicleTypeTrailer = false;
            this.vehicleTypeOther = false;
          } else {
            this.vehicleTypeOther = true;
            this.vehicleTypeTractor = false;
            this.vehicleTypeTrailer = false;
            this.vehicleTypeTruck = false;

          }
          this.fields.vehicleOther = this.truckType;
          this.fields.reportDATE = this.$moment.moment().utcOffset(this.$store.getters.timezoneOffsetMinutes).format('MM/DD/YYYY');
          this.fields.reportNumber = this.$moment.moment().utcOffset(this.$store.getters.timezoneOffsetMinutes).format('MMDDYY') + '-' + this.fields.fleetUnitNumber;
        }
      });
    }
    await this.fetchData();
  },
  watch: {
  },
  beforeDestroy () {
  },
  methods: {
    onRebuild() {
      this.saveAnnualInspectionReportAnswers(this.reportId, { answers: this.answers }).then(response2 => {
        this.$emit('save-as-pdf', this.reportId, this.truckId, this.truckName, this.fields.reportDATE);
      });
    },
    vehicleIndentificationChanged (varName) {
      const arr = ['vehicleIndentificationLicPlate', 'vehicleIndentificationVin', 'vehicleIndentificationOther'];
      arr.forEach(el => {
        this.$set(this, el, el === varName);
      });
    },
    onVehicleTypeChanged (varName) {
      const arr = ['vehicleTypeTractor', 'vehicleTypeTrailer', 'vehicleTypeTruck', 'vehicleTypeOther'];
      arr.forEach(el => {
        this.$set(this, el, el === varName);
      });
    },
    reportDATEChanged (value) {
      const d1 = this.$moment.moment(this.truckLastAnnualInspectionDATE);
      const d2 = this.$moment.moment(value);
      const daysInterval = parseInt(this.$store.getters.globalSettingsFields.annualInspectionIntervalInDays);
      const days = d2.diff(d1, 'days');
      if (days < 0 || (days > 0 && days > daysInterval)) {
        this.$root.toast(this.$i18n.t('Trucks.DateOutsideIntervalRangeFromLastAnnualInspection'), { color: 'warning' });
        //this.fields.reportDATE = d1.add(daysInterval, 'd').format('MM/DD/YYYY');
      }
    },
    isOkChanged (itemIndex, childIndex) {
      if (this.items[itemIndex].childs[childIndex].isOkByDefault) {
        this.$set(this.items[itemIndex].childs[childIndex], 'needsRepair', false);
      }
      this.$set(this, 'fetchKey', new Date().getTime());
    },
    isNeedsRepairChanged (itemIndex, childIndex) {
      if (this.items[itemIndex].childs[childIndex].needsRepair) {
        this.$set(this.items[itemIndex].childs[childIndex], 'isOkByDefault', false);
        this.$set(this.repairDateMenus, itemIndex + '_' + childIndex, false);
        this.$set(this.repairDates, itemIndex + '_' + childIndex, this.$moment.moment().utcOffset(this.$store.getters.timezoneOffsetMinutes).format('YYYY-MM-DD'));
        this.$set(this.items[itemIndex].childs[childIndex], 'repairedDATE', this.$root.formatDate(this.repairDates[itemIndex + '_' + childIndex]));
      }
      this.$set(this, 'fetchKey', new Date().getTime());
    },
    remove (id) {
      this.$dialog.confirm(this.$i18n.t('PleaseConfirmYourChoice')).then(dialog => {
        this.removeAnnualInspectionReport(id).then(response => {
          if (response.status === 'ok') {
            this.$emit('close');
          }
        });
      });
    },
    save (id = 0) {
      let _fields = {
        truckId: this.fields.truckId,
        miles: this.fields.miles,
        reportDATE: this.fields.reportDATE,
        reportNumber: this.fields.reportNumber,
        fleetUnitNumber: this.fields.fleetUnitNumber,
        motorCarrierOperator: this.fields.motorCarrierOperator,
        inspectorsName: this.fields.inspectorsName,
        address: this.fields.address,
        cityStateZip: this.fields.cityStateZip,
        isInspectorMeetsTheQualification: this.fields.isInspectorMeetsTheQualification ? 1 : 0,
        inspectionAgencyLocation: this.fields.inspectionAgencyLocation,
        vehicleOther: this.fields.vehicleOther,
        vehicleIdentification: this.fields.vehicleIdentification,
        isQuaterly: this.fields.isQuaterly ? 1 : 0,
        isAnnual: this.fields.isAnnual ? 1 : 0
      };
      this.loadingCreate = true;
      if (this.vehicleIndentificationLicPlate) {
        _fields['vehicleIdentificationType'] = 'lic plate';
      }
      if (this.vehicleIndentificationVin) {
        _fields['vehicleIdentificationType'] = 'vin';
      }
      if (this.vehicleIndentificationOther) {
        _fields['vehicleIdentificationType'] = 'other';
      }

      if (this.vehicleTypeTractor) {
        _fields['vehicleType'] = 'tractor';
      }
      if (this.vehicleTypeTrailer) {
        _fields['vehicleType'] = 'trailer';
      }
      if (this.vehicleTypeTruck) {
        _fields['vehicleType'] = 'truck';
      }
      if (this.vehicleTypeOther) {
        _fields['vehicleType'] = 'other';
      }

      this.saveAnnualInspectionReport(id, { fields: _fields, reportDATE: this.$root.parseDate(this.fields.reportDATE) }).then(response => {
        if (response.status === 'ok') {
          let reportId = response.id;
          this.answers = [];
          for (let i = 0; i < this.items.length; i++) {
            for (let j = 0; j < this.items[i].childs.length; j++) {
              this.answers.push({
                reportId: reportId,
                itemId: this.items[i].childs[j].id,
                repairedDATE: this.items[i].childs[j].repairedDATE,
                isOk: this.items[i].childs[j].isOkByDefault ? 1 : 0,
                needsRepair: this.items[i].childs[j].needsRepair ? 1 : 0,
                notes: ''
              });
            }
          }
          this.saveAnnualInspectionReportAnswers(reportId, { answers: this.answers }).then(response2 => {
            this.$emit('save-as-pdf', reportId, this.truckId, this.truckName, this.fields.reportDATE);
          });
        }
      });
    },
    async fetchData (showLoading = true) {
      let i = 0;
      let response = {};
      if (showLoading) {
        this.loading = true;
      }
      if (this.reportId > 0) {
        response = await this.getAnnualInspectionsReport(this.reportId);
        if (response.status === 'ok') {
          const _fields = Object.keys(this.fields);
          for (i = 0; i < _fields.length; i++) {
            const key = _fields[i];
            this.$set(this.fields, key, response.results[key]);
          }

          this.vehicleIndentificationLicPlate = response.results['vehicleIdentificationType'] === 'lic plate';
          this.vehicleIndentificationVin = response.results['vehicleIdentificationType'] === 'vin';
          this.vehicleIndentificationOther = response.results['vehicleIdentificationType'] === 'other';

          this.vehicleTypeTractor = response.results['vehicleType'] === 'tractor';
          this.vehicleTypeTrailer = response.results['vehicleType'] === 'trailer';
          this.vehicleTypeTruck = response.results['vehicleType'] === 'truck';
          this.vehicleTypeOther = response.results['vehicleType'] === 'other';
        }
      }

      response = await this.getAnnualInspectionReportItems(this.reportId || 0);
      if (response.status === 'ok') {
        this.items = response.results.filter((e) => parseInt(e.parent) === 0);

        if (this.isTrailer) {
          this.items.forEach((el) => { el.isOkByDefault = el.isOkByDefaultForTrailers; });
        }

        for (i = 0; i < this.items.length; i++) {
          this.items[i].childs = response.results.filter((e) => parseInt(e.parent) === this.items[i].id);

          if (this.isTrailer) {
            this.items[i].childs.forEach((el) => { el.isOkByDefault = el.isOkByDefaultForTrailers; });
          }
        }
      }
      this.loading = false;
      this.$emit('on-loaded');
    }
  }
};
</script>
