import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);
// Ready translated locale messages
var messages = {
  'en-US': {
    copiedToClipboard: 'Copied to clipboard',
    All: 'All',
    ToAll: 'To All',
    in: 'in',
    or: 'or',
    and: 'and',
    Continue: 'Continue',
    ContinueToNextLocation: 'To Next Location',
    More: 'More',
    moveUp: 'Move Up',
    moveDown: 'Move Down',
    inchShort: 'in.',
    feetShort: 'ft.',
    poundShort: 'lb.',
    milesShort: 'mi.',
    lbsShort: 'lbs',
    pieces: 'pieces',
    daysShort: 'd.',
    miles: 'miles',
    days: 'days',
    hoursShort: 'hr.',
    minutesShort: 'min',
    gallonsShort: 'gal.',
    mphShort: 'mph',
    from: 'from',
    timeSpent: 'Time spent',
    fromEmail: 'from',
    toEmail: 'to',
    ccEmail: 'CC',
    to: 'to',
    Yes: 'Yes',
    No: 'No',
    Add: 'Add',
    hide: 'Hide',
    unhide: 'UnHide',
    Total: 'Total',
    Search: 'Search',
    SearchString: 'Enter search text or number here...',
    Select: 'Select',
    Refresh: 'Refresh',
    Cancel: 'Cancel',
    Canceled: 'Canceled',
    Close: 'Close',
    Next: 'Next',
    Back: 'Back',
    BackToList: 'To List',
    BackDriversToList: 'To Drivers List',
    Version: 'Version',
    Invoice: 'Invoice',
    Delete: 'Delete',
    Deleted: 'Deleted',
    DeleteConfirmation: 'Do you want to delete?',
    RestoreConfirmation: 'Do you want to restore?',
    StatusUpdateConfirmation: 'Do you want to change status?',
    OpenNewPageTab: 'Open new tab',
    OpenInNewTab: 'Open in new tab',
    Save: 'Save',
    SaveToPDF: 'Save to PDF',
    SaveToPDFConfirmationMsg: 'Convert image to PDF can take up to 30 sec and depends on size of actual image. The new file will be placed near the original. Continue?',
    Create: 'Create',
    Creating: 'Creating',
    UserLabel: 'User',
    New: 'New',
    Open: 'Open',
    Edit: 'Edit',
    View: 'View',
    Print: 'Print',
    ViewAs: 'View as user',
    AllUsers: 'All Users',
    Activate: 'Activate',
    PhoneCall: 'Phone call',
    SendEmail: 'Send Email',
    SendAll: 'Send All',
    SendReply: 'Send Reply',
    BackToOwnUser: 'Back to own user',
    ViewAsMode: 'View mode as',
    Deactivate: 'Deactivate',
    Deactivated: 'Deactivated',
    Send: 'Send',
    Call: 'Call',
    Download: 'Download',
    DownloadWithAllDocuments: 'Download With Documents',
    SendViaEmail: 'Send Via Email',
    Filters: 'Filters',
    Remove: 'Remove',
    Settings: 'Settings',
    SelectUser: 'Select user',
    SaveWithUsers: 'Save for role and users',
    ResetToRole: 'Reset user rights',
    HideDeactivated: 'Hide deactivated',
    ShowDeactivated: 'Show deactivated',
    FormValidationFailed: 'Some fields not filed right',
    CreateNewUserFormHeader: 'Create new user',
    CreateNewUserWithRole: 'Create new {role}',
    AccessRightsHeader: 'Access Rights Setup',
    NewUserAdded: 'New user added',
    UserAlreadyExists: 'User already exists',
    UserSettingsUpdated: 'Settings updated',
    FieldUpdated: 'Field updated.',
    ChangesAreSaved: 'Changes are saved.',
    Decrypt: 'Decrypt',
    PasswordFieldIsEmpty: 'Password field is empty',
    DecryptionModalTitle: 'Enter your password to decrypt data',
    FieldDecrypted: 'Field decrypted',
    FieldDecryptinError: 'Field decryptin error',
    ChangesAreSavedWithAccessErrors: 'Changes are saved but you have some access errors.',
    PersonalDataAndDocumentsHeader: 'Personal data and documents',
    AccessError: 'Access denied',
    FieldAccessError: 'Fields access denied',
    FileUploaded: 'File uploaded.',
    AvatarUploaded: 'Avatar uploaded.',
    PasswordUpdated: 'Password updated.',
    LoginForm: 'Sign in to your account',
    Login: 'Enter',
    Logout: 'Sign out',
    AuthenticationFailed: 'Authentication failed',
    AccessRightsChanged: 'Access rights are changed',
    AccessRightsChangeError: 'Error when changing access rights',
    OfflineModeMsg: 'You are offline, all editing are blocked.',
    UnknownAddress: 'Unknown address',
    PaymentType: 'Payment type',
    CorporateCard: 'Corporate card',
    PersonalCard: 'Personal card',
    Cash: 'Cash',
    CompensateType: 'Compensate type',
    Compensate: 'Compensate',
    AddToSalary: 'Add to salary',
    Fine: 'Fine',
    Expenses: 'Expenses',
    OpenExternal: 'Open in new tab',
    LinkedAccounts: 'Linked Accounts',
    LinkGGLAccount: 'Link Account',
    LinkedAccountRemoved: 'Linked Account unlinked',
    GGLAccountLinked: 'Linked',
    GGLAccountNotLinked: 'Not linked',
    LinkGGLAccountDesc: 'Link Google account? After consent, you will be redirected to the Google authentication page. On it you need to log in to your account and give premissions.',
    RemoveGGLAccountDesc: 'Unlink linked account {email}',
    EmailWasSent: 'Email is sent.',
    EmailsWasSent: 'Emails are sent.',
    AttachFilesFromLoad: 'Attach files from load',
    SelctedEmailAttFromTheLoad: 'Select files from the load',
    SendEmailWithAttTitle: 'Send Email with Attachments',
    BatchSendEmailWithAttTitle: 'Batch Email Send with Attachments',
    DeletedFilter: 'Deleted Filter',
    GetLocationError: 'Get Location Error',
    OldVersionMessage: 'Please refresh the page. New version of the app is available.',
    ShowFullUI: 'Show full user interface',
    Notes: 'Notes',
    SaveNotes: 'Save Notes',
    SaveNotesConfirmationMsg: 'Save Notes?',
    SaveDriverRatesConfirmationMsg: 'Save rates?',
    ErrorCantCalcTheDistance: 'Error: Can`t calculate the distance or duration',
    Clone: 'Clone',
    Bid: 'Bid',
    Load: 'Load',
    OpenLoad: 'Open load',
    GoToLoad: 'Go to load',
    GoToShift: 'Go to shift',
    GoToInvoice: 'Go to invoice',
    Locations: 'Locations',
    StartAt: 'Start at',
    At: 'at',
    Update: 'Update',
    Pending: 'Pending',
    PleaseConfirmYourChoice: 'Please confirm your choice.',
    Waiting: 'Waiting',
    ShowCalendar: 'Show full Calendar',
    by: 'by',
    DispatcherAwaySwitch: 'When dispatcher is away driver can continue the load without approval.',
    IsAway: 'Away',
    IsActive: 'Active',
    IsOffline: 'Offline',
    ShowDynamicMap: 'Show Map',
    HideDynamicMap: 'Hide Map',
    ReUploadFile: 'Reupload file',
    Uploading: 'Uploading',
    RetryIn: 'Retry In',
    Empty: 'Empty',
    Truck: 'Truck',
    Trailer: 'Trailer',
    Driver: 'Driver',
    CoDriver: 'Co-Driver',
    Created: 'Created',
    Updated: 'Updated',
    CreatedBy: 'Created by',
    UpdatedBy: 'Updated by',
    History: 'History',
    GoToCalendarMonth: 'To month',
    GoToCalendarWeek: 'To week',
    Expected: 'Expected',
    ExpectedShort: 'Exp',
    Received: 'Received',
    ReceivedShort: 'Recv',
    Apply: 'Apply',
    DirectionsServiceError: 'Directions Service error, try again in a few seconds.',
    Loading: 'Loading',
    SelectHQLocation: 'Select Parking Location',
    Location: 'Location',
    OnLocation: 'On location',
    TypeToSearch: 'Type To Search',
    SelectColumns: 'Select Columns',
    Optional: '(optional)',
    Today: 'Today',
    ByMaps: 'By Maps',
    ByOdometer: 'By Odometer',
    Report: 'Report',
    DOTReport: 'DOT Report',
    SetAsCurrent: 'Set as current: {n}',
    scheduledFor: 'Scheduled for',
    for: 'for',
    scheduled: 'Scheduled',
    CreateNewBroker: 'Create New Broker',
    Saved: 'Saved',
    Register: 'Register',
    RegistrationSuccess: 'Registration Successfull',
    RegistrationFailed: 'Registration Failed',
    RegistrationSuccessMsg: 'You can sing in, after your registration will be validated.',
    NewRegistration: 'New Registration',
    Clear: 'Clear',
    Expires: 'Expires',
    ExpiresIn: 'Exp. In',
    AddComment: 'Add Comment',
    Comments: 'Comments',
    Comment: 'Comment',
    Submit: 'Submit',
    OverrideField: 'Field Overwriting',
    OverrideFieldAmount: 'Amount',
    OverrideFieldTime: 'Time',
    Expense: 'Expense',
    Bonus: 'Bonus',
    Deduction: 'Deduction',
    Shift: 'Shift',
    FilterType: 'Filter Type',
    FilterTruck: 'Filter Truck',
    FilterDriver: 'Filter Driver',
    ClearSelection: 'Clear Selection',
    RemoveAllFilters: 'Remove All Filters',
    Notifications: {
      ListTitle: 'Notifications',
      Created: 'Succesfully Sent',
      SettingsTitle: 'Notifications',
      ShowNotifications: 'Show Notifications',
      SendTo: 'Send to',
      SendAll: 'To All',
      SendByRole: 'To Role',
      SendByUsers: 'To Users',
      SelectUsers: 'Select users',
      Title: 'Title',
      Body: 'Body',
      MarkAsRead: 'Mark as Read',
      MarkAllAsRead: 'Read all',
      ListUsers: 'Users',
      StatusFilter: 'Status',
      My: 'My',
      CreateNewTitle: 'Create New Notification',
      types: {
        LOAD_CREATED: 'Load created',
        LOAD_APPROVED_TO_START: 'Load approved to start',
        LOAD_LOCATION_APPROVED: 'Load stage approved',
        LOAD_STARTED: 'Load started',
        LOAD_UNFINISHED: 'Load marked as not finished',
        LOAD_FINISHED: 'Load marked as finished',
        LOAD_FINISHED_WITH_TONU: 'Load marked as finished with TONU',
        LOAD_LOCATION_STARTED: 'Load stage is started',
        LOAD_LOCATION_ON_SITE: 'Load stage on site',
        LOAD_LOCATION_DONE: 'Load stage is done',
        LOAD_EXPENSES_ADDED: 'Load Expences updated',
        LOAD_PHOTOS_UPDATED: 'Load Photos updated',
        LOAD_RATE_CON_UPDATED: 'Load RateCon updated',
        LOAD_PUBLIC_RATE_CON_UPDATED: 'Load RateCon updated',
        LOAD_DO_UPDATED: 'Load DO updated',
        LOAD_BOL_UPDATED: 'Load BOL updated',
        LOAD_POD_UPDATED: 'Load POD updated',
        INVOICE_CREATED: 'Invoice created',
        INVOICE_UPDATED: 'Invoice updated',
        INVOICE_NOTE_UPDATED: 'Invoice note updated',
        INVOICE_DOCUMENTS_UPDATED: 'Invoice documents updated',
        INVOICE_STATUS_CHANGED: 'Invoice status changed',
        BID_CREATED: 'Bid created',
        BID_CREATED_AND_PENDING: 'Bid created And Pending',
        BID_ANSWERED_BY_EMAIL: 'Response to the BID letter',
        EMAIL_ARRIVED: 'Email arrived',
        CHAT_NEW_PERSONAL_MESSAGE: 'New chat personal messages',
        CHAT_NEW_GROUP_MESSAGE: 'New chat group messages',
        CHAT_NEW_CONTEXT_MESSAGE: 'New chat messages by load, truck, incoice, etd',
        TASKS_NEW_CREATED_MESSAGE: 'New tasks created',
        TASKS_UPDATED_MESSAGE: 'Tasks updated',
        TASKS_NEW_COMMENT_MESSAGE: 'New Tasks comments'
      }
    },
    Tasks: {
      GoToTask: 'Go To Task',
      Linked: 'Linked To',
      DaysLeft: 'Days Left',
      UpdateComment: 'Update Comment',
      CancelEditing: 'Cancel Editing',
      Comments: 'Comments',
      NewComment: 'New Comment',
      PostComment: 'Post Comment',
      ListTitle: 'Tasks list',
      Task: 'Task',
      Edit: 'Edit',
      ChangeStatus: 'Change Status',
      StatusFilter: 'Status',
      CreatorFilter: 'Creator',
      UsersFilter: 'Assigned User',
      PriorityFilter: 'Priority',
      TaskFor: 'Task For',
      EditTask: 'Edit Task',
      CreateTask: 'Create Task',
      Creator: 'Creator',
      Created: 'Created',
      Updated: 'Last Updated',
      Status: 'Status',
      Priority: 'Priority',
      Name: 'Subject',
      AssignedUsers: 'Assigned Users',
      Description: 'Description',
      isGlobal: 'For All Users',
      isPinned: 'Pinned',
      isDueDateTime: 'Due Date?',
      DueDate: 'Due Date',
      isAutoCloseDateTime: 'Auto Close Date',
      AutoCloseDate: 'Auto Close Date',
      attUPLOAD: 'Attachment',
      CreateNewConfirmationMsg: 'Create New Task?',
      SaveConfirmationMsg: 'Save Task?',
      RemoveCommentConfirmationMsg: 'Remove Comment?',
      NewTaskAdded: 'New task is added',
      ErrorWhenAddingTask: 'Error when adding a new task',
      TaskDataSaved: 'Task is saved',
      TaskDataSaveError: 'Error when saving a task'
    },
    Mails: {
      TypeAll: 'All',
      TypeNew: 'New',
      TypeUndead: 'Unread',
      TypeWithAnswers: 'Answered',
      ForwardingThread: 'Forwarding thread',
      ForwardedSuccessfully: 'Forwarded Successfully',
      ForwardedError: 'Forwarded Error',
      Forwarding: 'Forwarding',
      Forward: 'Forward',
      ForwardAll: 'Forward All',
      AddAttachments: 'Attach file',
      Attachments: 'Files',
      Event: 'Event',
      Reply: 'Reply',
      ReplyAll: 'Reply All',
      Quote: 'Toggle Quote',
      ListTitle: 'Mails list',
      Date: 'Date',
      Status: 'Status',
      From: 'From',
      To: 'To',
      Cc: 'Cc',
      Subject: 'Subject',
      Message: 'Text',
      groupMailsByThread: 'Group by thread',
      ShowQuoteTooltip: 'Show/Hide trimmed text',
      MarkAsReadTooltip: 'Mark as Read',
      MarkAsUnreadTooltip: 'Mark as Unread',
      showOnlySelfMails: 'Show only my',
      SendEmailConfirmationMsg: 'Do you want to send email from {email}?',
      ListUsers: 'User List',
      StatusFilter: 'Status',
      User: 'User',
      CreateNew: 'Create New'
    },
    KeepTruckin: {
      recap: 'Recap',
      date: 'Date',
      off_duty: 'Off Duty',
      on_duty: 'On Duty',
      sleeper: 'Sleeper',
      driving: 'Driving',
      waiting: 'Waiting',
      off_duty_short: 'Off',
      on_duty_short: 'On',
      sleeper_short: 'S',
      driving_short: 'D',
      waiting_short: 'W',
      cycle: 'Cycle',
      break: 'Break after',
      drive: 'Drive',
      shift: 'Shift',
      availableTime: 'Available time',
      drivingToday: 'Driving Today',
      drivingTomorrow: 'Driving Tomorrow',
      onDutyToday: 'On Duty Today',
      onDutyTomorrow: 'On Duty Tomorrow',
      timeAvailableToday: 'Time Available Today',
      timeAvailableTomorrow: 'Time Available Tomorrow'
    },
    KeepTruckinUserEmailNoFound: 'This user email not found in keeptruckin users',
    Error: 'Error',
    Errors: {
      AddingNewUserIsNotAllowed: 'Adding new user is not allowed.',
      AccessDeniedAuthorizationFailed: 'Access Denied. You have no right to view this page.',
      FileUploadErrorMsg: 'Error: File is not uploaded. File will be stored for reupload. Please try again later.',
      FormIsNotValid: 'Form Is Not Valid'
    },
    ApplyToAllGroup: 'Apply to all',
    AccessRightsGroup: {
      leftMenu: 'Left Menu',
      settings: 'User Settings Fields',
      accountingManager: 'Accounting Manager Fields',
      users: 'Users',
      broker: 'Broker Fields',
      dispatcher: 'Dispatcher Fields',
      driver: 'Driver Fields',
      driverOwnerOperator: 'Driver Owner Operator Fields',
      administrator: 'Administrator Fields',
      engineer: 'Engineer Fields',
      trucks: 'Trucks',
      bids: 'Bids',
      loads: 'Loads',
      invoices: 'Invoices',
      mails: 'Mails',
      notifications: 'Notifications',
      shifts: 'Trips',
      tasks: 'Tasks',
      loadsFeed: 'Loads Feed',
      documents: 'Documents',
      reports: 'Reports'
    },
    User: {
      Settings: {
        Update: 'Update',
        NewVersionAvailable: 'Press to update',
        DriverAppVersion: 'App Version',
        Reset: 'Reset',
        AccessRightForUser: 'Access rights for {firstName} {lastName}',
        AccessRightForRole: 'Access rights for all {role}`s',
        UpdateMsg: 'To update the app it recommended to have fast and consistent internet connection. Start an update?'
      },
      RateTypes: {
        TYPE_BY_MILES: 'By Miles',
        TYPE_FIXED: 'Fixed',
        TYPE_FIXED_PLUS_MILES: 'Fixed plus miles',
        TYPE_FIXED_PLUS_MILES_MORE_THAN: 'Fixed plus miles more than',
        TYPE_FIXED_OR_BY_MILES_MORE_THAN: 'Fixed or by miles more than'
      },
      UserDocumentsSaved: 'User Documents Saved',
      UserDocumentsSaveError: 'User Documents Save Error',
      RateType: 'Type',
      RateName: 'Name',
      SoloRate: 'Solo Rate',
      TeamRate: 'Team Rate',
      SoloRateFixed: 'Fixed Solo Rate',
      TeamRateFixed: 'Fixed Team Rate',
      RateMinMiles: 'Minimum Miles',
      RateNote: 'Note',
      FixedRate: 'Fixed',
      Title: 'User',
      ListTitle: 'User List',
      DeactivateConfirmation: 'Are you sure you want to deactivate user?',
      ActivateConfirmation: 'Are you sure you want to activate user?',
      UserInformation: 'Information',
      UserDocuments: 'Documents',
      UserLogs: 'Logs',
      UserLoads: 'Loads',
      UserReports: 'Reports',
      UserDocumentsAreEmpty: 'No documents',
      CopyRatesFromDriver: 'Copy from another driver',
      ApplyRatesToAllDriversSuccess: 'Current rates applied to all drivers',
      ApplyOneRateToAllDriversSuccess: 'Selected rate applied to all drivers',
      ApplyRatesToAllDrivers: 'Apply rates to all drivers',
      SetDefaultRates: 'Reset rates',
      ApplyOneRateToAllDrivers: 'Apply rate to all drivers',
      ApplyRatesToAllDriversConfirmation: 'Are you sure you want to apply current rates to all drivers?',
      ApplyOneRateToAllDriversConfirmation: 'Are you sure you want to apply selected rate to all drivers?',
      MergingBrokersConfirmation: 'When merging brokers, the broker to merge will be deactivated. If the broker have emails or managers which not exists in a target broker, these emails and managers will be added to the target broker. Also, the broker to merge will be replaced with the target broker in bids, loads, and invoices.',
      MergeBrokers: 'Merge brokers',
      SelectFromBroker: 'Select broker to merge',
      SelectToBroker: 'Select target broker',
      NoUser: 'No User',
      UserDeactivated: 'User {user} has been deactivated.',
      UserActivated: 'User {user} has been activated.',
      WorkHours: 'Work hours',
      Page: 'User page',
      MyPage: 'My page',
      Expired: 'Expired',
      DaysLeft: '{n} days left',
      MilesLeft: '{n} miles left',
      DaysOverdue: '{n} days overdue',
      MilesOverdue: '{n} miles overdue',
      OnWork: 'On Work',
      OffWork: 'Off Work',
      TruckChangeDriverTypeConfirmationMsg: 'Select role for the driver',
      SetAsDriver: 'Set as Driver',
      SetAsCoDriver: 'Set as CoDriver',
      SelectTruck: 'Select a truck',
      Driver: 'Driver',
      CoDriver: 'Co-Driver',
      RateUpdated: 'Rate updated',
      DriverCurrentSoloRate: 'Сurrent solo rate',
      DriverCurrentTeamRate: 'Сurrent team rate',
      DriverSetRateAsCurrentSolo: 'Set as current Solo rate',
      DriverSetRateAsCurrentTeam: 'Set as current Team rate',
      DriverSetAsCurrentRate: 'Set as current',
      driverRatesTypesNameHint: 'For entered name 2 rates will be added Team and Solo.',
      driverRatesValueHint: 'For Team rate entered value will be halved by default.',
      CommercialDrivingLicense: 'Picture of DL',
      Fields: {
        driverTruckTypeAppliedFor: 'Truck type applied For',
        driverDrivingExperience: 'Driving experience',
        driverAccidentsInLast3Years: 'Accidents in last 3 yrs',
        isReceiveEmailViaIMAP: 'Receive Email Via IMAP',
        imapHost: 'IMAP Host',
        imapPort: 'IMAP Port',
        imapIsSecure: 'IMAP Is Secure',
        imapLogin: 'IMAP Login',
        imapPassword: 'IMAP Password',
        desiredSalary: 'Desired Salary',
        about: 'About',
        usersFromOthers: 'Users From Others',
        disabledNotificationTypes: 'Disabled Notification Types',
        driverIsWorkOnPercentSWITCH: 'On percentage',
        driverPercentRate: 'Percent',
        driverTeamPercentRate: 'Team Percent',
        driverCurrentSoloRate: 'Current Solo Rate',
        driverCurrentTeamRate: 'Current Team Rate',
        driverDetentionRate: 'Detention Rate/Hour',
        deleteUsersDocuments: 'Delete users documents',
        driverSwitchToFullUI: 'Switch to full User Interface',
        driverCurrentRateExpiredDATE: 'Current Rate Expired Date',
        activity: 'Activity',
        role: 'Role',
        email: 'Email',
        firstName: 'First Name',
        lastName: 'Last Name',
        password: 'Password',
        avatarUPLOAD: 'Avatar',
        paymentDetails: 'Payment Details',
        paymentDetailsUPLOAD: 'Payment Details Files',
        agreementUPLOAD: 'Agreement',
        passportUPLOAD: 'Passport or ID',
        SSN: 'SSN',
        phoneNumber: 'Phone Number',
        resumeUPLOAD: 'CV',
        W2FormUPLOAD: 'Form W2',
        W9FormUPLOAD: 'Form W9',
        signature: 'Signature',
        isSendEmailViaSMTP: 'Send Email via SMTP Switch',
        smtpHost: 'SMTP Host',
        smtpPort: 'SMTP Port',
        smtpIsSecure: 'SMTP Secure',
        smtpLogin: 'SMTP Login',
        smtpPassword: 'SMTP Password',
        driverDrivingLicense: 'Driver`s License #',
        driverDrivingLicenseValidDATE: 'Driver`s License Expiration Date',
        driverDrivingLicenseUPLOAD: 'Driving License',
        driverMedicalCardValidDATE: 'Medical Card Expiration Date',
        driverMedicalCardUPLOAD: 'Medical Card',
        driverWorkPermitOrGreenCardUPLOAD: 'Work Permit Or Green Card',
        driverSSN: 'SSN',
        driverRates: 'Driver rates',
        driverDriverAgreementUPLOAD: 'Driver Agreement',
        driverTruck: 'Truck',
        driverOwnerOperatorSWITCH: 'Owner Operator',
        driverOwnerOperatorPercent: 'Payment Percent from Invoice',
        driverOwnerOperatorDetention: 'Detention per hour',
        driverOwnerOperatorIENUPLOAD: 'IEN',
        driverOwnerOperatorInsuranceUPLOAD: 'Insurance',
        driverOwnerOperatorAuthorityFormUSDOTUPLOAD: 'USDOT',
        driverOwnerOperatorAuthorityFormMCUPLOAD: 'MCU',
        driverOwnerOperatorAuthorityFormCAUPLOAD: 'CAU',
        driverOwnerOperatorW9FormUPLOAD: 'W9',
        driverOwnerOperatorStatementOfInformationCorporationUPLOAD: 'Statement Of Information Corporation',
        driverOwnerOperatorNameOfBank: 'Name Of Bank',
        driverOwnerOperatorBusinessName: 'Business Name',
        driverOwnerOperatorAddress: 'Address',
        driverOwnerOperatorAccount: 'Account',
        driverOwnerOperatorRouting: 'Routing',
        driverOwnerOperatorAgreementUPLOAD: 'Agreement',
        driverOwnerOperatorlinkedUsers: 'Linked Users',
        dispatcherTitle: 'Title',
        dispatcherEmail: 'Contact Email',
        dispatcherEmailTemplate: 'Email Template',
        dispatcherEmailSubjectTemplate: 'Email Subject Template',
        dispatcherEmailSignature: 'Email Signature',
        dispatcherCompanyLogoUPLOAD: 'Company Logo',
        dispatcherPaymentPercent: 'Payment Percent',
        dispatcherPaymentPercentWithOO: 'Payment Percent With Owner Operator',
        dispatcherPaymentPercentFromBonus: 'Payment Percent From Bonus',
        dispatcherPaymentPercentForPartialLoad: 'Payment Percent for partial load',
        dispatcherPaymentPercentForLTLLoad: 'Payment Percent from LTL load',
        dispatcherPaymentPercentForReturnLoad: 'Payment Percent from Return load',
        dispatcherWorkHoursStart: 'Start Work Time',
        dispatcherWorkHoursEnd: 'End Work Time',
        dispatcherOwnerOperatorBidAutoSend: 'Drivers Owner-Operator bids from which will send automaticaly',
        accountingManagerTitle: 'Title',
        accountingManagerEmail: 'Email',
        accountingManagerEmailSignature: 'Email Signature',
        accountingManagerCompanyLogoUPLOAD: 'Company Logo',
        accountingManagerWorkHoursStart: 'Start Work Time',
        accountingManagerWorkHoursEnd: 'End Work Time',
        brokerManagersJSON: 'Managers List',
        brokerName: 'Name',
        brokerEmails: 'Emails',
        brokerBillTo: 'Bill To',
        brokerIEN: 'Broker IEN',
        brokerPaymentNotes: 'Broker notes',
        brokerSetupPackageUPLOAD: 'Setup Package Documents',
        brokerDaysToPay: 'Days To Pay',
        brokerWithFactoringSWITCH: 'Factoring',
        brokerFactoringPercent: 'Factoring Percent',
        brokerFactoringPaymentDetails: 'Factoring Payment Details',
        brokerCompanyName: 'Company Name',
        brokerContactPerson: 'Contact Person',
        statusIsActive: 'Is Active',
        statusIsLoggedIn: 'Is Logged In',
        statusCreated: 'Creation Date',
        statusLastLogin: 'Last SignIn Date',
        statusLastActivity: 'Last Activity Date',
        isOpenItemsInModal: 'Open loads, invoices and trucks in modal',
        assistant: 'Assistant',
        assistants: 'Assistants',
        hiddenTrucks: 'Hidden Trucks',
        linkedToDispatcher: 'Linked To Dispatcher',
        hiringPosition: 'Position',
        TruckTypeAppliedFor: 'Truck Type Applied For',
        DrivingExperience: 'Driving Experience',
        AccidentsInLast3Years: 'Accidents In Last 3 Years',
        DesiredSalary: 'Desired Salary',
        dob: 'Date Of Birth',
      }
    },
    Roles: {
      SuperAdministrator: 'Super Admin',
      Administrator: 'Admin',
      Dispatcher: 'Dispatcher',
      Driver: 'Driver',
      DriverOwnerOperator: 'Driver Owner Operator',
      Broker: 'Broker',
      Engineer: 'Engineer',
      Assistant: 'Assistant',
      AccountingManager: 'Accounting Manager'
    },
    CreateUser: {
      ChangeRates: 'Change Rates',
      IncreaseRates: 'Increase',
      DecreaseRates: 'Decrease',
      SelectRole: 'Select role',
      PickAnAvatar: 'Pick an avatar',
      avatarUPLOAD: 'Avatar',
      FirstName: 'First name',
      LastName: 'Last name',
      FullName: 'Full name',
      EMail: 'E-Mail address',
      Password: 'Password',
      PasswordRepeat: 'Password repeat',
      Phone: 'Phone Number',
      PhoneWork: 'Work Phone Number',
      PhoneCell: 'Cell Phone Number',
      PhoneHome: 'Home Phone Number',
      PhoneFax: 'Fax Number',
      PhoneAdditional: 'Additional Phone Number',
      Submit: 'Create',
      Clear: 'Clear',
      GeneratePassword: 'Generate password',
      UpdatePassword: 'Update password',
      SavePassword: 'Save password',
      RemoveRateConfirmMsg: 'Are you sure you want to delete this rate?',
      RemoveFileConfirmMsg: 'Are you sure you want to delete this file?',
      SaveRoleAccessRightsConfirmMsg: 'Are you sure you want to save role rights?',
      SaveRoleWithUsersAccessRightsConfirmMsg: 'Are you sure you want to save role rights? This rights will be applied to all users with {role} role.',
      SaveUserAccessRightsConfirmMsg: 'Are you sure you want to save user rights?',
      ResetToRoleRightsConfirmMsg: 'Are you sure you want to reset user right? Rights of {user} will be equal with {role} role rights.',
      SaveSettingsConfirmMsg: 'Are you sure you want to save?',
      RateType: 'Rate Type',
      AddRate: 'Add Rate',
      NewRate: 'New Rate',
      UpdateRate: 'Update Rate',
      UpdateAllRates: 'Update for All Drivers',
      RemoveManagerConfirmMsg: 'Are you sure you want to delete this manager?',
      AddManager: 'Add Manager',
      UpdateManager: 'Update',
      AddFile: 'Add File',
      PressToSelectFile: 'Select file',
      Validators: {
        PasswordMustHaveAtLeastSymbols: 'Password must have at least {n} symbols',
        PasswordIsRequired: 'Password is required',
        PasswordsDoesNotMatch: 'Passwords does not match'
      },
      BasicInformation: 'Basic information',
      DriverInformation: 'Driver information',
      DriverOwnerOperatorInformation: 'Driver owner-operator information',
      DispatcherInformation: 'Dispatcher information',
      AccountingManagerInformation: 'Accounting manager information',
      BrokerInformation: 'Broker information',
      AdministratorInformation: 'Administrator information',
      EngineerInformation: 'Engineer information'
    },
    Validators: {
      SelectionIsRequired: 'Selection is required',
      FieldIsRequired: 'Field is required',
      MustBeValidEMail: 'E-Mail must be valid',
      EMailIsRequired: 'E-Mail is required',
      EmailAlreadyUsed: 'E-Mail already in use',
      MustBeNumber: 'Must Be Number',
      DriverRateTypeRule: 'Rate name must contain "solo" or "team" word'
    },
    LeftMenu: {
      Calendar: 'Calendar',
      Companies: 'Companies',
      Documents: 'Documents',
      LoadsCalendar: 'Loads Calendar',
      Tasks: 'Tasks',
      CreateTask: 'Create Tasks',
      Reports: 'Reports',
      Mails: 'Mails',
      Settings: 'Settings',
      Languages: 'Languages',
      InvertColors: 'Dark theme',
      AccessRights: 'Authorization',
      Logs: 'Logs',
      GlobalSettings: 'Global settings',
      Dashboard: 'Dashboard',
      Users: 'Users',
      CreateUser: 'Create User',
      CreateAdministrator: 'Create Administrator',
      CreateDispatcher: 'Create Dispatcher',
      CreateDriver: 'Create Driver',
      CreateDriverOwnerOperator: 'Create Driver Owner Operator',
      CreateAccountingManager: 'Create Accounting Manager',
      CreateBroker: 'Create Broker',
      CreateEngineer: 'Create Engineer',
      CreateAssistant: 'Create Assistant',
      AllUsersList: 'Users',
      all: 'All Roles',
      SuperAdministrator: 'Super Administrator',
      Administrator: 'Admins',
      Dispatcher: 'Dispatchers',
      Assistant: 'Assistants',
      Broker: 'Brokers',
      Driver: 'Drivers',
      DriverOwnerOperator: 'Drivers Owners',
      Engineer: 'Engineers',
      AccountingManager: 'Accounting Managers',
      Search: 'Search',
      Charts: 'Charts',
      CreateChart: 'Create charts',
      Trucks: 'Trucks',
      TrucksCalendar: 'Trucks schedule',
      CreateTruck: 'Add truck',
      Trailers: 'Trailers',
      CreateTrailer: 'Add Trailers',
      Bids: 'Bids',
      CreateBid: 'Create bid',
      Loads: 'Loads',
      CreateLoad: 'Create load',
      Invoices: 'Invoices',
      CreateInvoice: 'Create invoice',
      Notifications: 'Notifications',
      CreateNotifications: 'Create Notifications',
      Shifts: 'Trips',
      CreateShifts: 'Create a trip',
      ShiftsByDriver: 'By Drivers',
      ShiftsByTrucks: 'By Trucks',
      Chat: 'Chat',
      Chats: 'Chats',
      CreateChats: 'Create Chats',
      PostToChats: 'Post To Chats',
      LoadsFeed: 'Loads Feed'
    },
    PageTabs: {
      CloseTabConfirmationMessage: 'Do you want close the tab?'
    },
    Tooltips: {
      UsersHOS: 'Driver HOS',
      UsersMergeBroker: 'Merge broker',
      UsersListViewAs: 'View as user',
      UsersListEdit: 'Edit user data',
      UsersListDeactivate: 'Activate / Deactivate user',
      UsersListUserPage: 'Open user page'
    },
    NoSearchResults: 'No Search Results',
    Charts: {
      CreateNewConfirmationMsg: 'Do you want to create chart?',
      SaveConfirmationMsg: 'Do you want to save this chart?',
      CreateChartTitle: 'Create a chart',
      EditChartTitle: 'Edit a chart',
      List: 'Charts list',
      CreateChart: 'Create new chart',
      ChartSaved: 'Chart saved!',
      ChartTitleIsEmpty: 'Fill chart title for save!',
      RemoveConfirmMsg: 'Are you sure oyu want to remove this chart?',
      Years: 'Years',
      Month: 'Month',
      Days: 'Days',
      Hours: 'Hours',
      COUNT: 'Count',
      SUM: 'Sum',
      AVG: 'Avg',
      MAX: 'Max',
      MIN: 'Min',
      Interval: 'Interval'
    },
    Dashboards: {
      SelectDashboardType: 'Select type',
      SelectChart: 'Select a chart',
      AddPanel: 'Add panel',
      TextTypeTextareaHint: 'Enter text here',
      RemoveConfirmMsg: 'Are you sure oyu want to remove this panel?',
      LayoutSaved: 'Dashboard layout saved',
      CreatingDashboardWidget: 'Creating dashboard widget'
    },
    Trucks: {
      Photos: 'Photos',
      Map: 'Map',
      DateOutsideIntervalRangeFromLastAnnualInspection: 'Date is outside interval range from the last annual inspection',
      FaultCodes: 'Fault Codes',
      AnnualInspectionsList: 'Annual Inspections',
      AnnualInspectionsOdometerMiles: 'Odometer mi.',
      AnnualInspectionsAnnual: 'Annual',
      AnnualInspectionsQuarterly: 'Quarterly',
      AddMaintenanceTypeConfirmationMsg: 'Are you sure to add this maintenance type?',
      AddMaintenanceType: 'Add Maintanance Type',
      AddMaintenanceTypeLabel: 'Select or Enter New Maintanance Type',
      AnnualInspectionReport: 'Annual Inspection Report',
      NewAnnualInspectionReport: 'New Annual Inspection',
      MilesReportTitle: 'Trucks Miles Statistics',
      Status: 'Status',
      LastFinishedLocation: 'Last done',
      OilChangeSavedMsg: 'Oil change saved',
      UpdateOilChange: 'New Oil Change',
      Available: 'Available Trucks',
      ShowHiddenTrucks: 'Show hidden trucks',
      ShowHiddenTrailers: 'Show hidden trailers',
      Information: 'Information',
      MaintenanceInformation: 'Maintenance Information',
      MaintenanceOriginalParts: 'Original Parts',
      Documents: 'Documents',
      Report: 'Report',
      OilReplacementHistory: 'Oil change history',
      Properties: 'Properties',
      Oil: 'Oil',
      NoTruck: 'No truck',
      LocationTooltip: 'Current location is {data}',
      SpeedTooltip: 'Current speed is {data} mph',
      EngineHoursTooltip: 'Current engine hours is {data}',
      OdometerTooltip: 'Current odometer is {data} mi.',
      FuelTooltip: 'Fuel {data} gal.',
      FuelPercentTooltip: 'Fuel {data} %.',
      Page: 'Truck page',
      dimensions: 'Dimensions LхWхH',
      dimensionsDetail: 'Dimensions @door WхH',
      ShowTrucksImages: 'Show Truck Photo',
      ShowTrailersImages: 'Show Trailer Photo',
      ShowTruckDetails: 'Show details',
      NewTruckAdded: 'New truck added.',
      TruckDataSaved: 'Truck data saved.',
      TruckDataSaveError: 'Truck data save error.',
      ErrorWhenAddingTheTruck: 'Error when adding the truck.',
      NewTruckMaintenanceAdded: 'New truck maintenance added.',
      TruckMaintenanceSaved: 'Truck maintenance saved.',
      ErrorWhenSavingTheTruckMaintenance: 'Truck maintenance save error.',
      ErrorWhenAddingTheTruckMaintenance: 'Error when adding the truck maintenance.',
      Drivers: 'Drivers',
      NoDrivers: 'No driver are selected',
      NoCoDrivers: 'No co-driver are selected',
      Driver: 'Driver',
      CoDriver: 'Co-Driver',
      DriverOO: 'Owner-Operator',
      Create: 'Add Truck',
      CreateTrailer: 'Add Trailer',
      ChangeTruckConfirmation: 'Are you sure you want to change truck?',
      CreateConfirmation: 'Are you sure you want to create truck?',
      SaveConfirmation: 'Are you sure you want to save changes?',
      DeleteConfirmation: 'Are you sure you want to delete this truck?',
      ChangeTruckStatusConfirmation: 'Are you sure you want to update this truck status?',
      SaveMaintenanceConfirmation: 'Save truck maintenance?',
      ErrorMessages: {
        MaintenanceTypeShouldBeSelectedOrTypedMsg: 'Maintenance type should be selected or typed'
      },
      Statuses: {
        Active: 'Active',
        Abandoned: 'Available (Priority)',
        Maintenance: 'Maintenance',
        Deactivated: 'Deactivated',
        Available: 'Available',
        NotAvailableUntil: 'N/A Until'
      },
      Notifications: {
        maintenanceExpiredSoon: 'Truck need {type} soon. {value} miles left.',
        maintenanceExpired: 'Truck need {type} soon. {value} miles over limit.',
        oilReplacementExpiredSoon: 'Truck need oil change soon. {n} miles left.',
        oilReplacementExpired: 'Truck need oil change soon. {n} miles over limit.',
        annualInspectionExpiredSoon: 'Soon annual inspection will needed. {n} days left.',
        annualInspectionExpired: 'Truck need annual inspection. {n} days ago.',
        registrationCardExpiredSoon: 'Truck registration card expire soon. {n} days left.',
        registrationCardExpired: 'Truck registration card expired. {n} days ago.',
        insuranceExpiredExpiredSoon: 'Truck insurance expire soon. {n} days left.',
        insuranceExpiredExpired: 'Truck insurance expired. {n} days ago.'
      },
      Fields: {
        maintenanceInformation: 'Maintenance Information',
        truckFleetNumber: 'Fleet Number',
        activity: 'Activity',
        truckAvatarUPLOAD: 'Truck Photo',
        name: 'Name',
        isTrailer: 'Is Trailer',
        trailerType: 'Trailer Type',
        linkedTruckId: 'Attached to',
        licensePlate: 'License Plate',
        year: 'Year',
        model: 'Model',
        usdot: 'USDOT',
        owner: 'Owner',
        tireSize: 'Tire Size',
        vin: 'VIN',
        registrationCardExpiredDATE: 'Registration Card Expired',
        insuranceExpiredDATE: 'Insurance Expired',
        lastAnnualInspectionDATE: 'Last Annual Inspection',
        lastOilReplacementDATE: 'Last oil change date',
        lastOilReplacementMiles: 'Last oil change miles',
        lastOilReplacementUser: 'Added by user',
        nextOilReplacementIntervalMiles: 'Oil change interval',
        oilReplacementAfterMiles: 'Oil change after',
        length: 'Length',
        dimensionsL: 'Inside L',
        dimensionsW: 'Inside W',
        dimensionsH: 'Inside H',
        dimensionsDetailL: 'At door L',
        dimensionsDetailW: 'At door W',
        dimensionsDetailH: 'At door H',
        liftGate: 'Lift gate',
        palletJack: 'Pallet jack',
        airRide: 'Air ride',
        iccBars: 'ICC bars',
        reefer: 'Reefer',
        isVented: 'Vented',
        isSwingDoors: 'Swing Doors',
        isRollupDoors: 'Rollup Doors',
        sleeperSpace: 'Sleeper space',
        maxLoadWeight: 'Max Load Weight',
        eTrackQuantity: 'Number of eTrack',
        loadBarsQuantity: 'Number of Load bars',
        strapsQuantity: 'Number of Straps',
        blanketsQuantity: 'Number of Blankets',
        spareWheels: 'Spare Wheels',
        snowChains: 'Snow Chains',
        emptyScaleWeight: 'Empty Scale Weight',
        emptyScaleTicketUPLOAD: 'Empty Scale Ticket',
        truckInsuranceUPLOAD: 'Truck insurance',
        trailerInsuranceUPLOAD: 'Trailer insurance',
        truckRegistrationUPLOAD: 'Truck Registration',
        trailerRegistrationUPLOAD: 'Trailer Registration',
        annualInspectionUPLOAD: 'Annual Inspection',
        miles: 'Miles on odometer',
        location: 'Location',
        status: 'Status',
        type: 'Type',
        notes: 'Notes',
        maintenanceNote: 'Maintenance Notes',
        driver: 'Driver',
        codriver: 'Co-Driver',
        driverOO: 'Driver Owner-Operator',
        syncDriversWithKT: 'Sync truck driver with KeepTruckin',
        syncDriversWithKTShort: 'Sync w/KT',
        trucksFromOthers: 'Trucks from others',
        distanceBetween: 'Distance between e-tracks',
        samsaraKey: 'Samsara API key',
        keeptruckinKey: 'Motive (KeepTruckin) API Key'
      },
      APIKeyHint: 'Global key will be used if empty',
      LastUsedParts: 'Last parts used',
      Maintenance: 'Maintenance',
      MaintenanceStatus: 'Maintenance Status',
      NewMaintenance: 'New Maintenance',
      NewMaintenanceRecord: 'New Record',
      CreateMaintenance: 'Maintenance Record',
      maintenanceDate: 'Maintenance Date',
      maintenanceMiles: 'Odometer Miles',
      maintenanceType: 'Type',
      maintenancePartName: 'Part Name',
      maintenancePartCode: 'Part Code',
      maintenancePartAmount: 'Parts Amount',
      maintenanceIsReplaced: 'Replaced With',
      maintenanceScheduledOnly: 'Scheduled',
      maintenanceIsOriginal: 'Original Part',
      maintenancePartWear: 'Part Wear',
      maintenancePartSize: 'Part Size',
      maintenanceNewPartName: 'New Part Name',
      maintenanceNewPartCode: 'New Part Code',
      maintenancePartUsageMiles: 'Mileage since prev',
      maintenancePartUsageSeconds: 'Time since prev',
      maintenancePartPrevMaintenanceDate: 'Last maintanance date',
      maintenanceNote: 'Notes',
      nextMaintenanceMiles: 'Next maintenance milage',
      nextMaintenanceDATE: 'Next scheduled maintenance',
      maintenanceAtt: 'Attachement',
      RemoveMaintenanceTypeConfirmationMsg: 'Remove this maintenance?'
    },
    Bids: {
      Planned: 'Planned',
      plannedDATE: 'Planned date',
      brokerNameOrEmailUnavailable: 'New broker name or email are unavailable.',
      AddEmailToSelectedBroker: 'Add email to the selected broker',
      CreateLoad: 'Create Load',
      Bid: 'Bid',
      UseAdvTruckDimensions: 'Use advanced truck dimensions',
      ResetDraft: 'Reset Draft',
      UseGmailSignature: 'Append signature',
      SendEmailAfterCreating: 'Send email after bid was created',
      SelectSender: 'Select sender',
      Edit: 'Edit',
      SameRouteRates: 'Same route rates',
      TrafficBestGuess: 'Best Guess',
      TrafficOptimistic: 'Optimistic',
      TrafficpPessimistic: 'Pessimistic',
      Source: 'Source',
      SaveConfirmationMsg: 'Do you want to save this bid?',
      SaveAndSendConfirmationMsg: 'Do you want to save and send this bid?',
      CreateNewConfirmationMsg: 'Send bid?',
      SendEmailConfirmationMsg: 'Do you want to send email from {email}?',
      Create: 'Create bid',
      Send: 'Send bid',
      Save: 'Save bid',
      BidDataCreated: 'Bid was created and sent',
      BidDataCreateError: 'Bid create error',
      BidDataSaved: 'Bid saved',
      BidDataSaveError: 'Bid save error',
      EnterLocation: 'Enter Location',
      BidsCreateLoad: 'Create load',
      SelectDispatcher: 'Select Dispatcher',
      AddTruckLocation: 'Truck on {addr}. Add truck location?',
      EmailRecipient: 'Recipient',
      EmailCC: 'Copy',
      EmailSubject: 'Email subject',
      EmailBody: 'Email body',
      EmailCustomText: 'Optional text',
      UpdateEmailBody: 'Update email text',
      SaveAndSend: 'Save and Send',
      CreateAndSend: 'Create and Send',
      SendAndAddNew: 'Send and create new',
      CreateAndSendNew: 'Create new with current data',
      SelectBroker: 'Select broker',
      SelectManager: 'Select manager',
      ListTitle: 'Bids List',
      ListStatus: 'Status',
      ListCreated: 'Created',
      ListUpdated: 'Updated',
      ListDispatcher: 'Dispatcher',
      ListCreatorDispatcher: 'Creator / Dispatcher',
      ListLoad: 'Load',
      ListManager: 'Manager',
      ListSender: 'Sender',
      ListRecipient: 'Recipient',
      ListInCopy: 'Copy',
      ListTruck: 'Truck',
      ListBroker: 'Broker',
      ListTruckType: 'Truck Type',
      ListSubject: 'Subject',
      ListDistance: 'Distance',
      ListDuration: 'Duration',
      ListDistanceToPickup: 'Distance to pickup',
      ListRatePerMile: 'Rate per mile',
      ListRate: 'Rate',
      DELETED: 'DELETED',
      RestoreBid: 'Restore bid',
      DeleteBid: 'Delete bid',
      BidDeleteConfirmationMsg: 'Do you want delete bid?',
      BidRestoreConfirmationMsg: 'Do you want restore bid?',
      BidDeleted: 'Bid deleted.',
      BidDeleteError: 'Error when delete bid.',
      BidRestored: 'Bid restored.',
      BidRestoreError: 'Error when restoring bid.',
      AddHQLocation: 'Add home parking location',
      FeedMessageText: 'Message Text',
      CopyFiledHint: 'Enter email and press enter or tab to continue'
    },
    Loads: {
      ByMap: 'By Map',
      Actions: 'Actions',
      Map: 'Map',
      ApproveAll: 'Approve All',
      LocationCanceled: 'Location Canceled',
      CancelLocation: 'Cancel',
      CheckIn: 'Check In',
      CheckOut: 'Check Out',
      driverByMilesRate: 'Driver By Miles Rate',
      coDriverByMilesRate: 'Co-Driver By Miles Rate',
      driverFixedRate: 'Driver Fixed Rate',
      coDriverFixedRate: 'Co-Driver Fixed Rate',
      driverByMilesPlusFixedRate: 'Driver By Miles + Fixed Rate',
      coDriverByMilesPlusFixedRate: 'Co-Driver By Miles + Fixed Rate',
      LocationsDatesAreNotConsecutive: 'Locations start Date and Time must be consecutive.',
      LocationStarted: 'Started',
      LocationStart: 'Start',
      Earnings: 'Earnings',
      AfterEditingMarkAsRefined: 'After editing mark as refined',
      approveLocation: 'Approve',
      updateReturnToHQLocationMsg: 'By updating this location address its type will be cleared and "Return to Home Parking" on this load will be turned off',
      ShowHideAllLocations: 'Show/Hide all locations',
      TruckViewMode: 'Locations limited by truck',
      NextLoadForTruck: '{truckName} next load is',
      PrevLoadForTruck: '{truckName} prev load is',
      NoTruckNextLoad: 'No further load',
      CurrentLoads: 'Current loads',
      AddReturnToHQConfirmMsg: 'Add return to the home parking?',
      EnterExpensePlaceholder: 'Enter expense name or select',
      TruckCannotBeChangedSinceAlreadyStartedMsg: 'Сannot be changed since the truck is already started.',
      DriverCannotBeChangedSinceAlreadyStartedMsg: 'Сannot be changed since the driver is already started.',
      SetCurrentLocationFromSelectedTruckLocationConfirmMsg: 'Set current location the same as location of the selected truck?',
      UpdateOdometersFromLogs: 'Update Odometers From Logs',
      ConfirmUpdateOdometersFromLogs: 'By updatting odometers from logs all current odometer values in the load locations will be replaced with truck logs values. Are you sure?',
      LoadWaitingDispatcherApproval: 'Load waiting dispatcher approval',
      LoadWaitingToBeFinished: 'Load waiting to be finished',
      Mails: 'Mails',
      MailsUnread: 'Unread mails',
      cancelNextLocationsConfirmationMessage: 'Mark the following locations as canceled?',
      MarkAsStarted: 'Mark as started',
      MarkAsOnSite: 'Mark as on site',
      MarkAsLoaded: 'Mark as loaded',
      MarkAsUnLoaded: 'Mark as unloaded',
      MarkAsDone: 'Mark as done',
      MarkAsApproved: 'Approve location',
      ApproveLoadToStart: 'Approve to start',
      EditingUnlockRequested: 'Editing unlock is requested',
      RequestEditUnlock: 'Request Editing',
      MarkAsWaitingFurtherActions: 'Wait Further Actions',
      NotLoadedFiles: 'Not loaded files',
      ShowUnclosedLoads: 'Show only unclosed',
      TruckDrivers: 'Trucks/Drivers',
      EditingIsLocked: 'Editing is locked',
      EditingIsLockedForAll: 'Editing is locked for all',
      EditingIsLockedForDrivers: 'Editing is locked for drivers',
      EditingIsLockedForDispatchers: 'Editing is locked for dispatchers',
      EditingIsUnlocked: 'Editing Is Unlocked',
      LoadWithNumberExist: 'Load with this number already exist',
      Cancel: 'Cancel',
      Canceled: 'Canceled',
      Miles: 'Miles',
      Origin: 'Origin',
      Destination: 'Destination',
      Info: 'Info',
      OverrideEditLock: 'Editing allowance',
      DroveIn: 'Drove In',
      DroveOut: 'Drove Out',
      DocumentsNotMeetRequirements: 'Some documents required for Invoice are missing',
      MarkAsRefined: 'Mark As Refined',
      MarkAsRefine: 'Mark To Refine',
      MarkAsVerified: 'Mark As Verified',
      UndoVerified: 'Verified canceled',
      Verified: 'Verified',
      Refine: 'Refine',
      Refined: 'Refined',
      RefineNotes: 'Refine Notes',
      RefinedNotes: 'Refined Notes',
      RequestEditUnlockNotes: 'Request Editing Notes',
      SharedBetweenCount: 'Shared between',
      New: 'New',
      InProgress: 'In Progress',
      Finished: 'Finished',
      PickupAt: 'Pickup at',
      DeliveryAt: 'Delivery at',
      ParkingAt: 'Parking at',
      ExchangeAt: 'Exchange at',
      ReturnAt: 'Return at',
      TruckIsNotSelected: 'Truck is not selected',
      DriverIsNotSelected: 'Driver is not selected',
      LinkedLoad: 'Linked load',
      StartedEnded: 'Started / Ended',
      Locations: 'Locations',
      DispatcherBroker: 'Dispatcher / Broker',
      OdometerPhotoIsNotUploaded: 'To start, you must upload a photo of the odometer',
      OdometerPhotoIsNotUploadedToFinish: 'To complete, you must upload a photo of the odometer',
      OverrideSalaryConfirmationMsg: 'Set custom salary?',
      ReseySalaryOverrideConfirmationMsg: 'Reset custom salary?',
      OverrideSalary: 'Setting custom salary',
      ExpenseFromLoad: 'Expense from load',
      ExpensesHasPending: 'Pending expenses available',
      DoApproveExpense: 'Approve Expense',
      DoResetExpense: 'Reset Approve',
      ApproveExpense: 'Approve Expense?',
      ResetExpense: 'Reset Approve?',
      IsAddExpenseToRate: 'Add To Rate',
      IsAddedExpenseToRate: 'Added To Rate',
      IsCalcInDispatcherRate: 'Add To Dispatcher Rate Calc',
      NoTruckIsSelected: 'Truck is not selected',
      onReturnToHQSwitchConfirmMsg: 'Please choose where truck go after the load is finished.',
      truckChangedResetStatusMsg: 'Status and odometer values will be reset for location where truck changed',
      notFinishedStatus: 'Not Finished',
      notFinish: 'Unfinish',
      notFinishConfirmationMessage: 'Doy you want to unfinish current load?',
      finish: 'Finish',
      finishConfirmationMessage: 'Doy you want to finish current load?',
      finishedWithTONUStatus: 'Finished With TONU',
      finishWithTONU: 'Finish With TONU',
      finishWithTONUConfirmationMessage: 'Doy you want to finish current load with "Truck Ordered Not Used" status?',
      finishWithCancelConfirmationMessage: 'Doy you want to cancel the load?',
      TotalWithLinkedLoadsTitle: 'Finances for all loads',
      TotalWithLinkedLoads: 'Total with linked loads',
      TotalIncomeWithLinkedLoads: 'Total income with linked loads',
      TotalExpensesWithLinkedLoads: 'Total expenses with linked loads',
      TotalExpenses: 'Total expenses',
      GMapsDistance: 'GMaps distance',
      UpdatingDistancesAndDurations: 'Distances and times between locations are calculated...',
      AdditionalInfo: 'Additional info',
      DispatcherPercents: 'Dispatcher percents',
      PopulateLocationSettingsDown: 'Fill following locations with the same settings',
      ProgressSteps: 'Progress steps',
      GoToNavigation: 'Go To Navigation',
      WaitingDispatcherApproval: 'Waiting for the dispatcher approval',
      ListEmpty: 'Loads list is empty',
      ListStatus: 'Status',
      ListTrucks: 'Truck',
      ListDriver: 'Driver',
      ListBroker: 'Broker',
      ListDispatcher: 'Dispatcher',
      NoLoadRightNow: 'Without a cargo',
      LoadTrackingNotificationsSetupSuccess: 'Tracking Notification configured successfully',
      LoadTrackingNotificationsSetupError: 'Tracking Notification configuration error',
      TrackingNotifications: 'Tracking Notifications',
      TrackingNotificationsRecipients: 'Recipients',
      TrackingNotificationsInCopyRecipients: 'In Copy',
      TrackingNotificationsInterval: 'Sent interval',
      TrackingNotificationsSendByRequest: 'Send by request',
      TrackingNotificationsEmailSubject: 'Subject template',
      TrackingNotificationsEmailTemplate: 'Message template',
      TrackingNotificationsAutoReplyInfoTemplate: 'Auto reply information template',
      TrackingNotificationsSetup: 'Save',
      inCopyRecipients: '',
      TruckSpeed: 'Speed',
      TruckLocation: 'Location',
      TruckOdometer: 'Odometer',
      TruckFuel: 'Fuel',
      TruckEngineHours: 'Engine hours',
      TruckLoadDescription: '\'Truck Load\' - used for the fully priced first load to the truck.',
      LessThanTruckLoadDescription: '\'Less Than Truck Load\' - used for the fully priced, when this load combined with another \'Truck Load\' or \'Less Than Truck Load\'',
      PartialTruckLoadDescription: '\'Partial Truck Load\' - used when load have less then usual price. When truck already have one fully priced load, have space for new load, and delivery of the partial load along the way.',
      ReturnTruckLoadDescription: '\'Return Truck Load\' - used for the loads which will taken to prevent truck returning home parking empty.',
      RoundTripLoadDescription: '\'Round Trip Load\' - used for the load has one ratecon and two types of loads TL and RTL.',
      ResetStatuses: 'Reset statuses',
      NewLocationAddress: 'Insert new location',
      LocationAddress: 'Location address',
      AddSelectedLocation: 'Add selected location',
      UserTruckLocation: 'Add truck location',
      UseTruckLocationAtStartLocation: 'Truck on {addr}. Use truck location for start location?',
      AddHQLocation: 'Add home parking location',
      LoadIsFinishedCreateInvoice: 'Load is finished create invoice?',
      Information: 'Information',
      Invoice: 'Invoice',
      parentLoad: 'Parent load',
      deleteLoad: 'Delete load',
      restoreLoad: 'Restore load',
      ReturnToHQ: 'Return to home parking',
      AddReturnToHQLocation: 'Append home parking location',
      driverReturnToHQRate: 'Driver return rate',
      codriverReturnToHQRate: 'CoDriver return rate',
      driverReturnToHQRatePerMile: 'Driver return rate per mile',
      codriverReturnToHQRatePerMile: 'CoDriver return rate per mile',
      HQAddress: 'Home parking address',
      EnterHQAddress: 'Enter home parking address',
      FinancialData: 'Finances',
      View: 'View',
      Edit: 'Edit',
      Status: 'Status',
      LoadDeleted: 'Load deleted.',
      LoadDeleteError: 'Error when delete load.',
      LoadRestored: 'Load restored.',
      LoadRestoreError: 'Error when restoring load.',
      LoadDeleteConfirmationMessage: 'Do you want to delete this load?',
      LoadRestoreConfirmationMessage: 'Do you want to restore this load?',
      StatusUpdateConfirmationMessage: 'Are you sure you want to update the load status?',
      StatusClearConfirmationMessage: 'Are you sure you want to reset the load status?',
      CloneConfirmationMessage: 'All current load fields will be cloned to the new load except documents and notes. Do you want to clone current load?',
      GoToClonedLoads: 'Open cloned load?',
      CloneSuccess: 'Load succesfully cloned.',
      CloneError: 'Load cloning error.',
      DeleteExpenseConfirmation: 'Delete expense?',
      SaveExpensesConfirmation: 'Save expenses?',
      DriversAreEqualError: 'Driver and Co-Driver can not be the same.',
      PreviousStepNotDone: 'Previous step not done',
      Created: 'Created',
      Updated: 'Updated',
      UID: 'Load number',
      StartPlan: 'Start planned on',
      EndPlan: 'End planned on',
      SelectDispatcher: 'Select Dispatcher',
      SelectDispatcherToShare: 'Share with dispatchers',
      SelectBroker: 'Select Broker',
      SelectTruck: 'Select Truck',
      SelectDriver: 'Select Driver',
      SelectDriverOO: 'Select Owner Operator',
      SelectSecondDriver: 'Select Co-Driver',
      driverOwnerOperatorBonus: 'Bonus with Оwner-Operator',
      driverOwnerOperatorPercent: 'Owner-operator percent',
      Dispatcher: 'Dispatcher',
      DispatcherShort: 'Disp.',
      DispatcherPayment: 'Dispatcher payment',
      DispatcherPaymentRTL: 'Dispatcher RTL payment',
      DispatcherPaymentFromBonus: 'Dispatcher payment from bonus',
      Broker: 'Broker',
      Truck: 'Truck',
      Driver: 'Driver',
      Drivers: 'Drivers',
      CoDriver: 'Co-Driver',
      DriverRate: 'Driver rate',
      CoDriverRate: 'Co-Driver rate',
      Bid: 'Bid',
      Detention: 'Detention',
      Layover: 'Layover',
      Fine: 'Fine',
      DriverAssist: 'Driver assist',
      Idle: 'Idle',
      Rate: 'Rate',
      TLRate: 'TL Rate',
      RTLRate: 'RTL Rate',
      LoadRate: 'Load Rate',
      LoadRateOO: 'Load Rate for Owner-Operator',
      RateTONU: 'Rate TONU',
      plannedRatePerMile: 'Planned Rate per mile',
      RatePerMile: 'Rate per mile',
      plannedDistanceInMiles: 'Planned miles',
      ActualDistanceInMiles: 'Actual miles',
      distanceInMiles: 'Total distance in miles',
      durationTime: 'Duration',
      FromLocation: 'Departure',
      ToLocation: 'Destination',
      Pickup: 'Pickup',
      Delivery: 'Delivery',
      Parking: 'Parking',
      Exchange: 'Exchange',
      isFactoring: 'Factoring',
      isDriverOwnerOperator: 'Driver owner-operator',
      isDriverAssist: 'Driver Assisted',
      isDriverAllowedReturnToHQ: 'Allow driver choose return to home parking',
      isDriverAllowedToContinue: 'Allow driver to continue the load',
      ReturnToHQLocation: 'Return to Home Parking',
      isLocalLoad: 'Local load',
      isPickupRate: 'Pickup rate',
      defaultRate: 'Default rate',
      isAllMiles: 'All miles',
      isZipToZipMiles: 'Zip to zip miles',
      isEmptyMiles: 'Empty miles',
      isPickupMiles: 'Pickup miles',
      isReturnMiles: 'Return miles',
      driverLocalRate: 'Driver local rate',
      codriverLocalRate: 'Co-Driver local rate',
      driverRate: 'Driver rate',
      driverOORate: 'Driver owner operator rate',
      driverOOSalaryRate: 'Driver owner operator salary',
      codriverRate: 'Co-Driver rate',
      driverPickupRate: 'Driver pickup rate',
      codriverPickupRate: 'Co-Driver pickup rate',
      driverZipToZipRate: 'Driver zip to zip rate',
      codriverZipToZipRate: 'Co-Driver zip to zip rate',
      driverEmptyRate: 'Driver empty miles rate',
      codriverEmptyRate: 'Co-Driver empty miles rate',
      driverReturnRate: 'Driver return miles rate',
      codriverReturnRate: 'Co-Driver return miles rate',
      driverAllMilesRate: 'Driver all miles rate',
      codriverAllMilesRate: 'Co-Driver all miles rate',
      isDedicatedTruck: 'Dedicated truck',
      isEasyMode: 'Easy Mode',
      dispatcherPartialRate: 'Dispatcher partial rate',
      dispatcherLTLRate: 'Dispatcher LTL rate',
      dispatcherReturnRate: 'Dispatcher Return rate',
      TargetTime: 'Target time',
      ActualTime: 'Actual time',
      ActualDistance: 'Actual dist',
      setAppoinment: 'Appointment details',
      unsetAppoinment: 'Remove appointment details',
      PickupLocation: 'Pickup Location',
      PickupDatetime: 'Pickup Datetime',
      DeliveryLocation: 'Delivery Location',
      DeliveryDatetime: 'Delivery Datetime',
      TargetLocation: 'Destination',
      RecalcTime: 'Recalc timings',
      RegenShipper: 'Update "Shipper"',
      RegenShipTo: 'Update "Ship To"',
      SaveConfirmationMsg: 'Do you want to save this load?',
      SaveFilesConfirmationMsg: 'Do you want to save changes?',
      ListTitle: 'Loads List',
      LoadDataCreated: 'Load created',
      LoadDataCreateError: 'Load create error',
      LoadDataSaved: 'Load saved',
      LoadDataSaveError: 'Load save error',
      DateFormatError: 'Date format error',
      EnterLocationDateTime: 'Be on location',
      LeftLocationDateTime: 'Left location',
      ToLocationTarget: 'Total',
      ToLocationLeft: 'Left',
      OnLocationTime: 'Time on location',
      StandBy: 'Standby',
      Start: 'Start',
      Started: 'Pickup Date',
      Ended: 'Delivery Date',
      onTheWayStatus: 'On the way',
      dispatcherOnTheWayStatus: 'Can drive',
      waitingDispatcherApproval: 'Waiting dispatcher approval',
      dispatcherApproved: 'Approved by dispatcher',
      onSiteStatus: 'On site',
      loadedStatus: 'Loaded',
      notLoadedStatus: 'Not Loaded',
      finishedStatus: 'Finished',
      exchangedStatus: 'Exchanged',
      returnToHQStatus: 'Returned',
      parkedStatus: 'Parked',
      startToString: 'Start to {type} on {name}',
      startStatus: 'Start',
      onSiteToPickupStatus: 'On pickup',
      onTheWayToPickupStatus: 'On the way to pickup',
      unloadedStatus: 'Unloaded',
      notUnloadedStatus: 'Not Unloaded',
      onSiteToDeliveryStatus: 'On delivery',
      onTheWayToDeliveryStatus: 'On the way to delivery',
      onParkingStatus: 'On parking',
      onTheWayToParkingStatus: 'On the way to parking',
      onExchangeStatus: 'On exchange place',
      onTheWayToExchangeStatus: 'On the way to exchange',
      uploadDocuments: 'Documents',
      odometerStartData: 'Odometer start value',
      odometerEndData: 'Odometer finish value',
      odometerData: 'Odometer value',
      setAsPickupLocation: 'Set as pickup location',
      setAsDeliveryLocation: 'Set as delivery location',
      setAsParkingLocation: 'Set as parking location',
      setAsExchangeLocation: 'Set as exchange location',
      RateCon: 'RateCon',
      PublicRateCon: 'Public RateCon',
      DeliveryOrder: 'Delivery order',
      POD: 'Proof of delivery',
      BOL: 'Bill of lading',
      LoadPhotos: 'Load photos',
      ForfeitsTitle: 'Forfeit',
      AddExpense: 'Add expense',
      Expense: 'Expense',
      Deduction: 'Deduction',
      Bonus: 'Bonus',
      EditExpense: 'Edit expense',
      ExpensesAdded: 'Added',
      ExpensesTitle: 'Expenses',
      ExpensesName: 'Name',
      ExpensesTotal: 'Total',
      ExpensesAmount: 'Qty',
      ExpensesPerItem: 'Per item',
      ExpensesAddCheck: 'Add check',
      GallonsAmount: 'Gallons',
      ExpensesPerGallon: 'Per Gallon',
      ExpensesCheckUpload: 'Uploaded check',
      Including: 'Incomes',
      TotalIncluding: 'Total Incomes',
      Excluding: 'Expenses',
      Income: 'Total income',
      percentFromOOLoadRate: 'Percent from Owner-Operator load',
      dispatcherPaymentPercent: 'Dispatcher percent',
      dispatcherPaymentPercentWithOO: 'Dispatcher percent with Owner-Operator',
      dispatcherPaymentPercentFromBonus: 'Dispatcher percent from bonus',
      including: 'including',
      rateForTheLoad: 'Rate for the load',
      createInvoice: 'Create invoice',
      isExcludeDispatcherPayment: 'Exclude dispatcher percent',
      shipTo: 'Ship To',
      shipToCompanyName: 'Customer company name',
      shipToPhone: 'Customer contact phone number',
      shipToContactPerson: 'Customer contact person',
      loadDescription: 'Load description',
      shipper: 'Shipper',
      consignee: 'Consignee',
      loadNumber: 'Load Number',
      selectDispatchersToShareAccess: 'Open access to dispatchers',
      driverExpenseTotal: 'Driver Expenses',
      driverCompensateTotal: 'Compensate to driver',
      driverAddToSalary: 'Add to driver salary',
      driverFine: 'Driver fine',
      DispatcherPayments: 'Dispatcher salary',
      DriversPayments: 'Driver salary',
      driversReports: 'Drivers Reports',
      driversReportsUPLOAD: 'Drivers Reports Documents',
      finishedWithoutInvoice: 'Finished but without invoice',
      loadNotEnded: 'Load steps aren`t ended',
      editing_without_view: 'Allow load changes without ability to view',
      type: "Load type",
      ended: "Load end date time",
      started: "Start end date time",
      financialInformation: 'Load`s finantial Informtation',
      financialInformationForDispatcher: 'Dispathcer`s finantial Informtation',
      financialInformationForDriver: 'Driver`s finantial Informtation',
      dispatcher: 'Dispatcher',
      broker: 'Broker',
      driver: 'Driver',
      codriver: 'Co-Driver',
      parent: 'Parent',
      truck: 'Truck',
      factoringPercent: 'Factoring Percent',
      rateType: 'Rate Type',
      driverLocalMilesRate: 'Driver Local Miles Rate',
      codriverLocalMilesRate: 'Co-Driver local miles rate',
      driverPickupMilesRate: 'Driver pickup miles rate',
      codriverPickupMilesRate: 'Co-Driver pickup miles rate',
      dispatchersToShare: 'Dispatchers to share',
      dispatchersSharedAccess: 'Dispatchers shared access',
      loadPhotosUPLOAD: 'Upload load photos',
      rateConUPLOAD: 'Upload ratecon',
      publicRateConUPLOAD: 'Upload public ratecon',
      doUPLOAD: 'Upload delivery order',
      bolUPLOAD: 'Upload bill of landing',
      podUPLOAD: 'Upload proff of delivery',
      isReturnToHQ: 'Is return to HQ',
      expenses: 'Expences',
      changeLocationsList: 'Change locations',
      updateLocationsStatus: 'Update locations status',
      updateLocationsDetails: 'Update locations details',
      locationOdometer: 'Location odometer',
      odometerUPLOAD: 'Upload odometer photo',
      truckOO: 'Owner-Operator truck',
      driverOO: 'Owner-Operator driver',
      trackingNotifications: 'Tracking notifications',
      deleteDocuments: 'Delete documents',
      deleteLoadPhotos: 'Delete load photos',
      notes: 'Notes',
      approvePendingExpenses: 'Approve pending Expenses',
      overrideSalary: 'Override salary',
      isOverrideEditLock: 'Override edit lock',
      isVerifyAllowed: 'Allow verify the load',
      setDriverOORate: 'Set Owner-Operator rate',
      editLoadWhenEditingDisabled: 'Allow edit the load when access to loads closed',
      loadsFromOthers: 'See loads from others'
    },
    Invoices: {
      Actions: 'Actions',
      MarkCommentsActions: 'Marking/Comments',
      List: 'Invoices List',
      InvoiceWithNumberExists: 'Invoice with this number alreary exist.',
      InvoiceWithNumberExistsUseNumber: 'Invoice with this number alreary exist. Please use invoice number {n}',
      ResendRequired: 'Resend is required',
      Resend: 'Resend',
      Fees: 'Fees',
      InvoicesReport: 'Invoices Report',
      FromLoadsList: 'From loads',
      ShowNotPayed: 'Show pending payments',
      BilledDirectly: 'Billed directly',
      DateRange: 'Show dates range',
      name: 'Info',
      drivers: 'Drivers',
      trucks: 'Trucks',
      Dispather: 'Dispatcher',
      scheduleNumber: 'Schedule number',
      Total: 'Total',
      FactoringFees: 'Factoring fees',
      FactoringFeesShort: 'Fact. fees',
      ReloadFiles: 'Reload files',
      ListUserSended: 'Senders',
      ListUserCreated: 'Creators',
      ListBroker: 'Brokers',
      Expected: 'Expecting',
      ReportMode: 'Report Mode',
      isFactoring: 'Enable Factoring',
      Factoring: 'Factoring',
      StatusFilter: 'Status',
      SendAllInvoicesConfirmationMsg: 'Do you want to send all selected invoices?',
      MarkAsReady: 'Mark as Ready',
      MarkAsRefine: 'Mark To Refine',
      MarkAsRefined: 'Mark as Refined',
      MarkAsSent: 'Mark As Sent',
      MarkAsVerified: 'Mark as Verified',
      MarkAsWaiting: 'Mark as Waiting',
      MarkedAsSent: 'Marked as Sent',
      MarkedAsReady: 'Marked as Ready',
      MarkedAsVerified: 'Marked as Verified',
      MarkedAsRefine: 'Marked as Refine',
      MarkedAsRefined: 'Marked as Refined',
      MarkedAsWaiting: 'Marked as Waiting',
      ResetStatus: 'Reset status',
      MarkAsNotPaid: 'Mark as Not Paid',
      Waiting: 'Waiting Further Actions',
      Paid: 'Paid',
      PartiallyPaid: 'Underpaid',
      Refine: 'Refine',
      Refined: 'Refined',
      Ready: 'Ready',
      Verified: 'Verified',
      Sent: 'Sent',
      SentBy: 'Sent by',
      Created: 'Created',
      BatchSend: 'Batch Send',
      Creator: 'Creator',
      Sender: 'Sender',
      LoadDocumentsList: 'Documents from the load',
      ListTitle: 'Invoices list',
      View: 'View invoice',
      Edit: 'Edit invoice',
      invoiceDocumentsUPLOAD: 'Documents',
      SaveDocuments: 'Save Documents',
      SelectBroker: 'Select a broker',
      Broker: 'Broker',
      BrokerTerms: 'Broker terms',
      AddEntry: 'Add entry',
      SaveConfirmationMsg: 'Do you want to save this invoice?',
      DeleteEntryConfirmationMsg: 'Do you want to delete this entry?',
      Date: 'Created',
      DueDate: 'Due Date',
      SentDate: 'Sent Date',
      Overdue: 'Overdue',
      LoadUID: 'Load',
      Number: 'Invoice Number',
      Status: 'Status',
      Amount: 'Amount',
      Terms: 'Terms',
      Received: 'Received',
      ReceivedShort: 'Recv',
      parentInvoice: 'Parent',
      previousInvoicesList: 'Previous invoices',
      createNewVersion: 'Create new version',
      NotReceivedYet: 'Not received yet',
      InvoiceDataCreated: 'Invoice created.',
      InvoiceDataCreateError: 'Invoice create error.',
      InvoiceDataSaved: 'invoice saved.',
      InvoiceDataSaveError: 'Invoice save error.',
      amountReceived: 'Received',
      amountUnderpaid: 'Underpaid',
      receivedDate: 'Payment Date',
      paymentType: 'Payment Type',
      ResetRecordPayment: 'Reset Payment',
      RecordPayment: 'Record Payment',
      RecordPayments: 'Record Payments',
      UpdatePayment: 'Update Payment',
      RecordPaymentConfirmationMsg: 'Do you want record payment?',
      ResetRecordPaymentConfirmationMsg: 'Do you want reset payment record?',
      SaveNotesConfirmationMsg: 'Do you want to save notes?',
      DeleteConfirmationMsg: 'Do you want to delete invoice?',
      RestoreConfirmationMsg: 'Do you want to restore invoice?',
      DeleteInvoice: 'Delete Invoice',
      RestoreInvoice: 'Restore Invoice',
      InvoiceDeleted: 'Invoice Deleted',
      InvoiceRestored: 'Invoice Restored',
      InvoiceDeleteError: 'Delete invoice error',
      InvoiceRestoreError: 'Restore invoice error',
      Notes: 'Notes',
      ShowNotes: 'Notes',
      HideNotes: 'Notes',
      SaveNotes: 'Save Notes',
      batchPaymentRecordTitle: 'Batch Payments Recording',
      batchPaymentRecordBtn: 'Batch Payments',
      TrucksDrivers: 'Trucks/Drivers'
    },
    Reports: {
      Information: 'Information',
      SavedReports: 'Saved Reports',
      PrintSelected: 'Print Selected',
      MarkAsChecked: 'Mark As Checked',
      MarkedAsChecked: 'Marked As Checked',
      UnMarkAsChecked: 'Un Mark As Checked',
      MarkAsVerified: 'Mark As Verified',
      MarkedAsVerified: 'Marked As Verified',
      UnMarkAsVerified: 'Un Mark As Verified',
      NoDetention: 'No Detention',
      TruckMaintenance: 'Trucks Maintenance',
      Expenses: 'Expenses',
      ExpensesShort: 'Exps',
      RatePerMileShort: 'PerMi',
      DistanceShort: 'Dist',
      Net: 'Net',
      LoadsFeed: 'Loads Feed',
      Type: 'Report Type',
      UpdateDT: 'Update',
      UpdateSelectedDT: 'Update Selected',
      ShowDeleted: 'Show deleted',
      Trucks: 'Trucks',
      Drivers: 'Drivers',
      DriversOO: 'Drivers Owner Operator',
      Dispatchers: 'Dispatchers',
      DispatchersTotals: 'Dispatchers Totals',
      DriversTotals: 'Drivers Totals',
      TrucksTotals: 'Trucks Totals',
      Bids: 'Invoices',
      Loads: 'Loads',
      Invoices: 'Invoices',
      GenerateReport: 'Generate Report',
      Paid: 'Paid',
      MarkAsPaidWholeReportConfirmation: 'Do you want to mark as paid all loads in the report?',
      CreateReportsGroup: 'Create Report',
      CreateReportsGroupDOT: 'Create DOT Report',
      EditPayedDateOnSelectedLoads: 'Edit paid date of selected loads',
      MarkAsPayedAndCreateReportsGroup: 'Mark Selected As Paid and Create Report',
      MarkSelectedAsPayed: 'Mark Selected As Paid',
      MarkSelectedAsNotPayed: 'Mark Selected As Not Paid',
      viewFullInfoForDriverOOSwitch: 'View Full Driver Owner-Operator Information',
      MarkAsPayedConfirmation: 'Are you sure you want to update Payment status for selected loads?',
      MarkAsNotPayedConfirmation: 'Are you sure you want to remove Payment status for selected loads?',
      CreateReportsGroupConfirmation: 'Are you sure you want to group loads to report?',
      ReportRestored: 'Report restored',
      ReportRestoreError: 'Report restore error',
      ReportDeleted: 'Report deleted',
      ReportDeleteError: 'Report delete error',
      MilesByMapsMoreThenByOdometer: 'Miles calculated by maps more than by odometer on {n} miles.',
      MilesByOdometerMoreThenByMaps: 'Miles calculated by odometer more than by maps on {n} miles.',
      DriverShifts: 'Driver Trips',
      DriverLoads: 'Driver Loads',
      ReportCreatedSuccessfully: 'Report created successfully',
      ReportCreatedError: 'Report creation error',
      LoadsRefines: 'Loads Verification',
      reportsFromOther: 'Reports From Others',
      trucksReport: 'Trucks Report',
      loadsReport: 'Loads Report',
      invoicesReport: 'Invoices Report',
      driversReport: 'Drivers Report',
      driversOwnerOperatorsReport: 'Drivers Owner Operators Report',
      dispatchersReport: 'Dispatchers Report',
      loadsFeedReport: 'Loads Feed Report',
      loadsVerificationReport: 'Loads Verification Report',
      trucksMaintenanceReport: 'Trucks Maintenance Report',
      expensesReport: 'Expenses Report'
    },
    GlobalSettings: {
      goMotiveApiKey: 'Go Motive API key',
      samsaraApiKey: 'Samsara API key',
      googleOAuthClientId: 'Google OAuth client ID',
      googleOAuthClientSecret: 'Google OAuth client secret key',
      notificationsEmail: 'Link email to send email notifications',
      emailForNotificationsAboutUserRegistration: 'Email to send user registration notifications',
      LoadsFeed: 'Loads Feed',
      invoiceDefaultRecipientsList: 'Invoice Default Recipients List',
      invoiceDefaultRecipientsListHint: 'Each email in new line',
      Dispatchers: 'Dispatchers',
      DriverSettings: 'Driver settings',
      DriversDefaultRates: 'Drivers default rates',
      TrucksSettings: 'Trucks settings',
      TrucksWarningsSettings: 'Notifications settings',
      TrucksIntervalsSettings: 'Interval settings',
      TruckTypesTitle: 'Truck types list',
      TrailerTypesTitle: 'Trailer types list',
      TruckMaintenanceTypesTitle: 'Truck maintenance types list',
      EnterTruckType: 'Enter truck Type',
      EnterTrailerType: 'Enter trailer Type',
      EnterTruckMaintenanceType: 'Enter truck maintenance type',
      AddTruckType: 'Add truck type',
      AddTrailerType: 'Add trailer type',
      AddMaintenanaceType: 'Add Maintenance type',
      ExpensesNamesTitle: 'Expenses names',
      EnterExpensesNames: 'Enter expenses name',
      AddExpensesNames: 'Add expenses name',
      CompensateNamesTitle: 'Compensation names',
      EnterCompensateNames: 'Enter compensation name',
      AddCompensateNames: 'Add compensation name',
      DeductionsNamesTitle: 'Deductions names',
      EnterDeductionsNames: 'Enter deduction name',
      AddDeductionsNames: 'Add deduction name',
      BonusesNamesTitle: 'Bonuses names',
      EnterBonusesNames: 'Enter bonus name',
      AddBonusesNames: 'Add bonus name',
      SavedSuccessfully: 'Settings saved successfully!',
      SavedConfirmation: 'Are you sure you want to save global settings?',
      DeleteTruckTypeConfirmation: 'Are you sure you want to delete this truck type?',
      DeleteTruckMaintenanceTypeConfirmation: 'Are you sure you want to delete this truck maintenance type?',
      DeleteExpensesNameConfirmation: 'Are you sure you want to delete this expenses name?',
      DeleteCompensateNameConfirmation: 'Are you sure you want to delete this compensation name?',
      DeleteDeductionNameConfirmation: 'Are you sure you want to delete this deduction name?',
      DeleteBonusesNameConfirmation: 'Are you sure you want to delete this bonus name?',
      Loads: 'Loads settings',
      defaultHQPlace: 'Default HQ Place',
      beforeDetentionHours: 'Hours before detention',
      detentionPerHour: 'Detention per hour',
      milesDiffHighlightThreshold: 'Miles diff threshold to highlight load in reports',
      Invoices: 'Invoices',
      InvoiceContactInfo: 'Invoice contact information',
      invoiceFactoringShipTo: 'ShipTo information for factoring',
      freeMilesToPickup: 'Free miles to pickup',
      annualInspectionIntervalInDays: 'Annual Inspection Interval',
      annualInspectionWarningWhenDaysLeft: 'Show warning of expired annual inspection when left',
      registrationCardExpiredWarningWhenDaysLeft: 'Show warning of expired registration card when left',
      insuranceExpiredWarningWhenDaysLeft: 'Show warning of expired truck insurance when left',
      oilReplacementWarningWhenMilesLeft: 'Show warning about oil change when left',
      TrackingNotifications: 'Tracking Notifications Settings',
      defaultFactoringPercent: 'Default Factoring Percent',
      percentFromOOLoadRate: 'Percent from Owner-Operator load',
      factoringPerInvoiceFee: 'Factoring fee per invoice',
      factoringPerScheduleFee: 'Factoring fee per schedule',
      DocumentsRequirements: 'Documents Requirements',
      LoadPhotosRequired: 'Is Load Photos Required?',
      RateConRequired: 'Is RateCon Required?',
      DeliveryOrderRequired: 'Is Delivery Order Required?',
      ProfOfDeliveryRequired: 'Is Prof Of Delivery Required?',
      BillOfLandingRequired: 'Is Bill Of Landing Required?',
      ExpensePaymentTypesTitle: 'Expense payment types',
      AddExpensePaymentTypes: 'Add expense payment type',
      DeleteExpensePaymentTypeConfirmation: 'Delete expense payment type?',
      EnterExpensePaymentTypeName: 'Enter expense payment type',
      InvoicePaymentTypesTitle: 'Invoice payment types',
      AddInvoicePaymentTypes: 'Add invoice payment type',
      DeleteInvoicePaymentTypeConfirmation: 'Delete invoice payment type?',
      EnterInvoicePaymentTypeNames: '',
      minLayoverToBeIncludedInRateForDispatcher: 'Min Layover to include in load rate for dispatcher',
      APIKeys: 'API Keys',
      Maps: 'Maps',
      useGoogleMaps: 'Use Google Maps',
      annualInspectionReportAddress: 'Annual Inspection Report Address',
      annualInspectionReportCityStateZip: 'Annual Inspection Report City,State,Zip'
    },
    Maps: {
      ShowTraffic: 'Show traffic'
    },
    Logs: {
      Truck: 'Truck',
      Bid: 'Bid',
      Load: 'Load',
      Invoice: 'Invoice',
      ShowAllLogs: 'Show all logs',
      FilterByActionType: 'Filter By Action Type',
      ChangesOnly: 'Changes only',
      Date: 'Date',
      User: 'User',
      Role: 'Role',
      Name: 'Type',
      Value: 'Actions',
      ListTitle: 'Logs list',
      ShowDetails: 'Show Details',
      PathChangedDetails: 'User open page',
      OpenPage: 'Open',
      GoToPath: 'Go to: {path}',
      ALL: 'All',
      ONLINE_STATUS_CHANGED: 'Online status changed',
      PATH_CHANGED: 'Page changed',
      USER_CREATED: 'User Created',
      USER_UPDATED: 'User Updated',
      SETTINGS_UPDATED: 'Settings Updated',
      PASSWORD_UPDATED: 'Password Updated',
      BID_CREATED: 'Bid Created',
      BID_CREATED_AND_PENDING: 'Bid Created And Pending',
      BID_UPDATED: 'Bid Updated',
      TRUCK_CREATED: 'Truck Created',
      TRUCK_UPDATED: 'Truck Updated',
      LOAD_CREATED: 'Load created',
      LOAD_UPDATED: 'Load updated',
      INVOICE_CREATED: 'Invoice created',
      INVOICE_UPDATED: 'Invoice updated',
      USER_IS_ONLINE: 'User went online',
      USER_IS_OFFLINE: 'User went offline',
      ACCESS_RIGHTS_ROLE_CHANGED: 'Access rights changed for role',
      ACCESS_RIGHTS_ROLE_WITH_USER_CHANGED: 'Access rights changed for users and role',
      ACCESS_RIGHTS_USER_CHANGED: 'Access rights changed for user',
      ACCESS_RIGHTS_USER_RESET: 'Access rights has been reset for user'
    },
    Shifts: {
      InformationTitle: 'Trip Information',
      Status: 'Status',
      Compensations: 'Compensations',
      CompensationsShort: 'Comp.',
      Deductions: 'Deductions',
      DeductionsShort: 'Deduct,',
      Bonuses: 'Bonus',
      DriversDetention: 'Detention',
      DriversDetentionMinutes: 'Drivers Detention Mins.',
      DriversDetentionRate: 'Drivers Detention Rate',
      Load: 'Load',
      Dispatcher: 'Dispatcher',
      DispatcherShort: 'Disp.',
      PickupLocation: 'Pickup',
      DeliveryLocation: 'Delivery',
      Salary: 'Salary',
      ToCompensate: 'Compensate',
      LoadsInShift: 'Loads In Trip',
      CurrentInProgress: 'Now Active Is',
      Earned: 'Earned',
      ContinueShift: 'Continue Trip',
      From: 'from',
      LocationReadyToStart: 'Ready to start',
      LocationOnTheWay: 'Started',
      LocationOnSite: 'On site',
      LocationDoneAndWaining: 'Done and waiting approval',
      LocationNotDoneAndWaining: 'Not done and waiting approval',
      LocationDoneAndCanContinue: 'Done and can be continued',
      LocationReturnToHQAvailable: 'Return to Home Parking Available',
      CurrentShift: 'Current Trip',
      List: 'Trips list',
      LocationDetails: 'Location Details',
      LoadDocuments: 'Load #{n} documents',
      LoadExpenses: 'Load #{n} expenses',
      Rate: 'Rate By Miles',
      RatePerMile: 'Rate Per Mile',
      Driver: 'Driver',
      Trucks: 'Trucks',
      Truck: 'Truck',
      Shift: 'Trip',
      InProgress: 'In Progress',
      Finished: 'Finished',
      Started: 'Started',
      Ended: 'Ended',
      Duration: 'Duration',
      Distance: 'Miles Driven',
      DistanceByMaps: 'Miles By Maps',
      DistanceByOdometer: 'Miles By Odometer',
      StartNewShift: 'Starting trip',
      FinishingShift: 'Finishing trip',
      LeavingTruck: 'Leaving truck',
      SelectDriver: 'SelectDriver',
      ShiftNote: 'Note (optional)',
      OdometerOnStartPhoto: 'Odometer On Start Photo',
      OdometerOnEndPhoto: 'Odometer On End Photo',
      UploadOdometer: 'Upload Odometer',
      StartShift: 'Start New Trip',
      FinishShift: 'Finish Trip',
      YouAlreadyHaveOpenShift: 'You already have not finished trip.',
      Information: 'Information',
      GoToLoad: 'Go to load',
      GoToLoadDocuments: 'Documents',
      GoToLoadExpenses: 'Expenses',
      ShowLoadInformation: 'Show Load Information',
      StatusFilter: 'Status',
      UsersFilter: 'Drivers',
      ActiveLocationsNotAvailable: 'No active loads assigned to You and selected truck.',
      ShiftLocationsNotAvailable: 'No loads linked to this trip.',
      YouNeedSelectTruck: 'Select a truck to see available locations.',
      Origin: 'Origin',
      Destination: 'Destination',
      Finish: 'Finish',
      LeaveTruck: 'Leave truck',
      LeaveTruckName: 'Leave {value}',
      SelectTruckName: 'Select {value}',
      selectTruck: 'Select truck',
      AddTruck: 'Add Truck',
      NeedToCreateShift: 'You need to start a trip to interact with the load.',
      OdometerStart: 'Odometer Start',
      OdometerEnd: 'Odometer End',
      FinishingTrimConfirm: 'Finish the trip?',
      LeavingTruckConfirm: 'Leave the truck?',
      ReturnToHQConfirmMsg: 'Activate return to the home parking from selected location?',
      NotAllStatusesAvailableMsg: 'To finsih the trip all locations must be done.',
      NeedToChangeTruck: 'You need to change a trip truck to {value}.',
      NeedToSelectTruck: 'You need to select {value} as a trip truck.',
      InteractThruShift: 'You can interact with the load on the trip page.',
      ToShift: 'To Trip',
      CurrentTruck: 'Truck',
      TrucksTrips: 'Trucks Trips',
      TruckTrips: '{truckName} Trips',
      DriversTrips: 'Drivers Trips',
      DriverTrips: '{driverName} Trips',
      TrucksInShift: 'Trucks in trip',
      ShowFinishedLocations: 'Show finished',
      HideFinishedLocations: 'Hide finished',
      ByShift: 'by Trip',
      ByMap: 'by Map',
      ByLoad: 'by Load'
    },
    Chats: {
      Chat: 'Chat',
      ThreadList: 'Rooms list',
      AddThread: 'Create Room',
      CreateChat: 'Create Chat',
      AddUser: 'Chat with user',
      AddChatTitle: 'New Chat',
      AddThreadTitle: 'New Room',
      EditThreadTitle: 'Edit Room',
      CreateThread: 'Create Room',
      UpdateThread: 'Save',
      ThreadName: 'Room Name',
      MessageEditing: 'Message editing',
      MessageQuote: 'Message quote',
      RemoveThreadMsg: 'Remove room?',
      UpdateThreadMsg: 'Update room?',
      RemoveMessageMsg: 'Remove message?',
    },
    LoadsFeed: {
      bidsFromOther: 'Show if load already have bids from other users',
      bidsRateFromOther: 'Show rate of bids from other users',
      SwitchToSingle: 'To Single Filter',
      SwitchToMulti: 'To Multi Filter',
      FilterByAll: 'Filter By All',
      PickupDate: 'Pickup Date',
      DeliveryDate: 'Delivery Date',
      BiddingPostedLoad: 'Bidding',
      SuggestedRate: 'Suggested Rate',
      SendBid: 'Send Bid',
      Title: 'Possible Loads List',
      Created: 'Posted',
      Pickup: 'Pickup',
      Delivery: 'Delivery',
      Miles: 'Miles',
      Pieces: 'Pieces',
      Weight: 'Weight',
      Dims: 'Dims',
      Notes: 'Notes',
      TruckSize: 'Truck Size',
      Expires: 'Expires',
      CallPhone: 'Call Phone',
      LoadType: 'Load Type',
      ListAddresses: 'Address',
      ListState: 'State',
      ListZip: 'Zip',
      ListTruckType: 'Truck Size',
      ListLoadType: 'Load Type',
      ListBroker: 'Broker',
      PickupFilter: 'Filter Pickup',
      DeliveryFilter: 'Filter Delivery',
      FullMessage: 'Full Message',
      WithInRadius: 'With-In Miles',
      PickupTruck: 'Pickup Truck',
      CreateInNewTab: 'Create in new tab',
      UseTruckLocation: 'Use Truck Location',
      UseMyLocation: 'Use My Location',
      HideExpired: 'Hide Expired',
      AddFilter: 'Add Filter'
    },
    Documents: {
      Created: 'Created',
      User: 'User',
      Type: 'Type',
      Name: 'Name',
      Size: 'Size',
      Linked: 'Linked To'
    },
    Import: {
      ExpenseWasImported: 'Expense was imported',
      ImportSuccess: 'Import Successfull',
      NoLoadWasSelected: 'The load was not selected'
    },
    Companies: {
      ManageAll: "Manage All",
      SaveSettingsConfirmMsg: 'Save Company?',
      ToastCompanyCreated: 'Company has beed created',
      ToastCompanySaved: 'Company settings saved',
      LoadsEasyModeDefault: 'Loads easy mode by default'
    }
  },
  'ru-RU': {
    More: 'Еще',
    copiedToClipboard: 'Cкопировано в буфер обмена',
    All: 'Все',
    moveUp: 'Переместить вверх',
    moveDown: 'Переместить вниз',
    in: 'в',
    or: 'или',
    and: 'и',
    Continue: 'Продолжить',
    ContinueToNextLocation: 'К следующей локации',
    inchShort: 'дюйм.',
    feetShort: 'фут.',
    poundShort: 'фунт.',
    milesShort: 'миль',
    daysShort: 'дн.',
    miles: 'миль',
    days: 'дней',
    hoursShort: 'ч.',
    minutesShort: 'м.',
    gallonsShort: 'гал.',
    mphShort: 'мл/ч',
    from: 'из',
    timeSpent: 'Распр. времени',
    fromEmail: 'от',
    toEmail: 'кому',
    to: 'k',
    ccEmail: 'CC',
    Yes: 'Да',
    No: 'Нет',
    Add: 'Доб.',
    hide: 'Спрятать',
    unhide: 'Показать',
    Total: 'Итого',
    Search: 'Поиск',
    SearchString: 'Введите текст или цифру для поиска...',
    Select: 'Выбрать',
    Refresh: 'Обновить',
    Close: 'Закрыть',
    Cancel: 'Отмена',
    Canceled: 'Отменен',
    Next: 'Вперед',
    Back: 'Назад',
    BackToList: 'К Списку',
    BackDriversToList: 'К Списку водителей',
    Version: 'Версия',
    DeleteConfirmation: 'Хотите удалить?',
    RestoreConfirmation: 'Хотите восстановить?',
    OpenNewPageTab: 'Открыть новую вкладку',
    OpenInNewTab: 'Открыть в новой вкладке',
    Open: 'Открыть',
    Edit: 'Редактировать',
    View: 'Просмотр',
    Print: 'Печать',
    ViewAs: 'Увидеть как пользователь',
    AllUsers: 'Все пользователи',
    PhoneCall: 'Позвонить',
    SendEmail: 'Отправить письмо',
    SendAll: 'Отправить все',
    SendReply: 'Отправить ответ',
    BackToOwnUser: 'Вернутся к своему пользователю',
    ViewAsMode: 'Режим просмотра',
    Activate: 'Активировать',
    Deactivate: 'Деактивировать',
    Deactivated: 'Деактивирован',
    Send: 'Отправить',
    Call: 'Позвонить',
    Save: 'Сохранить',
    SaveToPDF: 'Сохранить в PDF',
    SaveToPDFConfirmationMsg: 'Конвертация в PDF может зянять до 30 секунд и зависит от размера изображения. Новый файл будет добавлен рядом с исходным изображением. Продолжить?',
    Create: 'Создать',
    Creating: 'Создание',
    UserLabel: 'Пользователя',
    Download: 'Скачать',
    DownloadWithAllDocuments: 'Скачать c документами',
    SendViaEmail: 'Отправить в письме',
    Filters: 'Фильтры',
    Remove: 'Удалить',
    Settings: 'Настройки',
    Decrypt: 'расшифровать',
    PasswordFieldIsEmpty: 'Поле пароля пустое',
    DecryptionModalTitle: 'Введите свой пароль чтобы расшифровать данные',
    FieldDecrypted: 'Данные расшифрованы',
    FieldDecryptinError: 'Ошибка расшифровки данных',
    SaveWithUsers: 'Сохранить для роли и пользователей',
    ResetToRole: 'Сбросить до прав роли',
    SelectUser: 'Выберите пользователя',
    HideDeactivated: 'Спрятать деактивированных',
    ShowDeactivated: 'Показать деактивированных',
    FormValidationFailed: 'Не все поля заполненны правильно',
    CreateNewUserFormHeader: 'Создать нового пользователя',
    CreateNewUserWithRole: 'Создать нового {role}',
    AccessRightsHeader: 'Настройка прав доступа',
    NewUserAdded: 'Новый пользователь добавлен',
    UserAlreadyExists: 'Пользователь уже существует',
    UserSettingsUpdated: 'Настройки сохранены',
    FieldUpdated: 'Поле обновленно.',
    ChangesAreSaved: 'Изменения сохранены.',
    ChangesAreSavedWithAccessErrors: 'Изменения сохранены но есть ошибки доступа.',
    FieldAccessError: 'Ошибка доступа.',
    PersonalDataAndDocumentsHeader: 'Личные данные и документы',
    FileUploaded: 'Файл загружен.',
    AvatarUploaded: 'Аватар загружен.',
    PasswordUpdated: 'Пароль обновлен.',
    LoginForm: 'Войдите в свой аккаунт',
    Login: 'Войти',
    Logout: 'Выйти',
    AuthenticationFailed: 'Ошибка входа',
    AccessRightsChanged: 'Права доступа изменены.',
    AccessRightsChangeError: 'Ошибка при изменении прав доступа',
    OfflineModeMsg: 'Вы не в сети, все редактирование заблокировано.',
    UnknownAddress: 'Неизвестный адрес',
    PaymentType: 'Тип оплаты',
    CorporateCard: 'Корпоративная карта',
    PersonalCard: 'Личная карта',
    Cash: 'Наличные',
    CompensateType: 'Вид компенсации',
    Compensate: 'Компенсировать',
    AddToSalary: 'Доб. к запрлате',
    Fine: 'Штраф',
    Expenses: 'Расходы',
    OpenExternal: 'Открыть в новой вкладке',
    LinkedAccounts: 'Связанные аккаунты',
    LinkedAccountRemoved: 'Привязанный аккаунт отвязан',
    LinkGGLAccount: 'Привязать аккаунт',
    GGLAccountLinked: 'Аккаунт привязан',
    GGLAccountNotLinked: 'Аккаунт не привязан',
    LinkGGLAccountDesc: 'Привязать Google аккаунт? После согласия Вы будите перенаправленны на страницу аутентификации Google. На ней нужно войти в нужный аккаунт и дать разрешения.',
    RemoveGGLAccountDesc: 'Отвязать привязанный аккаунт {email}',
    EmailWasSent: 'Письмо отправлено.',
    EmailsWasSent: 'Письма отправлены.',
    AttachFilesFromLoad: 'Прикрепить файлы из загрузки',
    SelctedEmailAttFromTheLoad: 'Выбор файлов из загрузки',
    SendEmailWithAttTitle: 'Отправка письма с файлами',
    BatchSendEmailWithAttTitle: 'Групповая отправка письма с файлами',
    DeletedFilter: 'Фильтр удаленных',
    GetLocationError: 'Ошибка получения локации',
    OldVersionMessage: 'Пожалуйста обновите страницу. Доступна новая версия приложения.',
    ShowFullUI: 'Показывать полный интерфейс',
    Notes: 'Заметки',
    SaveNotes: 'Сохранить заметки',
    SaveNotesConfirmationMsg: 'Сохранить заметки?',
    Clone: 'Клонировать',
    Bid: 'Бид',
    Load: 'Загрузка',
    Invoice: 'Инвойс',
    OpenLoad: 'Открыть загрузку',
    GoToLoad: 'К загрузке',
    GoToShift: 'К рейсу',
    GoToInvoice: 'К инвойсу',
    Locations: 'Локации',
    StartAt: 'Начало в',
    At: 'В',
    Update: 'Обновить',
    Pending: 'В ожидании',
    PleaseConfirmYourChoice: 'Пожалуйста подтвердите свой выбор.',
    Waiting: 'Ожидание',
    ShowCalendar: 'Открыть полный календарь',
    by: '',
    DispatcherAwaySwitch: 'Если диспетчер отсутствует водител сможет продолжиь загрузку без подтверждения',
    IsAway: 'Отсутствую',
    IsActive: 'Активен',
    ShowDynamicMap: 'Показать карту',
    HideDynamicMap: 'Скрыть карту',
    ReUploadFile: 'Отправить файл снова',
    Uploading: 'Загрузка',
    RetryIn: 'Повтор через',
    Empty: 'Пусто',
    Truck: 'Грузовик',
    History: 'История',
    GoToCalendarMonth: 'Открыть месяц',
    GoToCalendarWeek: 'Открыть неделю',
    Expected: 'Ожидается',
    Received: 'Получено',
    Apply: 'Применить',
    Location: "Локация",
    OnLocation: 'На локации',
    TypeToSearch: 'Пишите для поиска',
    SelectColumns: 'Выбор столбцов',
    ByMaps: 'По карте',
    ByOdometer: 'По одометру',
    Mails: {
      TypeAll: 'Все',
      TypeNew: 'Новые',
      TypeUndead: 'Непрочитанные',
      TypeWithAnswers: 'С Ответом',
      ForwardingThread: 'Переслылка переписки',
      ForwardedSuccessfully: 'Пересылка прошла успешно',
      ForwardedError: 'Ошибка пересылки',
      Forwarding: 'Пересылка',
      Forward: 'Переслать',
      ForwardAll: 'Переслать Все',
      AddAttachments: 'Прикрепить Файлы',
      Attachments: 'Файлы',
      Event: 'Событие',
      Reply: 'Ответить',
      ReplyAll: 'Ответить всем',
      Quote: 'Показать Цитату',
      ListTitle: 'Список списем',
      Date: 'Дата',
      Status: 'Статус',
      From: 'Отправитель',
      To: 'Получатель',
      Cc: 'Копия',
      Subject: 'Тема',
      Message: 'Текст',
      groupMailsByThread: 'Обьеденить по переписке',
      ShowQuoteTooltip: 'Показать/Скрыть обрезанный текст',
      MarkAsReadTooltip: 'Пометить как прочитанное',
      MarkAsUnreadTooltip: 'Пометить как непрочитанное',
      showOnlySelfMails: 'Только мои',
      SendEmailConfirmationMsg: 'Хотете отправить письмо с {email}?',
      ListUsers: 'Список пользователей',
      StatusFilter: 'Статус',
      User: 'Пользователь',
      CreateNew: 'Новое письмо'
    },
    KeepTruckin: {
      recap: 'Сводка',
      date: 'Дата',
      off_duty: 'Вне работы',
      on_duty: 'На работе',
      sleeper: 'Спит',
      driving: 'В пути',
      waiting: 'Ожидает',
      cycle: 'Цикл',
      break: 'До перерыва',
      drive: 'Вождение',
      shift: 'Смена',
      availableTime: 'Доступное время',
      drivingToday: 'Вождение сегодня',
      drivingTomorrow: 'Вождение завтра',
      onDutyToday: 'На работе сегодня',
      onDutyTomorrow: 'На работе завтра',
      timeAvailableToday: 'Время доступное сегодня',
      timeAvailableTomorrow: 'Время доступное завтра'
    },
    KeepTruckinUserEmailNoFound: 'Email пользтвателя не найден среди пользователей keeptruckin',
    Error: 'Ошибка',
    Errors: {
      AccessDeniedAuthorizationFailed: 'Доступ закрыт. У Вас нет прав для просмотра этой страницы.',
      FileUploadErrorMsg: 'Ошибка: Файл не загружен, он будет сохранен для перезагрузки. Попробуйте позже.',
      FormIsNotValid: 'Форма не прошла валидацию'
    },
    ApplyToAllGroup: 'Применить ко всем',
    AccessRightsGroup: {
      leftMenu: 'Меню Слева',
      settings: 'Поля настроек пользователя',
      accountingManager: 'Поля Бухалтера',
      users: 'Пользователи',
      broker: 'Поля Брокера',
      dispatcher: 'Поля Диспетчера',
      driver: 'Поля Водителя',
      driverOwnerOperator: 'Поля Водителя Собственника',
      administrator: 'Поля Администратора',
      engineer: 'Поля Инженера',
      trucks: 'Грузовики',
      bids: 'Биды',
      loads: 'Загрузки',
      invoices: 'Инвойсы',
      mails: 'Письма',
      notifications: 'Нотификации',
      tasks: 'Задачи',
      documents: 'Документы',
      reports: 'Отчеты'
    },
    User: {
      Settings: {
        Update: 'Обновить',
        UpdateApp: 'Обновить приложение',
        NewVersionAvailable: 'Нажмите чтобы обновить',
        DriverAppVersion: 'Версия приложения',
        Reset: 'Сбросить',
        AccessRightForUser: 'Права доступа для {firstName} {lastName}',
        AccessRightForRole: 'Права доступа для всех {role}`ов',
        UpdateMsg: 'Для обновления приложения рекомендуется наличие быстрого и стабильного интернета. Обновить?'
      },
      Title: 'Пользователь',
      ListTitle: 'Список пользователей',
      DeactivateConfirmation: 'Вы уверенны что хотите деактивировать пользователя?',
      ActivateConfirmation: 'Вы уверенны что хотите активировать пользователя?',
      UserInformation: 'Общая информация',
      UserDocuments: 'Документы',
      UserLogs: 'Логи',
      UserReports: 'Отчеты',
      UserDocumentsAreEmpty: 'Нет документов',
      CopyRatesFromDriver: 'Скопировать у другого водителя',
      ApplyRatesToAllDriversSuccess: 'Текущие рейты применены ко всем водителям',
      ApplyOneRateToAllDriversSuccess: 'Выбранный рейт применен ко всем водителям',
      SetDefaultRates: 'Сбросить рейты',
      ApplyRatesToAllDrivers: 'Применить рейты ко всем водителям',
      ApplyOneRateToAllDrivers: 'Применить рейт ко всем водителям',
      ApplyRatesToAllDriversConfirmation: 'Вы уверенны что хотите рименить текущие рейты ко всем водителям?',
      ApplyOneRateToAllDriversConfirmation: 'Вы уверенны что хотите рименить выбранный рейт ко всем водителям?',
      MergingBrokersConfirmation: 'Обьединив брокеров, брокер для обьединения будет деактивирован. Если у него есть почтовые ящики или менеджеры которых нет у целевого брокера, будут добавленны к целевому брокеру. Также брокер для обьединения будет заменен на целевого брокера в бидах, загрузках и инвойсах.',
      MergeBrokers: 'Обьединить брокеров',
      SelectFromBroker: 'Выберите брокера для обьедиения',
      SelectToBroker: 'Выберите целевого брокера',
      NoUser: 'Нет пользователя',
      UserDeactivated: 'Пользователь {user} деактивирован.',
      UserActivated: 'Пользователь {user} активирован.',
      WorkHours: 'Часы работы',
      Page: 'Страница пользователя',
      MyPage: 'Моя страница',
      Expired: 'Истек',
      DaysLeft: 'Осталось {n} д.',
      MilesLeft: 'Осталось {n} миль',
      DaysOverdue: 'Просрочено на {n} д.',
      MilesOverdue: 'Просрочено на {n} миль',
      OnWork: 'На работе',
      OffWork: 'Не на работе',
      TruckChangeDriverTypeConfirmationMsg: 'Выберите роль водителя',
      SetAsDriver: 'Роль Водителя',
      SetAsCoDriver: 'Роль Напарника',
      SelectTruck: 'Выберите грузовик',
      Driver: 'Водитель',
      CoDriver: 'Напарник',
      RateUpdated: 'Рейт обновлен',
      SoloRate: 'Соло рейт',
      TeamRate: 'Ком. рейт',
      DriverCurrentSoloRate: 'Текущий соло рейт',
      DriverCurrentTeamRate: 'Текущий командный рейт',
      DriverSetRateAsCurrentSolo: 'Сделать текущим Solo рейтом',
      DriverSetRateAsCurrentTeam: 'Сделать текущим Team рейтом',
      Fields: {
        driverSwitchToFullUI: 'Переключатель на полный интерфейс',
        activity: 'Активность',
        role: 'Роль',
        email: 'Email',
        firstName: 'Имя',
        lastName: 'Фамилия',
        password: 'Пароль',
        avatarUPLOAD: 'Аватар',
        paymentDetails: 'Данные для оплаты',
        paymentDetailsUPLOAD: 'Документы с данными для оплаты',
        agreementUPLOAD: 'Договор',
        passportUPLOAD: 'Паспорт',
        SSN: 'SSN или ИНН',
        phoneNumber: 'Номер телефона',
        resumeUPLOAD: 'CV',
        W2FormUPLOAD: 'Форма W2',
        W9FormUPLOAD: 'Форма W9',
        driverDrivingLicense: 'Водительские права #',
        driverDrivingLicenseValidDATE: 'Водительские права действительны до',
        driverDrivingLicenseUPLOAD: 'Водительские права',
        driverMedicalCardValidDATE: 'Медицинская карта действительна до',
        driverMedicalCardUPLOAD: 'Медицинская карта',
        driverWorkPermitOrGreenCardUPLOAD: 'Разрешение на работу или Грин карта',
        driverSSN: 'Номер соц. страхования',
        driverRates: 'Рэйты водителя',
        driverDriverAgreementUPLOAD: 'Водительский договор',
        driverTruck: 'Грузовик',
        driverOwnerOperatorSWITCH: 'Водитель собственник',
        driverOwnerOperatorPercent: 'Процент оплаты от инвойса',
        driverOwnerOperatorDetention: 'Размер Detention в час',
        driverOwnerOperatorIENUPLOAD: 'IEN',
        driverOwnerOperatorInsuranceUPLOAD: 'Стаховка',
        driverOwnerOperatorAuthorityFormUSDOTUPLOAD: 'USDOT',
        driverOwnerOperatorAuthorityFormMCUPLOAD: 'MCU',
        driverOwnerOperatorAuthorityFormCAUPLOAD: 'CAU',
        driverOwnerOperatorW9FormUPLOAD: 'W9',
        driverOwnerOperatorStatementOfInformationCorporationUPLOAD: 'Statement Of Information Corporation',
        driverOwnerOperatorNameOfBank: 'Название банка',
        driverOwnerOperatorBusinessName: 'Бизнесс Имя',
        driverOwnerOperatorAddress: 'Адрес',
        driverOwnerOperatorAccount: 'Аккаунт',
        driverOwnerOperatorRouting: 'Routing',
        driverOwnerOperatorAgreementUPLOAD: 'Договор',
        dispatcherTitle: 'Должность',
        dispatcherEmail: 'Контактный email',
        dispatcherEmailTemplate: 'Email шаблон',
        dispatcherEmailSubjectTemplate: 'Email шаблон для темы',
        dispatcherEmailSignature: 'Email Подпись',
        dispatcherCompanyLogoUPLOAD: 'Логотип компании',
        dispatcherPaymentPercent: 'Процент от платежа',
        dispatcherPaymentPercentWithOO: 'Процент от платежа c Owner-Operator',
        dispatcherPaymentPercentFromBonus: 'Процент от бонуса',
        dispatcherPaymentPercentForPartialLoad: 'Процент за partial load',
        dispatcherPaymentPercentForLTLLoad: 'Процент за LTL Load',
        dispatcherPaymentPercentForReturnLoad: 'Процент за Return Load',
        dispatcherWorkHoursStart: 'Время начала работы',
        dispatcherWorkHoursEnd: 'Время окончания работы',
        dispatcherOwnerOperatorBidAutoSend: 'Водители собственники биды которых отправляются автоматически',
        accountingManagerTitle: 'Должность',
        accountingManagerEmail: 'Электронная почта',
        accountingManagerEmailSignature: 'Email подпись',
        accountingManagerCompanyLogoUPLOAD: 'Логотип компании',
        accountingManagerWorkHoursStart: 'Время начала работы',
        accountingManagerWorkHoursEnd: 'Время окончания работы',
        brokerManagersJSON: 'Список мэнеджеров',
        brokerName: 'Название',
        brokerEmails: 'Электронная почта',
        brokerBillTo: 'Bill To',
        brokerIEN: 'IEN Брокера',
        brokerPaymentNotes: 'Заметки по брокеру',
        brokerSetupPackageUPLOAD: 'Пакет документов',
        brokerDaysToPay: 'Дней до оплаты',
        brokerWithFactoringSWITCH: 'Факторинг',
        brokerFactoringPercent: 'Процент факторинга',
        brokerFactoringPaymentDetails: 'Детали для оплаты факторинга',
        brokerCompanyName: 'Название компании',
        brokerContactPerson: 'Контактное лицо',
        statusIsActive: 'Деактивирован или нет',
        statusIsLoggedIn: 'На сайте или нет',
        statusCreated: 'Дата создания',
        statusLastLogin: 'Последний вход',
        statusLastActivity: 'Последняя активность',
        isOpenItemsInModal: 'Открывать загрузки, инвойсы и грузовики в модальном окне',
        hiddenTrucks: 'Скрытые грузовики',
        linkedToDispatcher: 'Привязать к диспетчеру'
      }
    },
    Roles: {
      SuperAdministrator: 'Супер Админ',
      Administrator: 'Админ',
      Dispatcher: 'Диспетчер',
      Broker: 'Брокер',
      Driver: 'Водитель',
      DriverOwnerOperator: 'Водитель Собственник',
      Engineer: 'Инженер',
      Assistant: 'Ассистент',
      AccountingManager: 'Бухалтер'
    },
    CreateUser: {
      SelectRole: 'Выберите роль',
      PickAnAvatar: 'Выберите аватар',
      avatarUPLOAD: 'Аватар',
      FirstName: 'Имя',
      LastName: 'Фамилия',
      FullName: 'Полное имя',
      EMail: 'E-Mail адрес',
      Password: 'Пароль',
      PasswordRepeat: 'Пароль повторно',
      Phone: 'Телефон',
      PhoneWork: 'Рабочий Телефон',
      PhoneCell: 'Мобильный Телефон',
      PhoneHome: 'Домашний Телефон',
      PhoneFax: 'Факс',
      PhoneAdditional: 'Дополнительны Телефон',
      Submit: 'Создать',
      Clear: 'Очистить',
      GeneratePassword: 'Генерировать пароль',
      UpdatePassword: 'Обновить пароль',
      SavePassword: 'Сохранить пароль',
      RemoveRateConfirmMsg: 'Вы уверены что хотите удалить этот рейт?',
      RemoveFileConfirmMsg: 'Вы уверены что хотите удалить этот файл?',
      SaveRoleAccessRightsConfirmMsg: 'Вы уверены что хотите сохранить права роли?',
      SaveRoleWithUsersAccessRightsConfirmMsg: 'Вы уверены что хотите сохранить права роли? Эти изменения будут применены ко всем пользователям с ролью {role}.',
      SaveUserAccessRightsConfirmMsg: 'Вы уверены что хотите сохранить права пользователя? Эти изменения будут применены только к выбраному пользователю.',
      ResetToRoleRightsConfirmMsg: 'Вы уверены что хотите сбросить права пользователя? Права {user} будут равны правам роли {role}.',
      SaveSettingsConfirmMsg: 'Вы уверены что хотите сохранить?',
      AddRate: 'Добавить рейт',
      NewRate: 'Новый рейт',
      UpdateRate: 'Обновить рейт',
      RateType: 'Тип рэйта',
      UpdateAllRates: 'Обновить для всех водителей',
      RemoveManagerConfirmMsg: 'Вы уверены что хотите удалить этого мэнеджера?',
      AddManager: 'Добавить менеджера',
      UpdateManager: 'Обновить',
      AddFile: 'Добавить файл',
      PressToSelectFile: 'Выбрать файл',
      Validators: {
        PasswordMustHaveAtLeastSymbols: 'Пароль должен быть не меньше {n} символов',
        PasswordIsRequired: 'Пароль не указан',
        PasswordsDoesNotMatch: 'Пароли не совпадают'
      },
      BasicInformation: 'Базовая информация',
      DriverInformation: 'Данные водителя',
      DriverOwnerOperatorInformation: 'Данные водителя владельца',
      DispatcherInformation: 'Данные диспетчера',
      AccountingManagerInformation: 'Данные бухалтера',
      BrokerInformation: 'Данные брокера',
      AdministratorInformation: 'Данные aдминистратора',
      EngineerInformation: 'Данные инженера'
    },
    Validators: {
      SelectionIsRequired: 'Выбор обязателен',
      FieldIsRequired: 'Поле обязательно',
      MustBeValidEMail: 'E-Mail не корректен',
      EMailIsRequired: 'E-Mail не указан',
      EmailAlreadyUsed: 'E-Mail уже используется',
      DriverRateTypeRule: 'Название рейта должно содержать слово "solo" или "team"'
    },
    LeftMenu: {
      Documents: 'Документы',
      LoadsCalendar: 'Календарь грузов',
      Tasks: 'Задачи',
      CreateTasks: 'Создать задачу',
      Notifications: 'Уведомления',
      CreateNotifications: 'Создать уведомление',
      Reports: 'Отчеты',
      Mails: 'Письма',
      Settings: 'Настройки',
      Languages: 'Языки',
      InvertColors: 'Темный режим',
      AccessRights: 'Авторизация',
      Logs: 'Логи',
      GlobalSettings: 'Общие настройки',
      Dashboard: 'Панель информации',
      Users: 'Пользователи',
      CreateUser: 'Добавить пользователя',
      AllUsersList: 'Пользователи',
      all: 'Все пользователи',
      SuperAdministrator: 'Супер Администратор',
      Administrator: 'Администраторы',
      Dispatcher: 'Диспетчеры',
      Assistant: 'Ассистенты',
      Broker: 'Брокеры',
      Driver: 'Водители',
      DriverOwnerOperator: 'Водители собственники',
      Engineer: 'Инженеры',
      AccountingManager: 'Бухгалтеры',
      Search: 'Поиск',
      Charts: 'Графики',
      CreateChart: 'Создание графиков',
      Trucks: 'Грузовики',
      CreateTruck: 'Добавление грузовика',
      TrucksCalendar: 'Расписание грузовиков',
      Bids: 'Биды',
      CreateBid: 'Создание бида',
      Loads: 'Загрузки',
      CreateLoad: 'Создание загрузки',
      Invoices: 'Инвойсы',
      CreateInvoice: 'Создание инвойса',
      Shifts: 'Рейсы',
      CreateShifts: 'Создать рейс',
      ShiftsByDriver: 'По Водителям',
      ShiftsByTrucks: 'По Грузовикам',
      Chat: 'Чат',
      Chats: 'Чаты',
      CreateChats: 'Создание чатов',
      PostToChats: 'Посты в чаты',
      LoadsFeed: 'Возможные загрузки',
    },
    PageTabs: {
      CloseTabConfirmationMessage: 'Хотите закрыть вкладку?'
    },
    Tooltips: {
      UsersHOS: 'Открыть часы водителя',
      UsersMergeBroker: 'Объединить брокера',
      UsersListViewAs: 'Увидеть сайт с правами пользователя.',
      UsersListEdit: 'Редактирование данных пользователя.',
      UsersListDeactivate: 'Активация / Деактивация пользователя.',
      UsersListUserPage: 'Открыть страницу пользователя'
    },
    NoSearchResults: 'Нет Результатов',
    Charts: {
      CreateNewConfirmationMsg: 'Хотите создвать график?',
      SaveConfirmationMsg: 'Хотите сохранить график?',
      CreateChartTitle: 'Создание графика',
      EditChartTitle: 'Редактирование графика',
      List: 'Список графиков',
      CreateChart: 'Cоздать новый график',
      ChartSaved: 'График сохранен!',
      ChartTitleIsEmpty: 'Заполните название графика чтобы сохранить.',
      RemoveConfirmMsg: 'Уверены что хотите удалить этот график?',
      Years: 'Лет',
      Month: 'Месяцев',
      Days: 'Дней',
      Hours: 'Часов',
      COUNT: 'Кол-во',
      SUM: 'Сумма',
      AVG: 'Средн.',
      MIN: 'Мин.',
      MAX: 'Макс.',
      Interval: 'Интервал'
    },
    Dashboards: {
      SelectDashboardType: 'Выберите тип',
      SelectChart: 'Выберите график',
      TextTypeTextareaHint: 'Введите текст',
      AddPanel: 'Добавить панель',
      RemoveConfirmMsg: 'Уверены что хотите удалить эту панель?'
    },
    Trucks: {
      Status: 'Статус',
      LastFinishedLocation: 'Последняя завершенная',
      OilChangeSavedMsg: 'Данные о замене масла сохранены',
      UpdateOilChange: 'Новая замена масла',
      Available: 'Свободные Грузовики',
      ShowHiddenTrucks: 'Показать спрятанные траки',
      Information: 'Информация',
      Documents: 'Документы',
      Report: 'Отчет',
      OilReplacementHistory: 'История замены масла',
      Properties: 'Параметры',
      Oil: 'Масло',
      NoTruck: 'Нет грузовика',
      LocationTooltip: 'Грузовик находится на {data}',
      SpeedTooltip: 'Текущая скорость {data} мл/ч.',
      EngineHoursTooltip: 'Часы двигателя {data}',
      OdometerTooltip: 'Одометр {data} миль',
      FuelTooltip: 'Топливо {data} гал.',
      FuelPercentTooltip: 'Топливо {data} %.',
      Page: 'Страница грузовика',
      dimensions: 'Габариты ДхШхВ',
      dimensionsDetail: 'Габариты @door ШхВ',
      ShowTruckDetails: 'Показать детали',
      ShowTrucksImages: 'Показывать фото грузовика',
      NewTruckAdded: 'Новый грузовик добавлен.',
      TruckDataSaved: 'Данные грузовика сохранены.',
      TruckDataSaveError: 'Обшибка при обновлении данных.',
      ErrorWhenAddingTheTruck: 'Обшибка при добавлении грузовика.',
      Drivers: 'Водители',
      NoDrivers: 'Водитель не выбран',
      NoCoDrivers: 'Напарник не выбран',
      Driver: 'Водитель',
      CoDriver: 'Напарник',
      DriverOO: 'Cобственник',
      Create: 'Добавить грузовик',
      ChangeConfirmation: 'Уверенны что хотите поменять грузовик?',
      CreateConfirmation: 'Уверенны что хотите добавить грузовик?',
      SaveConfirmation: 'Уверенны что хотите сохранить изменения?',
      DeleteConfirmation: 'Уверенны что хотите удалить грузовик?',
      Statuses: {
        Active: 'Активен',
        Abandoned: 'Оставленный',
        Maintenance: 'Тех. обслуживание',
        Deactivated: 'Деактивирован',
        Available: 'Доступен',
        NotAvailableUntil: 'Не дост. до'
      },
      Notifications: {
        maintenanceExpiredSoon: 'Грузовику скоро потребуется обслуживания {type}. Осталось {value} миль.',
        maintenanceExpired: 'Грузовику требуется {type}. Превышение {value} миль.',
        oilReplacementExpiredSoon: 'Грузовику скоро потребуется замена масла. Осталось {n} миль.',
        oilReplacementExpired: 'Грузовику требуется замена масла. Превышение {n} миль.',
        annualInspectionExpiredSoon: 'Скоро время регулярной инспекции. Осталось {n} дней.',
        annualInspectionExpired: 'Требуется регулярная инспекция. Превышение {n} дней.',
        registrationCardExpiredSoon: 'Скоро заканчивается карта регистрации грузовика. Осталось {n} дней.',
        registrationCardExpired: 'Закончилась карта регистрации грузовика. Превышение {n} дней.',
        insuranceExpiredExpiredSoon: 'Скоро заканчивается страховка грузовика. Осталось {n} дней.',
        insuranceExpiredExpired: 'Закончилась страховка грузовика. Превышение {n} дней.'
      },
      Fields: {
        truckAvatarUPLOAD: 'Фотография грузовика',
        name: 'Название',
        licensePlate: 'Номер',
        year: 'Год',
        model: 'Модель',
        number: 'Номер',
        owner: 'Владелец',
        tireSize: 'Размер колеса',
        truckFleetNumber: 'Номер трака',
        vin: 'VIN',
        registrationCardExpiredDATE: 'Дата окончания регистрации',
        insuranceExpiredDATE: 'Дата окончания страховки',
        lastAnnualInspectionDATE: 'Последняя инспекция',
        lastOilReplacementDATE: 'Дата последней замены масла',
        lastOilReplacementMiles: 'Миль при последней замене масла',
        lastOilReplacementUser: 'Добавлено пользователем',
        nextOilReplacementIntervalMiles: 'Интервал между заменой масла',
        oilReplacementAfterMiles: 'Замена масла после',
        length: 'Длина',
        dimensionsL: 'Внутр. размер L',
        dimensionsW: 'Внутр. размер W',
        dimensionsH: 'Внутр. размер H',
        dimensionsDetailL: 'По рамке L',
        dimensionsDetailW: 'По рамке W',
        dimensionsDetailH: 'По рамке H',
        liftGate: 'Lift gate',
        palletJack: 'Pallet jack',
        airRide: 'Air ride',
        iccBars: 'ICC bars',
        reefer: 'Reefer',
        isVented: 'Vented',
        isSwingDoors: 'SwingDoors',
        isRollupDoors: 'RollupDoors',
        sleeperSpace: 'Спальное место',
        maxLoadWeight: 'Макс. вес груза',
        eTrackQuantity: 'Кол-во eTrack',
        loadBarsQuantity: 'Кол-во Load bars',
        strapsQuantity: 'Кол-во Straps',
        blanketsQuantity: 'Кол-во Blankets',
        emptyScaleWeight: 'Вес без груза',
        emptyScaleTicketUPLOAD: 'Вес без груза документ',
        truckInsuranceUPLOAD: 'Страховка грузовика',
        truckRegistrationUPLOAD: 'Регистрация',
        annualInspectionUPLOAD: 'Инспекция',
        miles: 'Миль на одометре',
        location: 'Локация',
        activity: 'Активность',
        status: 'Статус',
        type: 'Тип',
        notes: 'Заметки',
        driver: 'Водитель',
        codriver: 'Напарник',
        driverOO: 'Водитель собственник',
        syncDriversWithKT: 'Синхронизировать водителя грузовика с KeepTruckin',
        syncDriversWithKTShort: 'Синх. с KT',
        distanceBetween: 'Растояние между e-tracks'
      }
    },
    Bids: {
      Planned: 'Запланированна',
      plannedDATE: 'Запланированная дата',
      brokerNameOrEmailUnavailable: 'Название и email нового брокера отсутсвуют.',
      AddEmailToSelectedBroker: 'Добавить email к выбранному брокеру',
      CreateLoad: 'Создать загрузку',
      Bid: 'Bid',
      UseAdvTruckDimensions: 'Использовать дополнительные размеры грузовика',
      ResetDraft: 'Сбросить черновик',
      UseGmailSignature: 'Добавить подпись',
      SelectSender: 'Выбрать отправителя',
      SendEmailAfterCreating: 'Отправить письмо после создания бида',
      Edit: 'Редактировать',
      SameRouteRates: 'Рэйты по выбранному пути',
      TrafficBestGuess: 'Лучшее предположение',
      TrafficOptimistic: 'Оптимистичное предположение',
      TrafficpPessimistic: 'Пессиместичное предположение',
      Source: 'Источник',
      SaveConfirmationMsg: 'Сохранить бид?',
      CreateNewConfirmationMsg: 'Отправить бид?',
      SendEmailConfirmationMsg: 'Хотете отправить письмо с {email}?',
      Create: 'Создать бид',
      Save: 'Сохранить бид',
      BidDataCreated: 'Бид создан.',
      BidDataCreateError: 'Ошибка при создании бида.',
      BidDataSaved: 'Бид сохранен.',
      BidDataSaveError: 'Ошибка при сохранении бида.',
      EnterLocation: 'Введите локацию',
      BidsCreateLoad: 'Создать загрузку',
      SelectDispatcher: 'Выберите Диспетчера',
      AddTruckLocation: 'Грузовик находится по адресу {addr}. Добавить его локацию?',
      EmailRecipient: 'Получатель',
      EmailCC: 'Копия',
      EmailSubject: 'Тема письма',
      EmailBody: 'Текст письма',
      EmailCustomText: 'Опциональный текст',
      UpdateEmailBody: 'Обновить текст письма',
      SaveAndSend: 'Сохранить и Отправить',
      CreateAndSend: 'Создать и Отправить',
      CreateAndAddNew: 'Создать и добавить еще',
      CreateAndSendNew: 'Создать новый с текущими данными',
      SelectBroker: 'Выберите брокера',
      SelectManager: 'Выберите менеджера',
      ListTitle: 'Список бидов',
      ListStatus: 'Статус',
      ListCreated: 'Создан',
      ListUpdated: 'Обновлен',
      ListDispatcher: 'Диспетчер',
      ListCreatorDispatcher: 'Создатель / Диспетчер',
      ListLoad: 'Загрузка',
      ListManager: 'Менеджер',
      ListSender: 'Отправитель',
      ListRecipient: 'Получатель',
      ListInCopy: 'Копия',
      ListTruck: 'Грузовик',
      ListBroker: 'Брокер',
      ListTruckType: 'Тип трака',
      ListSubject: 'Путь',
      ListDistance: 'Расстояние',
      ListDuration: 'Продолжительность',
      ListDistanceToPickup: 'Расстояние до пикапа',
      ListRatePerMile: 'Рэйт за милю',
      ListRate: 'Рэйт',
      DELETED: 'УДАЛЕН',
      RestoreBid: 'Восстановить бид',
      DeleteBid: 'Удалить бид',
      BidDeleteConfirmationMsg: 'Хотите удалить бид?',
      BidRestoreConfirmationMsg: 'Хотите восстановить бид?',
      BidDeleted: 'Бид удален.',
      BidDeleteError: 'Ошибка при удалении бида.',
      BidRestored: 'Бид восстановлен.',
      BidRestoreError: 'Ошибка при восстановлении бида.',
      AddHQLocation: 'Добавить локацию домашней парковки',
      FeedMessageText: 'Текст сообщения'
    },
    Loads: {
      AfterEditingMarkAsRefined: 'После редактирования отметьте как исправленно',
      approveLocation: 'Подтвердить локацию',
      ShowHideAllLocations: 'Показать/Скрыть все локации',
      TruckViewMode: 'Локации ограниченны по траку',
      CurrentLoads: 'Текущие загрузки',
      NoTruckNextLoad: 'Нет дальнейшей загрузки',
      AddReturnToHQConfirmMsg: 'Добавить возврат на парковку?',
      EnterExpensePlaceholder: 'Введите название или выберите',
      SetCurrentLocationFromSelectedTruckLocationConfirmMsg: 'Установить текущюю локацию такой же как и у выбранного грузовика?',
      LoadWaitingDispatcherApproval: 'Загрузка ожидает подтверждения от диспетчера',
      LoadWaitingToBeFinished: 'Загрузка ожидает завершения',
      Mails: 'Писем',
      MailsUnread: 'Непрочитанных писем',
      cancelNextLocationsConfirmationMessage: 'Отметить все последующие локации как отмененные?',
      MarkAsStarted: 'Отметить как стартовал',
      MarkAsOnSite: 'Отметить как на месте',
      MarkAsLoaded: 'Отметить как загружен',
      MarkAsUnLoaded: 'Отметить как выгружен',
      MarkAsDone: 'Отметить как готово',
      MarkAsApproved: 'Отметить проверенным',
      ApproveLoadToStart: 'Разрешить старт',
      EditingUnlockRequested: 'Запрос на редактирование отправлен',
      RequestEditUnlock: 'Запросить редактирование',
      MarkAsWaitingFurtherActions: 'Ожидать будущих действий',
      NotLoadedFiles: 'Незагруженые файлы',
      TruckDrivers: 'Грузов./Вод.',
      EditingIsLocked: 'Редактирование заблокированно',
      EditingIsLockedForAll: 'Редактирование заблокированно для всех',
      EditingIsLockedForDrivers: 'Редактирование заблокированно для водителей',
      EditingIsLockedForDispatchers: 'Редактирование заблокированно для диспетчеров',
      EditingIsUnlocked: 'Редактирование разблокированно',
      LoadWithNumberExist: 'Загрузка с таким номером уже существует',
      Cancel: 'Отменить',
      Canceled: 'Отменена',
      Miles: 'Миль',
      Info: 'Инфо',
      OverrideEditLock: 'Разрешения редактирования',
      DroveIn: 'Заехал',
      DroveOut: 'Выехал',
      DocumentsNotMeetRequirements: 'Отсутствуют документы нужные для создания инвойса',
      MarkAsRefined: 'Отметить как исправленно',
      MarkAsRefine: 'Отметить для исправления',
      MarkAsVerified: 'Отметить как проверенно',
      UndoVerified: 'Отмена проверки',
      Verified: 'Проверенно',
      Refine: 'Исправить',
      Refined: 'Исправлено',
      RefineNotes: 'Комментарий для уточнения',
      RefinedNotes: 'Комментарий исправлений',
      RequestEditUnlockNotes: 'Комментарий для завроса на редактирование',
      Finished: 'ЗАВЕРШЕНА',
      PickupAt: 'Пикап в',
      DeliveryAt: 'Доставка в',
      ParkingAt: 'Парковка в',
      ExchangeAt: 'Обмен в',
      ReturnAt: 'Возврат в',
      TruckIsNotSelected: 'Грузовик не выбран',
      DriverIsNotSelected: 'Водитель не выбран',
      LinkedLoad: 'Связанная загрузка',
      StartedEnded: 'Начало / Конец',
      Locations: 'Локации',
      DispatcherBroker: 'Диспетчер / Брокер',
      OdometerPhotoIsNotUploaded: 'Фото одометра должно быть загруженно перед стартом.',
      OdometerPhotoIsNotUploadedToFinish: 'Фото одометра должно быть загруженно для завершения.',
      OverrideSalaryConfirmationMsg: 'Установить указанную зарплату?',
      ReseySalaryOverrideConfirmationMsg: 'Сбросить указанную зарплату?',
      OverrideSalary: 'Указать свою зарплату',
      ExpenseFromLoad: 'Расход из груза',
      ExpensesHasPending: 'Есть ожидающие подтверждения расходы',
      DoApproveExpense: 'Подтвердить расход',
      DoResetExpense: 'Сбросить подтверждение',
      ApproveExpense: 'Подтвердить расход?',
      ResetExpense: 'Сбросить подтверждение?',
      IsAddExpenseToRate: 'Добавить в рейт',
      IsAddedExpenseToRate: 'Добавлен в рейт',
      IsCalcInDispatcherRate: 'Включить в расчет рейта Диспетчера',
      NoTruckIsSelected: 'Грузовик не выбран',
      onReturnToHQSwitchConfirmMsg: 'Пожалуйста укажите куда отправится грузовик после завершения загрузки.',
      truckChangedResetStatusMsg: 'Статус и одометр будут сброшены для локации где изменен грузовик.',
      notFinishedStatus: 'Незавершена',
      notFinish: 'Сделать незавершенной',
      notFinishConfirmationMessage: 'Вы хотите сделать текущую загрузку незавершенной?',
      finish: 'Завершить',
      finishConfirmationMessage: 'Вы хотите завершить текущую загрузку?',
      finishedWithTONUStatus: 'Завершена с TONU',
      finishWithTONU: 'Завершить с TONU',
      finishWithTONUConfirmationMessage: 'Вы хотите завершить текущую загрузку со статусом "Truck Ordered Not Used" status?',
      finishWithCancelConfirmationMessage: 'Отменить загрузку?',
      TotalWithLinkedLoadsTitle: 'Финансы по всем загрузкам',
      TotalWithLinkedLoads: 'Итого по всеми загрузкам',
      TotalIncomeWithLinkedLoads: 'Доход со всех загрузками',
      TotalExpensesWithLinkedLoads: 'Расходы по всем загрузкам',
      TotalExpenses: 'Итого расходов',
      GMapsDistance: 'GMaps дист.',
      UpdatingDistancesAndDurations: 'Расчитываются растояния и время между локациями...',
      AdditionalInfo: 'Доп. информация',
      DispatcherPercents: 'Проценты диспетчера',
      PopulateLocationSettingsDown: 'Заполнить также найстроки последующих локаций',
      ProgressSteps: 'Этапы загрузки',
      GoToNavigation: 'К Навигации',
      WaitingDispatcherApproval: 'Ожидаем подтверждения от диспетчера',
      ListEmpty: 'Список загрузок пуст',
      ListStatus: 'Статуc',
      ListTrucks: 'Грузовик',
      ListDriver: 'Водитель',
      ListDispatcher: 'Диспетчер',
      ListBroker: 'Брокер',
      NoLoadRightNow: 'Без груза',
      TruckSpeed: 'Скорость',
      TruckLocation: 'Местоположение',
      TruckOdometer: 'Одометр',
      TruckFuel: 'Топливо',
      TruckEngineHours: 'Часы двигателя',
      ResetStatuses: 'Сбросить статусы',
      NewLocationAddress: 'Добавить нувую локацию',
      LocationAddress: 'Адрес локации',
      AddSelectedLocation: 'Добавить выбранную локацию',
      AddHQLocation: 'Добавить локацию домашней парковки',
      UserTruckLocation: 'Добавить локацию грузовика',
      LoadIsFinishedCreateInvoice: 'Загрузка завершена, создать инвойс?',
      Information: 'Информация',
      Invoice: 'Инвойс',
      parentLoad: 'Основная загрузка',
      deleteLoad: 'Удалить загрузку',
      restoreLoad: 'Восстановить загрузку',
      ReturnToHQ: 'Возврат на основную парковку',
      AddReturnToHQLocation: 'Добавить возврат на парковку',
      driverReturnToHQRate: 'Рейт возвращения',
      codriverReturnToHQRate: 'Рейт возвращения',
      driverReturnToHQRatePerMile: 'Рейт возвращения за милю у водителя',
      codriverReturnToHQRatePerMile: 'Рейт возвращения за милю у напарника',
      HQAddress: 'Адрес основной парковки',
      EnterHQAddress: 'Ведите адрес основной парковки',
      FinancialData: 'Финансы',
      View: 'Открыть',
      Edit: 'Редактировать',
      Status: 'Статус',
      LoadDeleted: 'Загрузка удалена.',
      LoadDeleteError: 'Ошибка при удалении загрузки.',
      LoadRestored: 'Загрузка восстановлена.',
      LoadRestoreError: 'Ошибка при восстановлении загрузки.',
      LoadDeleteConfirmationMessage: 'Вы хотите удалить груз?',
      LoadRestoreConfirmationMessage: 'Вы хотите восстановить груз?',
      StatusUpdateConfirmationMessage: 'Вы хотите обновить статус груза?',
      StatusClearConfirmationMessage: 'Вы хотите сбросить статус груза?',
      CloneConfirmationMessage: 'При клонировании в новую загрузку будут скопированны все поля текущей загрузки кроме документов и заметок. Вы хотите клонировать текущую загрузку?',
      GoToClonedLoads: 'Открыть клонированную загрузку?',
      CloneSuccess: 'Загрузка успешно клонированна.',
      CloneError: 'Ошибка при клонировании загрузки.',
      DeleteExpenseConfirmation: 'Удалить расход?',
      SaveExpensesConfirmation: 'Сохранить расходы?',
      DriversAreEqualError: 'Водитель и напарник не могут совпадать.',
      PreviousStepNotDone: 'Предыдущий шаг не выполнен',
      Created: 'Создан',
      Updated: 'Обновлен',
      UID: 'Номер груза',
      StartPlan: 'Планируемое время старта',
      EndPlan: 'Планируемое время завершения',
      SelectDispatcher: 'Выберите Диспетчера',
      SelectDispatcherToShare: 'Поделить с диспетчерами',
      SelectBroker: 'Выберите Брокера',
      SelectTruck: 'Выберите Грузовик',
      SelectDriver: 'Выберите Водителя',
      SelectSecondDriver: 'Выберите Напарника',
      driverOwnerOperatorBonus: 'Бонус с Оwner-Operator',
      driverOwnerOperatorPercent: 'Процент Оwner-Operator`a',
      Dispatcher: 'Диспетчер',
      DispatcherPayment: 'Диспетчер заработал',
      DispatcherPaymentRTL: 'Диспетчер заработал c обрат.',
      DispatcherPaymentFromBonus: 'Диспетчер заработал от бонуса',
      Broker: 'Брокер',
      Truck: 'Грузовик',
      Driver: 'Водитель',
      Drivers: 'Водители',
      CoDriver: 'Напарник',
      DriverRate: 'Rate водителя',
      CoDriverRate: 'Rate напарникa',
      Bid: 'Бид',
      Detention: 'Detention',
      Layover: 'Layover',
      Fine: 'Fine',
      DriverAssist: 'Помощь водителя',
      Idle: 'Простой',
      Rate: 'Стоимость',
      TLRate: 'Стоимость прямой закрузки',
      RTLRate: 'Стоимость обратной загрузки',
      LoadRate: 'Стоимость загрузки',
      LoadRateOO: 'Стоимость загрузки для водителя собственника',
      RateTONU: 'Стоимость TONU',
      plannedRatePerMile: 'Запланированная стоимость за милю',
      RatePerMile: 'Стоимость за милю',
      plannedDistanceInMiles: 'Запланированные мили',
      ActualDistanceInMiles: 'Реальные мили',
      distanceInMiles: 'Общее расстояние в милях',
      durationTime: 'Продолжительность',
      FromLocation: 'Пункт отправления',
      ToLocation: 'Пункт назначения',
      Pickup: 'Загрузка',
      Delivery: 'Доставка',
      Parking: 'Парковка',
      Exchange: 'Обмен',
      isFactoring: 'Факторинг',
      isDriverOwnerOperator: 'Водитель собственник',
      isDriverAssist: 'Водитель помогал',
      isDriverAllowedReturnToHQ: 'Разрешить водителю выбрать вернуться на парковку',
      isDriverAllowedToContinue: 'Разрешить водителю продолжать загрузку',
      ReturnToHQLocation: 'Вернуться на парковку',
      isLocalLoad: 'Локальный груз',
      isPickupRate: 'Pickup рейт',
      defaultRate: 'Стандартный рейт',
      isAllMiles: 'Все мили',
      isZipToZipMiles: 'Zip to zip',
      isEmptyMiles: 'Пустые мили',
      isPickupMiles: 'Пикап мили',
      isReturnMiles: 'Возвратные мили',
      driverLocalRate: 'Локальный рейт водителя',
      codriverLocalRate: 'Локальный рейт напарника',
      driverRate: 'Рейт водителя',
      driverOORate: 'Рейт водителя собственника',
      driverOOSalaryRate: 'Зарплата водителя собственника',
      codriverRate: 'Рейт напарника',
      driverPickupRate: 'Рейт pickup водителя',
      codriverPickupRate: 'Рейт pickup напарника',
      driverZipToZipRate: 'Рейт zip to zip водителя',
      codriverZipToZipRate: 'Рейт zip to zip напарника',
      driverEmptyRate: 'Рейт empty miles водителя',
      codriverEmptyRate: 'Рейт empty miles напарника',
      driverAllMilesRate: 'Рейт all miles водителя',
      codriverAllMilesRate: 'Рейт all miles напарника',
      isDedicatedTruck: 'Зарезервированный грузовик',
      isEasyMode: 'Упрощеный режим',
      dispatcherPartialRate: 'Рэйт диспетчера за Partial load',
      dispatcherLTLRate: 'Рэйт диспетчера за LTL load',
      dispatcherReturnRate: 'Рэйт диспетчера за Return load',
      TargetTime: 'План. время',
      ActualTime: 'Факт. время',
      ActualDistance: 'Факт. раст.',
      setAppoinment: 'Детали встречи',
      unsetAppoinment: 'Убрать детали встречи',
      PickupLocation: 'Адрес загрузки',
      PickupDatetime: 'Дата и время загрузки',
      DeliveryLocation: 'Адрес доставки',
      DeliveryDatetime: 'Дата и время доставки',
      TargetLocation: 'Пункт назначения',
      RecalcTime: 'Пересчитать сроки',
      RegenShipper: 'Обновить "Shipper"',
      RegenShipTo: 'Обновить "Ship To"',
      SaveConfirmationMsg: 'Вы хотите сохранить этот груз?',
      SaveFilesConfirmationMsg: 'Вы хотите сохранить изменения?',
      ListTitle: 'Список грузов',
      LoadDataCreated: 'Груз создан.',
      LoadDataCreateError: 'Ошибка создания груза.',
      LoadDataSaved: 'Груз сохранен.',
      LoadDataSaveError: 'Ошибка сохранения груза.',
      DateFormatError: 'Ошибка в формате даты',
      EnterLocationDateTime: 'Быть на локации',
      LeftLocationDateTime: 'Покинуть локацию',
      ToLocationTarget: 'Итого',
      ToLocationLeft: 'Осталось',
      OnLocationTime: 'Время на локации',
      StandBy: 'Ожидание',
      Start: 'Стартовать',
      Started: 'Дата пикапа',
      Ended: 'Дата доставки',
      onTheWayStatus: 'В пути',
      dispatcherOnTheWayStatus: 'Можно выезжать',
      waitingDispatcherApproval: 'Ожидается разрешение диспетчера',
      dispatcherApproved: 'Разрешено диспетчером',
      onSiteStatus: 'На месте',
      onSiteToPickupStatus: 'На месте для загрузки',
      onTheWayToPickupStatus: 'На пути к загрузке',
      finishedStatus: 'Завершена',
      loadedStatus: 'Загрузил',
      notLoadedStatus: 'Не загрузил',
      unloadedStatus: 'Выгрузил',
      notUnloadedStatus: 'Не выгрузил',
      exchangedStatus: 'Обменялся',
      returnToHQStatus: 'Вернулся',
      parkedStatus: 'Припарковался',
      onSiteToDeliveryStatus: 'На месте для доставки',
      onTheWayToDeliveryStatus: 'На пути к доставке',
      onParkingStatus: 'На парковке',
      onTheWayToParkingStatus: 'На пути к парковке',
      onExchangeStatus: 'На месте обмена',
      onTheWayToExchangeStatus: 'На пути к месту обмена',
      uploadDocuments: 'Документы',
      odometerStartData: 'Начальные показания одометра',
      odometerEndData: 'Конечные показания одометра',
      odometerData: 'Показания одометра',
      setAsPickupLocation: 'Установить как адрес загрузки',
      setAsDeliveryLocation: 'Установить как адрес доставки',
      setAsParkingLocation: 'Установить как адрес парковки',
      setAsExchangeLocation: 'Установить как адрес обмена',
      RateCon: 'RateCon',
      PublicRateCon: 'Public RateCon',
      DeliveryOrder: 'Delivery order',
      POD: 'Proof of delivery',
      BOL: 'Bill of lading',
      LoadPhotos: 'Фото загрузки',
      ForfeitsTitle: 'Неустойка',
      AddExpense: 'Добавить расход',
      EditExpenseEditExpense: 'Изменить расход',
      ExpensesAdded: 'Добавлен',
      ExpensesTitle: 'Расходы',
      ExpensesName: 'Расход',
      ExpensesTotal: 'Сумма',
      ExpensesAmount: 'Кол-во',
      ExpensesPerItem: 'За ед.',
      GallonsAmount: 'Галлонов',
      ExpensesPerGallon: 'За галлон',
      ExpensesCheckUpload: 'Загруженный чек',
      Including: 'Доходы',
      TotalIncluding: 'Итого доходов',
      Excluding: 'Расходы',
      Income: 'Итоговая прибыль',
      percentFromOOLoadRate: 'Процент с загрузки водителя собственника',
      dispatcherPaymentPercent: 'Процент диспетчера',
      dispatcherPaymentPercentWithOO: 'Процент диспетчера c Owner-Operator',
      dispatcherPaymentPercentFromBonus: 'Процент диспетчера от бонуса',
      including: 'включая',
      rateForTheLoad: 'Rate загрузки',
      createInvoice: 'Создать инвойс',
      isExcludeDispatcherPayment: 'Исключить процент диспетчера',
      shipTo: 'Ship To',
      shipToCompanyName: 'Название компании заказчика',
      shipToPhone: 'Контактный телефон заказчика',
      shipToContactPerson: 'Контактное лицо заказчика',
      loadDescription: 'Load description',
      shipper: 'Shipper',
      consignee: 'Consignee',
      loadNumber: 'Load Number',
      selectDispatchersToShareAccess: 'Открыть доступ диспетчерам',
      driverExpenseTotal: 'Расходы водителя',
      driverCompensateTotal: 'Компенсировать водителю',
      driverAddToSalary: 'Добавить к запрлате водителя',
      driverFine: 'Штраф водителя',
      DispatcherPayments: 'Зарплата диспетчера',
      DriversPayments: 'Запрлата водителя'
    },
    GlobalSettings: {
      LoadsFeed: 'Список возможных загрузок',
      Dispatchers: 'Диспетчеры',
      DriverSettings: 'Настройки водителя',
      DriversDefaultRates: 'Рейты водителя по умолчанию',
      TrucksSettings: 'Настройки грузовиков',
      TrucksWarningsSettings: 'Настройки уведомлений',
      TrucksIntervalsSettings: 'Настройки интервалов',
      TruckTypesTitle: 'Типы грузовиков',
      TruckMaintenanceTypesTitle: 'Типы обслуживания грузовиков',
      EnterTruckType: 'Введите тип грузовика',
      EnterTruckMaintenanceType: 'Введите тип обслуживания',
      AddTruckType: 'Добавить тип',
      ExpensesNamesTitle: 'Названия расходов',
      EnterExpensesNames: 'Введите название расхода',
      AddExpensesNames: 'Добавить название расхода',
      SavedSuccessfully: 'Настройки успешно сохранены!',
      SavedConfirmation: 'Вы уверенны что хотите сохранить настройки?',
      DeleteTruckTypeConfirmation: 'Вы уверенны что хотите удалить этот тип грузовика?',
      DeleteTruckMaintenanceTypeConfirmation: 'Вы уверенны что хотите удалить этот тип обслуживания грузовика?',
      DeleteExpensesNameConfirmation: 'Вы уверенны что хотите удалить этот тип грузовика?',
      Loads: 'Настройки загрузок',
      defaultHQPlace: 'Место для возврата по умолчанию',
      beforeDetentionHours: 'Часов до detention',
      detentionPerHour: 'Detention в час',
      milesDiffHighlightThreshold: 'Расхождение миль при котором подтвечивать загрузку в отчетах',
      Invoices: 'Инвойсы',
      InvoiceContactInfo: 'Контактная информация в инвойсе',
      invoiceFactoringShipTo: 'ShipTo в инвойсе для факторинга',
      freeMilesToPickup: 'Бесплатные мили до пикапа',
      annualInspectionIntervalInDays: 'Период регулярной инспекции',
      annualInspectionWarningWhenDaysLeft: 'Показывать нотификацию об необходимости инспекции когда осталось',
      registrationCardExpiredWarningWhenDaysLeft: 'Показывать нотификацию об истечении карты регистраци когда осталось',
      insuranceExpiredWarningWhenDaysLeft: 'Показывать нотификацию об истечении страховки когда осталось',
      oilReplacementWarningWhenMilesLeft: 'Показывать нотификацию об необходимости смены масла когда осталось',
      TrackingNotifications: 'Настройки сообщений отследивания',
      defaultFactoringPercent: 'Процент факторинга по умолчанию',
      percentFromOOLoadRate: 'Процент с загрузки водителя-собственника',
      factoringPerInvoiceFee: 'Коммисия факторинга за инвойс',
      factoringPerScheduleFee: 'Коммисия факторинга за график',
      DocumentsRequirements: 'Обязательные документы',
      LoadPhotosRequired: 'Фото грузка обязятельны?',
      RateConRequired: 'RateCon обязателен?',
      DeliveryOrderRequired: 'Delivery Order обязателен?',
      ProfOfDeliveryRequired: 'Prof Of Delivery обязателен?',
      BillOfLandingRequired: 'Bill Of Landing обязателен?',
      minLayoverToBeIncludedInRateForDispatcher: 'Минимальный Layover который будет включен в рейт загрузки для диспетчера'
    },
    Maps: {
      ShowTraffic: 'Показать пробки'
    },
    Logs: {
      Truck: 'Грузовик',
      Bid: 'Bid',
      ShowAllLogs: 'Показать все логи',
      FilterByActionType: 'Фильтровать по типу действия',
      ChangesOnly: 'Только изменения',
      Date: 'Дата и время',
      TheUser: 'Пользователь',
      Role: 'Роль',
      Name: 'Операция',
      Value: 'Действия',
      ListTitle: 'Список логов',
      ShowDetails: 'Показать детали',
      PathChangedDetails: 'Пользователь открыл страницу',
      OpenPage: 'Открыть',
      GoToPath: 'Перейти на: {path}',
      ALL: 'Все',
      ONLINE_STATUS_CHANGED: 'Онлайн статус изменен',
      PATH_CHANGED: 'Изменение страницы',
      USER_CREATED: 'Пользователь добавлен',
      USER_UPDATED: 'Пользователь обновлен',
      SETTINGS_UPDATED: 'Настройки обновленны',
      PASSWORD_UPDATED: 'Пароль обновлен',
      BID_CREATED: 'Бид создан',
      BID_CREATED_AND_PENDING: 'Бид создан и ожидает отправки',
      BID_UPDATED: 'Бид обновлен',
      TRUCK_CREATED: 'Грузовик добавлен',
      TRUCK_UPDATED: 'Грузовик обновлен',
      LOAD_CREATED: 'Загрузка создана',
      LOAD_UPDATED: 'Загрузка обновлена',
      USER_IS_ONLINE: 'Пользователь онлайн',
      USER_IS_OFFLINE: 'Пользователь оффлайн',
      ACCESS_RIGHTS_ROLE_CHANGED: 'Права доступа изменены для роли',
      ACCESS_RIGHTS_ROLE_WITH_USER_CHANGED: 'Права доступа изменены для пользователей и роли',
      ACCESS_RIGHTS_USER_CHANGED: 'Права доступа изменены для пользователя',
      ACCESS_RIGHTS_USER_RESET: 'Права доступа сброшены для пользователя'
    },
    Shifts: {
      Earned: 'Зараб.',
      ContinueShift: 'Продолжить рейс',
      From: 'от',
      LocationReadyToStart: 'Готов к старту',
      LocationOnTheWay: 'Стартовал',
      LocationOnSite: 'На месте',
      LocationDoneAndWaining: 'Выполнен и ожидает подтверждения',
      LocationNotDoneAndWaining: 'Не выполнен и ожидает подтверждения',
      LocationDoneAndCanContinue: 'Выполнеи и может быть продолжен',
      LocationReturnToHQAvailable: 'Возврат на парковку доступен',
      CurrentShift: 'Текуший рейс',
      List: 'Список рейсов',
      LocationDetails: 'Детали',
      LoadDocuments: 'Загрузка #{n}: документы',
      Rate: 'Рейт',
      Driver: 'Водитель',
      Trucks: 'Грузовики',
      Truck: 'Грузовик',
      Shift: 'Рейс',
      InProgress: 'В процессе',
      Finished: 'Завершен',
      Started: 'Начат',
      Ended: 'Окончен',
      Duration: 'Длительность',
      Distance: 'Дистанция',
      StartNewShift: 'Начало рейса',
      FinishingShift: 'Завершение рейс',
      LeavingTruck: 'Покинуть грузовик',
      SelectDriver: 'Выброр водителя',
      ShiftNote: 'Комментарий (опционально)',
      OdometerOnStartPhoto: 'Фото одометра в начале',
      OdometerOnEndPhoto: 'Фото одометра в конце',
      UploadOdometer: 'Загрузить одометр',
      StartShift: 'Начать новый рейс',
      FinishShift: 'Завершить рейс',
      YouAlreadyHaveOpenShift: 'You already have not finished trip.',
      Information: 'Информация о рейсе',
      GoToLoad: 'К загр.',
      GoToLoadDocuments: 'Докумeнты',
      ShowLoadInformation: 'Инфо о загрузке',
      StatusFilter: 'Статус',
      UsersFilter: 'Водители',
      ActiveLocationsNotAvailable: 'Нет активный загрузок назначеныз на Вас и выбранный грузовик.',
      ShiftLocationsNotAvailable: 'Нет загрузок привязанных к рейсу.',
      YouNeedSelectTruck: 'Выберите грузовик чтобы увидеть доступные локации.',
      Origin: 'Откуда',
      Finish: 'Финиш',
      LeaveTruck: 'Покинуть грузовик',
      LeaveTruckName: 'Покинуть {value}',
      SelectTruckName: 'Выбрать {value}',
      selectTruck: 'Выбрать грузовик',
      AddTruck: 'Добавить грузовик',
      NeedToCreateShift: 'Вы должны начать рейс чтобы взаимодействовать с загрузкой.',
      OdometerStart: 'одометр в начале',
      OdometerEnd: 'одометра в конце',
      FinishingTrimConfirm: 'Завершить рейс?',
      LeavingTruckConfirm: 'Покинуть грузовик?',
      ReturnToHQConfirmMsg: 'Активировать возврат на парковку с выбранной локации?',
      NotAllStatusesAvailableMsg: 'Чтобы закончить рейс нужно завершить все локации',
      NeedToChangeTruck: 'Вам необходимо изменить грузовик на {value}.',
      NeedToSelectTruck: 'Вам необходимо выбрать {value} для текущего рейса.',
      InteractThruShift: 'Вы можете взаимодействовать с загрузкой на странице рейса.',
      ToShift: 'К рейсу',
      CurrentTruck: 'Грузовик',
      TrucksInShift: 'Грузовики в рейсе',
      ShowFinishedLocations: 'Показать завершенные',
      HideFinishedLocations: 'Спрятать завершенные'
    }
  }
};

// Create VueI18n instance with options
export default new VueI18n({
  locale: 'en-US', // set locale
  messages // set locale messages
});
